import axiosInstance from "../../api/axios";

const GET_ALL_ROOMS = `${process.env.REACT_APP_BACK_URL}/api/v1/rooms/getAllRooms`;

const GET_ALL_ROOMS_BY_ESTABLISHMENTID = (establishmentId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/rooms/getRoomsByEstablishmentId/${establishmentId}`;
const CREATE_ROOM = `${process.env.REACT_APP_BACK_URL}/api/v1/rooms/createRoom`;
const UPDATE_ROOM = `${process.env.REACT_APP_BACK_URL}/api/v1/rooms/updateRoom`;
const DELETE_ROOM = `${process.env.REACT_APP_BACK_URL}/api/v1/rooms/delete`;
const AVAILABILITY_ROOM = `${process.env.REACT_APP_BACK_URL}/api/v1/rooms/getRoomAvailablewithFilter`;

// const ADD_ROOM = `${process.env.REACT_APP_BACK_URL}/api/v1/service/addService`;

export const availabilityRoomBooking = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `${AVAILABILITY_ROOM}/${payload.establishmentId}`,
      payload.bookings
    );
    console.log("response service", response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createRoom = async (roomData) => {
  try {
    const response = await axiosInstance.post(CREATE_ROOM, roomData);
    return response;
  } catch (error) {
    throw error;
  }
};

//updtae
export const updateRoom = async (id, roomData) => {
  try {
    const response = await axiosInstance.post(`${UPDATE_ROOM}/${id}`, roomData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllRooms = async () => {
  try {
    const response = await axiosInstance.get(GET_ALL_ROOMS);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getRoomsByEstablishment = async (establishmentId) => {
  try {
    const response = await axiosInstance.get(
      GET_ALL_ROOMS_BY_ESTABLISHMENTID(establishmentId)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//delete room
export const deleteRoom = async (idRoom) => {
  try {
    const response = await axiosInstance.delete(`${DELETE_ROOM}/${idRoom}`);
    console.log("room service", response);
    return response;
  } catch (error) {
    throw error;
  }
};
