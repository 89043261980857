import axiosInstance from "../../api/axios";

const GET_ALL_USERS = `${process.env.REACT_APP_BACK_URL}/api/v1/auth/getAllUsers`;
const UPDATE_USER = (userId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/auth/update/${userId}`;
const GET_USER_BY_UNIQUEID = (uniqueId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/auth/getUserByUniqueId/${uniqueId}`;
const GET_USERS_FROM_AFFECTATION = (establishmentId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/affectationUser/getUsersFromAffectationByEstablishmentId/${establishmentId}`;
const CREATE__USER_ACCOUNT = `${process.env.REACT_APP_BACK_URL}/api/v1/auth/signup`;

const PASSPORT_OCR_TUN = `${process.env.REACT_APP_BACK_URL}/api/v1/passportOCR/passportOcrTun`;
const SCAN_IDENTITY = `${process.env.REACT_APP_BACK_URL}/api/v1/ocr/upload`

const UPDATE_ROLE_USER = (userId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/auth/updateRoleByUserId/${userId}`;

export const getAllUsers = async () => {
  try {
    const response = await axiosInstance.get(GET_ALL_USERS);
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const passportOcrTun = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    console.log("formData========>", formData);
    const response = await axiosInstance.post(PASSPORT_OCR_TUN, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUserByUniqueId = async (uniqueId) => {
  try {
    const response = await axiosInstance.get(GET_USER_BY_UNIQUEID(uniqueId));
    console.log("uniqueId", response);
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const updateUser = async (
  userId,
  email,
  status,
  firstName,
  lastName,
  role
) => {
  try {
    const response = await axiosInstance.post(UPDATE_USER(userId), {
      email,
      status,
      firstName,
      lastName,
      role,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const CreateUser = async (transformedData) => {
  console.log("🚀 ~ CreateUser ~ transformedData:", transformedData);
  try {
    const response = await axiosInstance.post(
      CREATE__USER_ACCOUNT,
      transformedData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateRoleUser = async (updateUserRole) => {
  console.log("🚀 ~ updateRoleUser ~ updateUserRole:", updateUserRole);
  try {
    const { userId, role } = updateUserRole;
    const response = await axiosInstance.post(UPDATE_ROLE_USER(userId), {
      role,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUsersFromAffectationByEstablishmentId = async (
  establishmentId
) => {
  try {
    const response = await axiosInstance.get(
      GET_USERS_FROM_AFFECTATION(establishmentId)
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};


export const ScanIdentity = async (scanData) => {
  console.log("🚀 ~ CreateUser ~ transformedData:", scanData);
  try {
    const response = await axiosInstance.post(
      SCAN_IDENTITY,
      scanData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};