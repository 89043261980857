import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableComp from "../../../Components/dataTable/TableComp";
import { Eye, Edit } from "react-feather";
import { Btn, Image } from "../../../AbstractElements";
import { dynamicImage } from "../../../Service";
import { toast } from "react-toastify";

import {
  deleteCategoryAsync,
  getSubCategoryAsync,
} from "../../../redux-toolkit/slices/categorySlice";
import { useTranslation } from "react-i18next";
import CategoryView from "./CategoryView";
import { usePermission } from "../../../Service/usePermission";
const SingleCategory = () => {

  /************* Start Hooks **************/
  //Start useState
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  //End useState

  //Start useSelector
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );
  const categoryData = useSelector((state) => state.category.categoriesData);
  const isLoading = useSelector((state) => state.category.loading);
  //End useSelector

  //useDispatch
  const dispatch = useDispatch();

  //useNavigate
  const navigate = useNavigate();

  // useTranslation
   const { t } = useTranslation();

  //useParams
  let params = useParams();

  //useLocation
  let location = useLocation();

  //Start useEffect
  useEffect(() => {
    location &&
      affectationSelected &&
      dispatch(
        getSubCategoryAsync({
          categoryId: params.categoryId,
          establishmentId: affectationSelected?.establishments?._id,
          page,
          perPage
        })
      );
  }, [dispatch, affectationSelected, params, page, perPage]);

  //End useEffect

  /************* End Hooks **************/
  

  /************* Start Constants && variables **************/
  const categoryTableColumnChildren = [
    {
      name: t("Image"),
      selector: (row) => (
        <Image
          src={
            row.images[0]
              ? process.env.REACT_APP_MINIO_URL + row?.images[0]
              : dynamicImage("ecommerce/options.png")
          }
          style={{ width: "70px", padding: "10px" }}
        />
      ),
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
      center: true,
    },
    {
      name: "Sub Category",
      selector: (row) => typeof row.children === "number" ? row.children : 0,
      sortable: true,
      center: true,
    },
    {
      name: "Services",
      selector: (row) => (typeof row.services === "number" ? row.services : 0),
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <a
            title="View"
            className="btn-xs"
            onClick={() => handleOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Eye width={20} />
          </a>

          <a
            className="btn-xs"
            title="Edit"
            onClick={() => handelNavigateEditSubCategory(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };
  /************* End Constants && variables **************/

  /************* Start Functions && handlers **************/
  const handleOpenToView = (category) => {
    setSelectedCategory(category);
    setIsOpen(true);
  };

  const handelDeleteCategory = async () => {
    if (selectedRows?.[0]?.children === 0) {
    try {
      const response = await dispatch(
        deleteCategoryAsync(selectedRows?.[0]?._id)
      );
      if (deleteCategoryAsync.fulfilled.match(response)) {
          if(page <= 1 && (categoryData?.totalData - 1) <= 0) {
            if (window?.history?.length > 1) {
              navigate(-1);
            }
            else {
              navigate(`/services-manager/category/`);
            }

          }
        else if ( Math.ceil((categoryData?.totalData - 1 )/ perPage) < page )  // this is useful when deleting the last category in a page != 1
        {
          setPage((prevPage) => prevPage - 1);
        }
        
        toast.success(
          response.payload.message || "Category deleted successfully!!"
        );
      } else {
        toast.error(response.payload);
      }
    } catch (error) {
      toast.error("An error occurred while deleting the category.");
    }
  } else {
    toast.error("This category have subCategory");
  }

  };
  const handelNavigateToForm = (row) => {
    const rowName = location?.state?.name.replace(/ /g, "-");
    navigate(`/services-manager/category/${params.categoryId}/subCategory/new`, {state: location?.state});
  };

  const handelNavigateToSubCategory = (row) => {
    const rowName = row.name.replace(/ /g, "-");
    if(row?.children > 0)
    navigate(`/services-manager/category/${row?._id}`, { state: row });
  };
  const handelNavigateEditSubCategory = (row) => {
    const rowName = location?.state?.name.replace(/ /g, "-");
    navigate(
      `/services-manager/category/${params.categoryId}/subCategory/edit/${row?._id}`,
      { state: {subCategory: row, parentCategory: location?.state } }
    );
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };


  
  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  /************* End Functions && handlers **************/


 
  return (
    <div className="page-body">
      <Breadcrumbs />
      <div className="d-flex align-items-center justify-content-end bg-light-info p-2">
        <Btn
          color="danger mb-2 m-r-15"
          onClick={() => handelDeleteCategory()}
          disabled={usePermission('static', "service manager", '!delete')  || selectedRows.length === 0 }
        >
          Delete
        </Btn>
        <Btn
          color="btn btn-primary mb-2"
          onClick={() => handelNavigateToForm()}
          disabled={usePermission('static', "service manager", '!execute')} 
        >
          Add New Sub Category
        </Btn>
      </div>
      {isLoading  ? (
        <div style={{ position: "relative", marginTop: "200px" }}>
          <div className="loader-wrapper" style={{width: "100%", height: "100%", position: "absolute", top: "0"}}>
            <div className="theme-loader" />
          </div>
        </div>
       ) : (
        <TableComp
        data={categoryData.data}
        columns={categoryTableColumnChildren}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        handleRowSelected={handleRowSelected}
        selectableRowsSingle={true}
        selectableRows={true}
        toggleDelete={false}
        expandableRows={false}
        highlightOnHover={true}
        fixedHeader={true}
        customStyles={customStyles}
        onRowClicked={(row) => handelNavigateToSubCategory(row)}
        pagination
        paginationTotalRows={categoryData.totalData}
        paginationServer
      />
      )}
      <CategoryView
        isOpen={isOpen}
        toggleClose={() => setIsOpen(false)}
        data={selectedCategory}
      />
      
    </div>
  );
};

export default SingleCategory;
