import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  signInAuth,
  getAdminAuth,
  getAdminAffectations,
  getAffectationById,
  logoutAuth,
} from "../services/authService";
import { toast } from "react-toastify";

const initialState = {
  UserAdmin: null,
  affectations: [],
  affectationSelected: null,
  loading: false,
  error: null,
  loadingAffectationSelected: false
};

export const signInAuthAsync = createAsyncThunk(
  "auth/loginAdmin",
  async (authData) => {
    try {
      const response = await signInAuth(authData);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const logoutAuthAsync = createAsyncThunk(
  "auth/logoutAdmin",
  async () => {
    try {
      const response = await logoutAuth();
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getAdminAuthAsync = createAsyncThunk(
  "auth/getAdmin",
  async (adminId) => {
    try {
      const response = await getAdminAuth(adminId);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const getAdminAffectationsAsync = createAsyncThunk(
  "auth/getAdminAffectations",
  async (adminId) => {
    try {
      const response = await getAdminAffectations(adminId);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getAffectationByIdAsync = createAsyncThunk(
  "auth/getAffectationById",
  async (affectationId) => {
    let isRoot;
    try {
      const response = await getAffectationById(affectationId);

      return response;
    } catch (error) {
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAffectationSelected: (state, action) => {
      state.affectationSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInAuthAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signInAuthAsync.fulfilled, (state, action) => {
        state.UserAdmin = action.payload.admin;
        state.affectations = action.payload.affectations;
        state.loading = false;
        state.error = null;
        localStorage.setItem("accessToken", action.payload?.accessToken);
        localStorage.setItem("admin", action.payload?.admin?._id);
      })
      .addCase(signInAuthAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(logoutAuthAsync.fulfilled, (state, action) => {
        state.UserAdmin = null;
        state.affectations = [];
        state.loading = false;
        state.error = null;
        localStorage.removeItem("accessToken");
        localStorage.removeItem("affectation");
        localStorage.removeItem("admin");
      })
      .addCase(logoutAuthAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getAdminAuthAsync.fulfilled, (state, action) => {
        state.UserAdmin = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAdminAuthAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getAdminAffectationsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdminAffectationsAsync.fulfilled, (state, action) => {
        state.affectations = action.payload.affectations;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAdminAffectationsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getAffectationByIdAsync.pending, (state) => {
        state.loadingAffectationSelected = true;
        state.error = null;
      })
      .addCase(getAffectationByIdAsync.fulfilled, (state, action) => {
        state.affectationSelected = action.payload.affectations[0];
        state.loadingAffectationSelected = false;
        state.error = null;
      })
      .addCase(getAffectationByIdAsync.rejected, (state, action) => {
        state.loadingAffectationSelected = false;
        state.error = action.error;
      });
  },
});
export const { setAffectationSelected } = authSlice.actions;
export const authReducer = authSlice.reducer;
export const { actions } = authSlice;
