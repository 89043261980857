import { Container, Input, Label, Row } from "reactstrap";
import { Image } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import { deleteServices, fetchServices, updateTheService } from "../../redux-toolkit/slices/serviceSlice";
import TableComp from "../../Components/dataTable/TableComp";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Edit, Trash } from "react-feather";
import { dynamicImage } from "../../Service";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { usePermission } from "../../Service/usePermission";

const ServicePageContainer = () => {
  /** Start hooks **/
    //Start useState
    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(1); // state for current page
    const [perPage, setPerPage] = useState(10); // state for items per page
    //End useState

  //Start useSelector
  const servicesData = useSelector((state) => state.services.servicesData);
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );
  //End useSelector

  //useDispatch
  const dispatch = useDispatch();

  //useNavigate
  const navigate = useNavigate();

  //useTranslation
  const { t } = useTranslation();

    //useEffect
      useEffect(() => {
        const fetchServiceData = async () => {
          if (affectationSelected) {
            const res = await dispatch(
              fetchServices({
                establishmentId: affectationSelected?.establishments._id,
                page,
                perPage,
              })
            );
          
          }
        };
      
        fetchServiceData();
      }, [dispatch, affectationSelected, page, perPage]);

  /** End hooks **/


  /********* Start constants and variables **********/
      const ServiceTableColumns = [
        {
          name: t("Image"),
          selector: (row) => (
            <div>
              <Image
                src={
                  row?.images[0]
                    ? process.env.REACT_APP_MINIO_URL + row?.images[0]
                    : dynamicImage("ecommerce/options.png")
                }
                style={{ width: "70px", padding: "10px" }}
              />
            </div>
          ),
          sortable: true,
          center: true,
        },
        {
          name: t("Name"),
          selector: (row) => row["name"],
          sortable: true,
          center: true,
        },
        {
          name: t("Description"),
          selector: (row) => row?.description,
          sortable: true,
          center: true,
        },
        {
          name: t("Category"),
          selector: (row) => row?.category?.name,
          sortable: true,
          center: true,
        },
        {
          name: t("Department"),
          selector: (row) => row?.departement?.name,
          sortable: true,
          center: true,
        },
        {
          name: t("Products"),
          selector: (row) => row?.products?.length,
          sortable: true,
          center: true,
        },
        {
          name: t("Active"),
          selector: (row) => (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "start",
                alignItems: "flex-end",
              }}
            >
              <div className="icon-state switch-outline">
                <Label
                  className="switch"
                  style={{ marginBottom: "0px", marginTop: "0px" }}
                >
                  <Input
                    type="checkbox"
                    checked={row?.statut}
                    onChange={()=>handleChangeStatus(row)}
                    disabled={usePermission('static', "service manager", '!update')} 
                  />
                  <span className="switch-state bg-primary"></span>
                </Label>
              </div>
            </div>
          ),
          sortable: true,
          center: true,
        },
        {
          name: t("Action"),
          selector: (row) => (
            <div>
              <a className="btn-xs" style={{ cursor: "pointer" }}>
                <Trash width={20} color="red" onClick={usePermission('static', "service manager", 'delete') ? () => handelDeleteService(row) : null} 
                   style={{ 
                    cursor: usePermission('static', "service manager", '!delete') ? 'not-allowed' : 'pointer', 
                    opacity: usePermission('static', "service manager", '!delete')  ? 0.5 : 1 
                  }}/>
              </a>
              <a
                className="btn-xs"
                title="Edit"
                onClick={() => handelNavigateEditService(row)}
                style={{ cursor: "pointer" }}
              >
                <Edit width={20} />
              </a>
            </div>
          ),
          sortable: false,
          center: true,
        },
      ];
    
      const customStyles = {
        headCells: {
          style: {
            color: "#202124",
            fontSize: "14px",
          },
        },
        rows: {
          highlightOnHoverStyle: {
            backgroundColor: "rgb(230, 244, 244)",
            borderBottomColor: "#FFFFFF",
            outline: "1px solid #FFFFFF",
            cursor: "pointer",
          },
        },
        pagination: {
          style: {
            border: "none",
          },
        },
      };
  /********* End constants and variables **********/


  /********* Statrt function and handlers **********/
    const handelModal = () => {
      setIsOpen(!isOpen);
    };

  
    const handelDeleteService = async (row) => {
        try {  
          SweetAlert.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover it!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "cancel",
            reverseButtons: true,
          }).then(async (result) => {
            if (result.value) {        
              const response = await dispatch(deleteServices(row?._id));
              if (deleteServices.rejected.match(response)) {
                toast.error(response.payload || "Error while deleting this service!!")
              } else {
                if ( Math.ceil(servicesData?.data?.totalData / perPage) < page )  // this is useful when deleting the last service in a page != 1
                {
                  setPage(page-1)
                }
                toast.success(response.payload.message || "Service was deleted successfully!!")
              }        
              } 
            }
          );
         
        } catch (error) {
          toast.error("An error occurred while deleting the service.");
        }
     
    };

  

    const handlePerRowsChange = async (newPerPage, newPage) => {
      setPerPage(newPerPage);
      setPage(newPage);
    };

    const handleChangeStatus = async (row) => {
      const serviceUpdated = await dispatch(
        updateTheService({
          serviceId: row?._id,
          serviceData: {statut: !row?.statut},
        })
      )
    }
  /** End hooks **/

  /********* Start constants and variables **********/

  /********* End constants and variables **********/

  /********* Statrt function and handlers **********/
 

  const handelNavigateEditService = (row) => {
    const rowName = row._id.replace(/ /g, "-");
    navigate(`/services-manager/services/edit/${rowName}`, { state: row });
  };


  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  /********* End function and handlers **********/

  return (
    <Container fluid={true}>
      <Row>
        <TableComp
          data={servicesData.data}
          columns={ServiceTableColumns}
          toggleDelete={false}
          expandableRows={false}
          highlightOnHover={true}
          customStyles={customStyles}
          onChangePage={handlePageChange} // Add page change handler
          onChangeRowsPerPage={handlePerRowsChange} // Add per page change handler
          pagination // Enable pagination
          paginationServer // Indicate that pagination is server-side
          paginationTotalRows={servicesData.totalData} // Total rows for pagination
          selectableRows={false}
        />
      </Row>
    </Container>
  );
};

export default ServicePageContainer;
