import { useEffect, useCallback } from "react";
import io from "socket.io-client";
import notificationSound from "../assets/audio/mix-notification.wav"; // Adjust the path as needed

const useSocket = (event, callback) => {
  const playNotificationSound = useCallback(() => {
    const audio = new Audio(notificationSound);
    console.log("🚀 ~ playNotificationSound ~ audio:", audio);
    audio.play().catch((error) => console.error("Error playing sound:", error));
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      auth: {
        token: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    const handleEvent = (receivedData) => {
      if (callback) {
        callback(receivedData);
      }
      playNotificationSound();
    };

    socket.on(event, handleEvent);

    return () => {
      socket.off(event, handleEvent);
      socket.disconnect();
    };
  }, [event, callback, playNotificationSound]);

  return playNotificationSound; // No local state needed
};

export default useSocket;
