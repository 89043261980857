import axiosInstance from "../../api/axios";

const CREATE_CATEGORY = `${process.env.REACT_APP_BACK_URL}/api/v1/category/addCategory`;
const GET_ALL_CATEGORY_TREE = `${process.env.REACT_APP_BACK_URL}/api/v1/category/getcategory/categoryandservice`;
const GET_CATEGORY_WITH_NO_PARENT_OR_GET_CHILDREN_OF_THE_CATEGORY_IN_PARAMS = `${process.env.REACT_APP_BACK_URL}/api/v1/category`;
const GET_ALL_CATEGORY = `${process.env.REACT_APP_BACK_URL}/api/v1/category/all`
const GET_SERVICES_OF_A_CATEGORY = `${process.env.REACT_APP_BACK_URL}/api/v1/category/services`
const UPDATE_CATEGORY = (idCategory) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/category/update/${idCategory}`;
const DELETE_CATEGORY = (idCategory) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/category/delete/${idCategory}`;

export const createCategory = async (categoryData) => {
  try {
    const response = await axiosInstance.post(CREATE_CATEGORY, categoryData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateCategory = async (idCategory, categoryData) => {
  try {
    const response = await axiosInstance.post(
      UPDATE_CATEGORY(idCategory),
      categoryData
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCategory = async (idCategory) => {
  try {
    const response = await axiosInstance.delete(DELETE_CATEGORY(idCategory));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const gelAllCategoryTree = async (establishment, page, perPage, tree) => {
  try {
    
    const response = await axiosInstance.post(
      `${GET_ALL_CATEGORY_TREE}?page=${page}&perPage=${perPage}`,
      {establishment, tree}
    );
  
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllCategory = async (establishment, page, perPage) => {
  try {
    
    const response = await axiosInstance.get(
      `${GET_ALL_CATEGORY}${establishment}?page=${page}&perPage=${perPage}`
    );
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCategoriesWithoutParent = async (establishmentId, page, perPage) => {
  try {
    const response = await axiosInstance.post(
      `${GET_CATEGORY_WITH_NO_PARENT_OR_GET_CHILDREN_OF_THE_CATEGORY_IN_PARAMS}?page=${page}&perPage=${perPage}`,
      { establishment: establishmentId }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getSubCategory = async (categoryId, establishmentId, page, perPage) => { // Accept both categoryId and establishmentId
  
    try {
    const response = await axiosInstance.post(
      `${GET_CATEGORY_WITH_NO_PARENT_OR_GET_CHILDREN_OF_THE_CATEGORY_IN_PARAMS}/${categoryId}?page=${page}&perPage=${perPage}`, // Include categoryId in the URL
       {establishment:establishmentId}  // Include establishmentId in the request body
    );
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getServicesByCategoryId = async (categoryId) => {
  try {
    
    const response = await axiosInstance.get(
      `${GET_SERVICES_OF_A_CATEGORY}/${categoryId}`
    );
    
    return response.data;
  } catch (error) {
    throw error;
  }
};