import React, { Fragment, useState } from "react";
import { Database } from "react-feather";
import { Image, LI, P, UL } from "../../AbstractElements";
import Affectation from "../../Components/affectations";

const AffectationHeader = () => {
  const [open, setOpen] = useState(false);
  const handleTheModal = () => {
    setOpen(true);
  };
  return (
    <Fragment>
      <LI className="" onClick={handleTheModal}>
        <Database />{" "}
      </LI>

      <Affectation open={open} setOpen={setOpen} />
    </Fragment>
  );
};

export default AffectationHeader;
