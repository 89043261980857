import axiosInstance from "../../api/axios";

const GET_COMPLAINTS = `${process.env.REACT_APP_BACK_URL}/api/v1/reclamation/getReclamationsByEstablishmentId/`;
const UPDATE_COMPLAINTS_TO_RESOLVED = `${process.env.REACT_APP_BACK_URL}/api/v1/reclamation/updateReclamationStatus`;

export const getComplaints = async (
  establishmentId,
  page,
  perPage,
  services
) => {
  try {
    const response = await axiosInstance.get(
      `${GET_COMPLAINTS}${establishmentId}?perPage=${perPage}&page=${page}&status=Unresolved&services=${services}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateComplaintStatus = async (reclamationId) => {
  try {
    const response = await axiosInstance.put(
      `${UPDATE_COMPLAINTS_TO_RESOLVED}/${reclamationId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
