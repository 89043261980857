export let projectOverViewDatas = [
  {
    headingClassName: "font-primary",
    heading: 18,
    paragraph: "Active Cases",
  },
  {
    headingClassName: "font-secondary",
    heading: 143,
    paragraph: "Pending Tasks",
  },
  {
    headingClassName: "font-success",
    heading: 574,
    paragraph: "Upcoming Events",
  },
  { headingClassName: "font-info", heading: 15, paragraph: "New Messages" },
  {
    headingClassName: "font-warning",
    heading: 20,
    paragraph: "Open Requests",
  },
  { headingClassName: "font-danger", heading: 312, paragraph: "Hours Spent" },
];
