import { useEffect } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import DashboardContainer from "../../Components/Dashboard";
import { useSelector } from "react-redux";
const HomePage = () => {
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  console.log("🚀 ~ file: index.jsx:7 ~ HomePage ~ userAdmin:", userAdmin);

  return (
    <div className="page-body">
      <Breadcrumbs title="DashBoard" />
      <DashboardContainer />
    </div>
  );
};

export default HomePage;
