import { Home, Server, Users, ShoppingBag, Key, Settings, AlertCircle, Calendar } from "react-feather";

const MENUITEMS = [
  {
    title: "Dashboard",
    icon: <Home />,
    url: "/dashboard",
    type: "link",
    // menu: [
    //   {
    //     title: "Home",
    //     url: `${process.env.PUBLIC_URL}/home`,
    //     type: "link",
    //   },
    // ],
  },
  {
    title: "Service Manager",
    icon: <Server />,
    url: "/services-manager",
    type: "link",
    menu: [      
      {
        title: "Category",
        url: `/services-manager/category`,
        type: "link",
      },
      {
        title: "Department",
        url: `/services-manager/department`,
        type: "link",
      },
      {
        title: "Services",
        url: `/services-manager/services`,
        type: "link",
      },
    ],
  },
  {
    title: "Employees",
    icon: <Users />,
    url: "/employees",
    type: "link",
  },
  {
    title: "Clients",
    icon: <Users />,
    url: "/users",
    type: "link",
  },
  {
    title: "Booking",
    icon: <Calendar />,
    url: "/booking",
    type: "link",
  },
  {
    title: "Complaints",
    icon: <AlertCircle />,
    url: "/complaints",
    type: "link",
  },
  {
    title: "Room",
    icon: <Key />,
    url: "/rooms",
    type: "link",
  },
  {
    title: "Orders",
    icon: <ShoppingBag />,
    url: "/orders",
    type: "link",
  },
  {
    title: "Settings",
    icon: <Settings />,
    url: "/settings-page",
    type: "link",
  },
];


//this is the menu of prestation
export const PRESTATION_MENUITEMS = [
  {
    title: "Dashboard",
    icon: <Home />,
    url: "/dashboard",
    type: "link",
  },
  {
    title: "Service Manager",
    icon: <Server />,
    url: "/services-manager",
    type: "link",
    menu: [      
      {
        title: "Category",
        url: `/services-manager/category`,
        type: "link",
      },
      {
        title: "Department",
        url: `/services-manager/department`,
        type: "link",
      },
      {
        title: "Services",
        url: `/services-manager/services`,
        type: "link",
      },
    ],
  },
  {
    title: "Employees",
    icon: <Users />,
    url: "/employees",
    type: "link",
  },
  {
    title: "Complaints",
    icon: <AlertCircle />,
    url: "/complaints",
    type: "link",
  },
 
  {
    title: "Appointment",
    icon: <Calendar />,
    url: "/appointments",
    type: "link",
  },
  {
    title: "Settings",
    icon: <Settings />,
    url: "/settings-page",
    type: "link",
  },
];

export default MENUITEMS;
