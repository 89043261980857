import React, { Fragment, useEffect, useState } from "react";
import { Home } from "react-feather";
import { Image, LI, P, UL } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import { getAffectationByIdAsync } from "../../redux-toolkit/slices/authSlice";
const EtablishementSelected = () => {
  const dispatch = useDispatch();
  const affectationId = localStorage.getItem("affectation");

  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );
  console.log(
    "🚀 ~ affectationSelected ~ affectationSelected:",
    affectationSelected
  );

  //The affectationSelected will be obtained in the parent of this component.
  // useEffect(() => {
  //   if (affectationSelected == null) {
  //     dispatch(getAffectationByIdAsync(affectationId));
  //   }
  // }, []);  

  return (
    <Fragment>
      <Home />
      <span
        className="f-12 f-w-600"
        style={{ paddingLeft: "5px", paddingRight: "15px" }}
      >
        {affectationSelected?.establishments?.name}
      </span>
    </Fragment>
  );
};

export default EtablishementSelected;
