import axiosInstance from "../../api/axios";

const GET_ALL_DEPARTEMENT = `${process.env.REACT_APP_BACK_URL}/api/v1/departement/getDepartementAndService`;
const ADD_DEPARTEMENT = `${process.env.REACT_APP_BACK_URL}/api/v1/departement/addDepartement`;
const UPDTAE_DEPARTEMENT = `${process.env.REACT_APP_BACK_URL}/api/v1/departement/update`;
const DELETE_DEPARTMENT = (idDepartement) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/departement/delete/${idDepartement}`;
const GET_DEPARTMENT_BY_ID = (idDepartement) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/departement/getDepartementById/${idDepartement}`;

export const getAllDepartement = async (establishmentId, page, perPage) => {
  try {
    const response = await axiosInstance.post(`${GET_ALL_DEPARTEMENT}?perPage=${perPage}&page=${page}`, {
      establishment: establishmentId,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

// export const getAllDepartementWithPagination = async (establishmentId, page, perPage) => {
//   try {
//     // const response = await axiosInstance.post(GET_ALL_DEPARTEMENT, {
//     //   establishment: establishmentdata,
//     // });
//     const response = await axiosInstance.post(`${GET_ALL_DEPARTEMENT}?page=${page}&perPage=${perPage}`, {
//       establishment: establishmentId,
//     });
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };
export const addNewDepartement = async (departementData) => {
  try {
    const response = await axiosInstance.post(ADD_DEPARTEMENT, departementData);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteDepartment = async (idDepartement) => {
  try {
    const response = await axiosInstance.delete(
      DELETE_DEPARTMENT(idDepartement)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDepartmentById = async (idDepartement) => {
  try {
    const response = await axiosInstance.post(
      GET_DEPARTMENT_BY_ID(idDepartement)
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//updtae department
export const updateDepartment = async (idDepartement, departementData) => {
  try {
    const response = await axiosInstance.post(`${UPDTAE_DEPARTEMENT}/${idDepartement}`, departementData);
    console.log("response in department service", response)
    return response;
  } catch (error) {
    throw error;
  }
};