import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getServiceById,
  getAllServices,
  addNewService,
  deleteService,
  updateService,
} from "../services/serviceService";

const initialState = {
  servicesData: {}, // servicessData contains 4 fields : data(an array of services), totalData, perPage, currentPage
  subService: [],
  newService: null,
  loading: false,
  error: null,
};

export const fetchServices = createAsyncThunk(
  "services/fetchData",
  async ({ establishmentId, page, perPage }) => {
    try {
      const response = await getAllServices(establishmentId, page, perPage);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSubServices = createAsyncThunk(
  "services/fetchSubService",
  async ({ establishmentId, id }) => {
    try {
      const response = await getAllServices(establishmentId, id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addService = createAsyncThunk(
  "services/addService",
  async (serviceData,{ rejectWithValue }) => {
    try {
      const response = await addNewService(serviceData);
      return response.data;

  } catch (error) {
   
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message || "An unexpected error has occurred!");
    }
  }
  }
);


export const updateTheService = createAsyncThunk(
  "services/updateService",
  async ({ serviceId, serviceData }, { rejectWithValue }) => {
   
    try {
      const response = await updateService(serviceId, serviceData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message || "An unexpected error has occurred!");
      }
    }
  }
);

export const deleteServices = createAsyncThunk(
  "services/deleteServices",
  async (serviceId,  { rejectWithValue }) => {
    try {
      const response = await deleteService(serviceId);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message || "An unexpected error has occurred!");
      }
    }
  }
);

export const getServiceByIdAsync = createAsyncThunk(
  "services/getServiceById",
  async (serviceId,  { rejectWithValue }) => {
    try {
      const response = await getServiceById(serviceId);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message || "An unexpected error has occurred!");
      }
    }
  }
);

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      state.servicesData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchServices.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(fetchSubServices.fulfilled, (state, action) => {
      state.subService = action.payload.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addService.fulfilled, (state, action) => {
      state.newService = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addService.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(updateTheService.fulfilled, (state, action) => {
      state.servicesData.data = state.servicesData?.data?.map((elem)=>{
        if(elem._id === action?.payload?.data?._id) {
          return action.payload.data
        }
        return elem
      })
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateTheService.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteServices.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteServices.fulfilled, (state, action) => {
      state.servicesData.data = state.servicesData.data.filter(
        (service) => service._id !== action.payload?.data?._id
      );
      state.servicesData.totalData-- ;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getServiceByIdAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getServiceByIdAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const servicesReducer = servicesSlice.reducer;
export const { actions } = servicesSlice;
