import { useSelector } from 'react-redux';

// Function to evaluate the permission expression
function evaluateExpression(resultObject, expression) {
  const { read, update, delete: del, execute } = resultObject; /**delete keyword is a reserved word in JavaScript, we rename it to del during destructuring and replace occurrences of delete in the expression string with del. */
  return eval(expression.replace(/delete/g, 'del'));
}

// Function to construct the permissions object
function getPermission(roles = [], status = "", Identifier = null) {
  let resultObject = { read: false, update: false, delete: false, execute: false };

 
  roles?.forEach((role) => {
    role.permissions?.forEach((permission) => {
      if (status === "dynamic") {
        if (permission?.service && permission.service === Identifier) {
          if (!resultObject.read && permission.read) {
            resultObject.read = true;
          }
          if (!resultObject.update && permission.update) {
            resultObject.update = true;
          }
          if (!resultObject.delete && permission.delete) {
            resultObject.delete = true;
          }
          if (!resultObject.execute && permission.execute) {
            resultObject.execute = true;
          }
        }
      } else if (status === "static") {
        if (permission?.name.toUpperCase() === Identifier.toUpperCase()) {

          if (!resultObject.read && permission.read) {
            resultObject.read = true;
          }
          if (!resultObject.update && permission.update) {
            resultObject.update = true;
          }
          if (!resultObject.delete && permission.delete) {
            resultObject.delete = true;
          }
          if (!resultObject.execute && permission.execute) {
            resultObject.execute = true;
          }
        }
      }
    });
  });

  return resultObject;
}

// Custom hook to get permissions and evaluate the expression
export function usePermission(status = "", Identifier = null, expression = "") {
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );
  const resultObject = getPermission(affectationSelected?.role, status, Identifier);
  return evaluateExpression(resultObject, expression);
}
