import { Image } from "../../AbstractElements";
import { Link } from "react-router-dom";
const LeftHeader = () => {
  const images = require.context("../../assets/images/logo", true);
  const dynamicImage = (image: string) => {
    return images(`./${image}`);
  };
  return (
    <div className="main-header-left">
      <div className="logo-wrapper">
        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
          <div style={{ backgroundColor: "" }}>
            <Image
              body={true}
              src={dynamicImage("officent_logo.png")}
              alt="logo"
              style={{ width: "125px",marginTop: "-10px",height:'50px'}}
            />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default LeftHeader;
