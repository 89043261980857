import React, { useEffect } from "react";
import { Col, Row, Label, Button, Input } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { createMealsAsync, getAllMeals } from "../../../redux-toolkit/slices/mealsSlice";
import Select from "react-select";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  price: yup.number().typeError("Price must be a number").required("Price is required"),
  type: yup.string().required("Type is required"),
});

const typeOptions = [
  { value: "all-inclusive", label: "All-Inclusive" },
  { value: "demi-pension", label: "Demi-Pension" },
  { value: "pension-complete", label: "Pension-Complete" },
];

const CreateEstablishmentMealsForm = () => {
  const dispatch = useDispatch();
  const affectationSelectedData = useSelector((state) => state.auth.affectationSelected);
  const establishmentId = affectationSelectedData?.establishments?._id;
  console.log("🚀 ~ CreateEstablishmentMealsForm ~ establishmentId:", establishmentId)
  const meals = useSelector((state) => state.meals.meals);
  console.log("🚀 ~ CreateEstablishmentMealsForm ~ meals:", meals)
  const loading = useSelector((state) => state.meals.loading);
  const error = useSelector((state) => state.meals.error);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      price: "",
      type: "",
    },
  });

  useEffect(() => {
    setValue("type", "all-inclusive");
  }, [setValue]);

  useEffect(() => {
    if (establishmentId) {
      dispatch(getAllMeals(establishmentId));
    }
  }, [dispatch, establishmentId]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("price", data.price);
    formData.append("type", data.type);
    formData.append("establishment", establishmentId);

    try {
      const mealsCreated = await dispatch(createMealsAsync(formData));
      
      if (createMealsAsync.fulfilled.match(mealsCreated)) {
        toast.success("Meal created successfully!");
      reset();
        await dispatch(getAllMeals(establishmentId));
      }else{
        toast.error("Error!");
      }
      
    } catch (error) {
      console.error("Error creating meal:", error);
      toast.error("Failed to create meal.");
    }
  };

  const columns = [
    { name: 'Name', selector: row => row.name, sortable: true },
    { name: 'Price', selector: row => row.price, sortable: true },
    { name: 'Type', selector: row => row.type, sortable: true },
  ];

  return (
    <Col xl="12">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="6">
            <Label for="name">Name</Label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  id="name"
                  type="text"
                  {...field}
                  invalid={!!errors.name}
                />
              )}
            />
            {errors.name && <div className="text-danger">{errors.name.message}</div>}
          </Col>
          <Col md="6">
            <Label for="price">Price</Label>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <Input
                  id="price"
                  type="number"
                  {...field}
                  invalid={!!errors.price}
                />
              )}
            />
            {errors.price && <div className="text-danger">{errors.price.message}</div>}
          </Col>
        </Row>
        <Row style={{ paddingTop: "10px" }}>
          <Col md="6">
            <Label for="type">Type</Label>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={typeOptions}
                  value={typeOptions.find(option => option.value === field.value)}
                  onChange={option => field.onChange(option.value)}
                />
              )}
            />
            {errors.type && <div className="text-danger">{errors.type.message}</div>}
          </Col>
        </Row>
        <Row style={{ paddingTop: "10px" }}>
          <Col md="12">
            <Button type="submit" color="primary">Create Meal</Button>
          </Col>
        </Row>
      </form>

      <Row style={{ paddingTop: "20px" }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <DataTable
            data={meals}
            columns={columns}
            // // keyField="_id"
            // striped
            // pagination
          />
        )}
      </Row>
    </Col>
  );
};

export default CreateEstablishmentMealsForm;
