import axiosInstance from "../../api/axios";

const UPDATE_ETABLISHEMENT = `${process.env.REACT_APP_BACK_URL}/api/v1/etablissement/update`;
const GET_ETABLISHEMENT = `${process.env.REACT_APP_BACK_URL}/api/v1/etablissement`;

export const updateEtablishementData = async (data, id) => {
  if (data instanceof FormData) {
    console.log("🚀 ~ updateEtablishementData ~ data (FormData):");
    for (let pair of data.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
  } else {
    console.log("🚀 ~ updateEtablishementData ~ data:", data);
  }
  try {
    const response = await axiosInstance.put(
      `${UPDATE_ETABLISHEMENT}/${id}`,
      data
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

// //get establishmentById
// export const getEstablishment = async (establishmentId) => {
//   try {
//     const response = await axiosInstance.get(
//       `${GET_ETABLISHEMENT}/${establishmentId}`
//     );
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };
