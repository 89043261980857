import axiosInstance from "../../api/axios";

const GET_ALL_ORDERS = `${process.env.REACT_APP_BACK_URL}/api/v1/order/getOrdersByEstablishmentId`;
const EXECUTE_ORDER = `${process.env.REACT_APP_BACK_URL}/api/v1/order/updateOrder`;

// const ADD_ROOM = `${process.env.REACT_APP_BACK_URL}/api/v1/service/addService`;

export const getAllOrders = async (establishement, page, perPage, services) => {
  try {
    const response = await axiosInstance.get(
      `${GET_ALL_ORDERS}/${establishement}?page=${page}&perPage=${perPage}&services=${services}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const executeOrder = async (data, orderId) => {
  try {
    const response = await axiosInstance.post(
      `${EXECUTE_ORDER}/${orderId}`,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
