import React from "react";
import Offcanvas from "../../../../CommonElements/Offcanvas";
import CollapseCategory from "../../../../Components/ServicePage/Category/collapseCategory";

const CategoryView = ({ isOpen, toggleClose, data }) => {
  return (
    <Offcanvas
      isOpen={isOpen}
      toggleClose={toggleClose}
      titleSideBar={data?.Details}
      style={{ width: "50%" }}
    >
      <h1>{data?.name}</h1>
      <div style={{ width: "100%", paddingTop: "20px" }}>
        <CollapseCategory id={data?._id} />
      </div>
    </Offcanvas>
  );
};

export default CategoryView;
