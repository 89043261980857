import { Navigate, Outlet } from "react-router-dom";
import useProtectedRoute from "../Service/useProtectRoutes";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAffectationByIdAsync } from "../redux-toolkit/slices/authSlice";

const PrivateRoute = () => {
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const hasPermission = useProtectedRoute();
  const { affectationSelected, loadingAffectationSelected } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (affectationSelected == null) {
      const id = localStorage.getItem("affectation");
      dispatch(getAffectationByIdAsync(id));
    }
  }, []);

  return (
    <>
      {affectationSelected == null || loadingAffectationSelected ? (
        <div className="loader-wrapper">
          <div className="theme-loader" />
        </div>
      ) : accessToken ? (
        affectationSelected &&
        (hasPermission ? <Outlet /> : <Navigate to="/dashboard" />)
      ) : (
        <Navigate to="/login" />
      )}
      {/*       
       : accessToken ? affectationSelected && ( <Outlet /> ) : <Navigate to="/login" />  }
        */}
    </>
  );
};

export default PrivateRoute;
