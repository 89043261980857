import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
} from "reactstrap";
import { Btn } from "../../AbstractElements";
import {
  setAffectationSelected,
  getAdminAffectationsAsync,
} from "../../redux-toolkit/slices/authSlice";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../Layout/Loader";
import { useNavigate } from "react-router-dom";

const Affectation = ({ open, setOpen, firstLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const affectationData = useSelector((state) => state.auth.affectations);
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  const adminId = localStorage.getItem("admin");

  console.log("affectationSelectedData", affectationSelectedData);

  console.log("🚀 ~ Affectation ~ affectation:", affectationData);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleToggleModal = () => {
    if (!open) {
      setOpen(!open);
    } else {
      setOpen(!open);
      setSelectedRows([]);
    }
  };
  const supportColumns = [
    {
      name: "Establishement",
      selector: (row) => row?.establishments?.name,
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: (row) => row?.establishments?.type || "N/A",
      sortable: true,
      center: true,
    },
    {
      name: "Role",
      selector: (row) => row?.role[0].name,
      sortable: true,
      center: true,
    },
  ];

  const submitAffectation = () => {
    if (selectedRows.length > 0) {
      localStorage.setItem("affectation", selectedRows[0]?._id);
      dispatch(setAffectationSelected(selectedRows[0]));
      toast.success("Operation succeeded...!");
      setOpen(false);
      if (firstLogin) {
        toast.success("Login Success...!");
        navigate("/dashboard");
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (affectationData.length >= 0) {
      console.log("here enter");
      dispatch(getAdminAffectationsAsync(adminId));
    }
  }, []);

  //   if (affectationData.length >= 0 || affectationData == null) return <Loader />;

  return (
    <div>
      <Modal isOpen={open} size="lg" centered={true} toggle={handleToggleModal}>
        <ModalHeader
          style={{ width: "100%", position: "relative" }}
          className="custom-modal-header"
        >
          Select which etablishement you want to switch
        </ModalHeader>
        <ModalBody style={{ padding: "10px" }}>
          <Col xl="12">
            {affectationData?.length <= 0 ? (
              <div>loading...</div>
            ) : (
              <div className="table-responsive support-table">
                <DataTable
                  columns={supportColumns}
                  data={affectationData}
                  striped={true}
                  pagination
                  selectableRows
                  selectableRowsSingle
                  onSelectedRowsChange={handleRowSelected}
                  highlightOnHover={true}
                  fixedHeader={true}
                />
              </div>
            )}
          </Col>
        </ModalBody>
        <ModalFooter>
          <Btn color="primary" onClick={handleToggleModal}>
            Close
          </Btn>
          <Button
            onClick={submitAffectation}
            color={`success`}
            disabled={selectedRows?.length === 0}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Affectation;
