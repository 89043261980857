import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  CardBody,
  Row,
  Label,
  CardFooter,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../../../../Components/Inputs/simpleInput";
import UploadDropzone from "../../../../Components/Dropzone/UploadDropzone";
import Select from "react-select";
import { toast } from "react-toastify";

import {
  addProduct,
  fetchProducts,
  updateProductAsync,
} from "../../../../redux-toolkit/slices/productSlice";
import { useLocation } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import SweetAlert from "sweetalert2";
import { usePermission } from "../../../../Service/usePermission";


const Products = ({ selectedProduct, setEditProductInNewService, setSelectedProduct, page, perPage }) => {


/*****************Start hooks *******************/  
  //Start useState
   const [hasAnImage, setHasAnImage] = useState(false);
   const [finishEditP,setFinishEditP]=useState(false);
   const [dropzoneKey, setDropzoneKey] = useState(0); //This state is used to reset the Dropzone after successfully submitting the product.
  //End useState

  //useSelector
   const newService = useSelector((state) => state.services.newService);
  
  //useDispatch
   const dispatch = useDispatch();

  //useLocation
  let location = useLocation();

  //useTranslation
  const { t } = useTranslation();

   //useForm hook : we declared it in constants and variables block  to be able to use "schema" const

  // Start useEffect
  useEffect(() => {
    if (selectedProduct) {
      if (Array.isArray(selectedProduct.images) && selectedProduct.images.length > 0) {
        setHasAnImage(true);
      }
      reset({
        name: selectedProduct?.name,
        description: selectedProduct?.description,
        type: { label: selectedProduct?.type, value: selectedProduct?.type },
        duration: selectedProduct?.duration,
        price: selectedProduct?.price,
      });

    }
  }, [selectedProduct]); 
  // End useEffect

   //custom hook:
  // Call usePermission to get necessary permissions we called outSide to avoid condionally call of hooks
  const canExecute = usePermission('static', "service manager", 'execute');
  const canUpdate = usePermission('static', "service manager", 'update');
  /*****************End hooks *******************/  

  /***************** Start constants and variables *******************/
  const pathname = location.pathname.split("/");

  const schema = yup.object().shape({
    name: yup.string().required("You must enter a first name"),
    description: yup.string().required("You must enter the description"),
    price: yup.number().typeError("You must enter a price (must be a number)")
  });

  //useForm
  const {
    control ,
    handleSubmit,
    reset,
    resetField,
    formState: {errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });


  const TYPE = [
    { label: "Appointement vue", value: "clock" },
    { label: "Product vue", value: "product" },
  ];
  /***************** End constants and variables *******************/ 

  /*****************Start Functions and handlers *******************/ 
   const getUploadParams = (body) => {
     return { url: "https://httpbin.org/post" };
   };

   const handleSubmitFile = ({ meta, file, remove }, status) => {
    setValue("images", [file]);
    if (status === "headers_received") {
      toast.success(`${meta.name} uploaded!`);
    } else if (status === "aborted") {
      toast(`${meta.name}, upload failed...`);
    } else if (status === "removed") {
      resetField("images");
    }
  };


  async function onSubmit(data) {
    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("description", data?.description);
    formData.append("price", data?.price);
    formData.append("duration", data?.duration);
    formData.append("type", data?.type?.value);
  
    if (data?.images?.length) {
      data.images.forEach(image => formData.append("images", image));
    }
  
    const resetForm = () => {
      reset({
        name: "",
        description: "",
        type: { label: "", value: "" },
        duration: "08:00:00",
        price: "",
      });
    };
  
    const fetchUpdatedProducts = async () => {
      await dispatch(fetchProducts({ service: location?.state?._id, page, perPage }));
    };
  
    const handleSuccess = (message) => {
      SweetAlert.fire("Success", message, "success");
      resetForm();
      setValue("images", []);  // Reset images field
      setDropzoneKey(prevKey => prevKey + 1);  // Update Dropzone key to force reset
    };
  
    const handleError = (message) => {
      SweetAlert.fire("Error", message, "error");
    };
  
    try {
      let response;
      if (pathname.includes("edit")) {
        formData.append("service", location?.state?._id);
        if (!selectedProduct) {
          response = await dispatch(addProduct(formData));
          if (addProduct.rejected.match(response)) {
            handleError(response.payload || "Error while adding a product!");
          } else {
            handleSuccess(response.payload.message);
          }
        } else {
          response = await dispatch(updateProductAsync({
            productId: selectedProduct?._id,
            productData: formData,
          }));
          if (updateProductAsync.rejected.match(response)) {
            handleError(response.payload || "Error while updating a product!");
          } else {
            handleSuccess(response.payload.message);
            setSelectedProduct(null);
            fetchUpdatedProducts();
            setFinishEditP(true);
          }
        }
      } else if (pathname.includes("new")) {
        formData.append("service", newService?.data?._id);
        if (selectedProduct) {
          response = await dispatch(updateProductAsync({
            productId: selectedProduct?._id,
            productData: formData,
          }));
          if (updateProductAsync.rejected.match(response)) {
            handleError(response.payload || "Error while updating a product!");
          } else {
            handleSuccess(response.payload.message);
            setEditProductInNewService(true);
            setSelectedProduct(null);
            setFinishEditP(true);
          }
        } else {
          response = await dispatch(addProduct(formData));
          if (addProduct.rejected.match(response)) {
            handleError(response.payload || "Error while adding a product!");
          } else {
            handleSuccess(response.payload.message);
          }
        }
      }
    } catch (error) {
      toast.error("An error occurred while processing the product.");
    }
  }

    const handleDeleteImage = () => {
      setHasAnImage(false);
    };

  /*****************End Functions and handlers *******************/ 


  return (
    <>
 <h4 style={{ paddingBottom: "10px" }}>
  { 
    (pathname.includes("edit") || pathname.includes("new")) && selectedProduct
    ? t("Edit product")
    : (pathname.includes("edit") || pathname.includes("new")) && finishEditP
    ? t("Add new product")
    : (pathname.includes("edit") || pathname.includes("new")) && !selectedProduct 
    ? t("Add new product")
    : !pathname.includes("edit") && "Add new product"
  }
</h4>
    <Col xl="12" id="productt">
      <form className="card">
        <CardBody>
          <Row>
            <Col xl="12">
              <Row>
                <Col md="6">
                  <Label>{t("Product name")}</Label>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <SimpleInput
                        {...field}
                        errclassname="error-msg-input" // change to lowercase
                        className="form-control"
                        placeholder="Name"
                        errors={errors.name?.message}
                      />
                    )}
                  />
                </Col>
                <Col md="6">
                  <Label>{t("Description")}</Label>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <SimpleInput
                        {...field}
                        errclassname="error-msg-input" // change to lowercase
                        className="form-control"
                        placeholder="Description"
                        errors={errors.name?.message}
                      />
                    )}
                  />
                </Col>
                </Row>

      
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
            <Col xl="12">
              <Row>
                <Col md="6">
                  {/* <Label>Product name</Label> */}
                  <Label>{t("Price")}</Label>

                   <Controller
                    name="price"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <InputGroup>
                        <InputGroup>
                          <Input
                            {...field}
                            type="text"
                            className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                            placeholder="Price"
                            aria-label="Amount (to the nearest dollar)"
                          />
                          <InputGroupText>{"TND"}</InputGroupText>
                        </InputGroup>
                          {errors.price && (
                              <span className="error-msg-input">
                                {errors.price.message}
                              </span>
                            
                          )}
                      </InputGroup>
                      
                    )}
                  />
                </Col>
                <Col md="6">
                  <Label>{t("Type")}</Label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={TYPE?.map((e) => ({
                          label: e?.label,
                          value: e?.value,
                        }))}
                        {...field}
                      />
                    )}
                  />
                </Col>
                <Col md="6">{/* <Col md="6" className={typeClock ? "d-none" : ""}> */}
                  <Label >{t("Duration")}</Label>
                  <Controller
                    name="duration"
                    control={control}
                    defaultValue="08:00:00"
                    render={({ field }) => (
                      <Input
                        className="digits"
                        type="time"
                        {...field}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ paddingTop: "10px" }}>
          <Col xl="3" className="position-relative">
                {(hasAnImage && (!finishEditP || selectedProduct)) ? (
                  <div className="position-relative">
                    <img
                      src={process.env.REACT_APP_MINIO_URL + selectedProduct?.images[0]}
                      alt="Preview"
                      className="img-fluid"
                    />
                    <FaTrash className="position-absolute top-0 start-0 text-danger" style={{ cursor: 'pointer' }} onClick={handleDeleteImage} />
                  </div>
                ) : (
                  <Controller
                    name="images"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <UploadDropzone
                        key={dropzoneKey} 
                        getUploadParams={getUploadParams}
                        handleChangeStatus={handleSubmitFile}
                        {...field}
                      />
                    )}
                  />
                )}
              </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Button
            onClick={handleSubmit(onSubmit)}
            color="secondary"
            disabled={
                  (selectedProduct && !canUpdate ) ||
                  (!selectedProduct && !canExecute)
             }
          >{}
            { 
              (pathname.includes("edit") || pathname.includes("new")) && selectedProduct
              ? t("Edit")
              : (pathname.includes("edit") || pathname.includes("new")) && finishEditP
              ? t("Add")
              : (pathname.includes("edit") || pathname.includes("new")) && !selectedProduct 
              ? t("Add")
              : !pathname.includes("edit") && "Add"
            }
          </Button>
        </CardFooter>
      </form>
    </Col>
    </>
  );
};

export default Products;
