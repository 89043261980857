import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Check } from "react-feather";
import {  fetchAppointmentsAsync } from "../../redux-toolkit/slices/appointmentSlice";
import AppointmentDetailsModal from "./AppointmentDetailsModal";


const Appointment = () => {
  const [statusExecute, setStatusExecute] = useState(null);
  const [selectedAppointment, setSelectedOrder] = useState(null);
  const [page, setPage] = useState(1); // State for current page
  const [perPage, setPerPage] = useState(10); // State for items per page
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const appointmentsData = useSelector((state) => state.appointments.appointmentsData);
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

 
  const handleToggleModal = (statut, row) => {
    if (!open) {
      setStatusExecute(statut);
      setSelectedOrder(row);
      setOpen(!open);
    } else {
      setOpen(!open);
      setStatusExecute(null);
      setSelectedOrder(null);
    }
  };

  const supportColumns = [
    {
      name: "Guest",
      selector: (row) => {
        const firstName = row?.user?.firstName || "N/A";
        const lastName = row?.user?.lastName || "N/A";
        return (
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ paddingRight: "5px" }}>{firstName}</div>{" "}
            <div style={{ paddingLeft: "5px" }}>{lastName}</div>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
    {
        name: "Persons' number",
        selector: (row) => row?.numberOfPerson,
        sortable: true,
        center: true,
      },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      center: true,
    },
   
    {
      name: "Action",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {row?.status === "pending" ? (
            <div>
              <button
                type="button"
                className="btn btn-outline-success py-sm px-2 m-1"
                onClick={() => handleToggleModal("Approve", row)}
              >
                Approve
              </button>
              <button
                type="button"
                className="btn btn-danger py-sm px-2 m-1"
                onClick={() => handleToggleModal("Decline", row)}
              >
                Decline
              </button>
            </div>
          ) : (
            <>
              {" "}
              <Check size={16} />
            </>
          )}
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (affectationSelected) {
        try {
          const res = await dispatch(
            fetchAppointmentsAsync({
              establishmentId: affectationSelected?.establishments?._id,
              page,
              perPage,
            })
          );
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      }
    };

    fetchData();
  }, [dispatch, affectationSelected, page, perPage]);

  return (
    <div>
      <div className="table-responsive support-table">
        <DataTable
          columns={supportColumns}
          data={appointmentsData?.data}
          striped={true}
          pagination
          paginationServer={true} // Enable server-side pagination
          paginationTotalRows={appointmentsData?.totalData} // Total number of rows for pagination
          onChangePage={handlePageChange} // Handler for page change
          paginationPerPage={perPage} // Number of rows per page
          paginationRowsPerPageOptions={[10, 20, 30, 40]} // Options for rows per page
          onChangeRowsPerPage={handlePerRowsChange} // Handler for rows per page change
          expandOnRowClicked={true}
          highlightOnHover={true}
          fixedHeader={true}
        />
      </div>
      <AppointmentDetailsModal open={open} handleToggleModal={handleToggleModal} statusExecute={statusExecute} selectedAppointment={selectedAppointment} setOpen={setOpen} />
    </div>
  );
};

export default Appointment;
