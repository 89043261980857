import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
} from "reactstrap";
import moment from "moment";
import "moment/locale/fr";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  executeMyOrder,
  fetchOrdersAsync,
} from "../../redux-toolkit/slices/ordersSlice";
import { Check } from "react-feather";
import { Btn } from "../../AbstractElements";
import { toast } from "react-toastify";
import { usePermission } from "../../Service/usePermission";
import { extractServicesWithExecutePermission } from "../../Service/extractServiceWithExecutePermission";

const Orders = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [statusExecute, setStatusExecute] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [note, setNote] = useState("");
  const [page, setPage] = useState(1); // State for current page
  const [perPage, setPerPage] = useState(10); // State for items per page
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  const ordersData = useSelector((state) => state.orders.ordersData);
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleToggleModal = (statut, row) => {
    if (!open) {
      setStatusExecute(statut);
      setSelectedOrder(row);
      setOpen(!open);
    } else {
      setOpen(!open);
      setStatusExecute(null);
      setSelectedOrder(null);
    }
  };

  const services = extractServicesWithExecutePermission(
    affectationSelected?.role
  );

  const supportColumns = [
    {
      name: "Guest",
      selector: (row) => {
        const firstName = row?.affectationId?.user?.firstName || "N/A";
        const lastName = row?.affectationId?.user?.lastName || "N/A";
        return (
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ paddingRight: "5px" }}>{firstName}</div>{" "}
            <div style={{ paddingLeft: "5px" }}>{lastName}</div>
          </div>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "Service",
      selector: (row) => row?.products[0]?.product?.service?.name,
      sortable: true,
      center: true,
    },
    {
      name: "Client status",
      selector: (row) => row?.clientStatus,
      sortable: true,
      center: true,
    },
    {
      name: "Order status",
      selector: (row) => row?.orderStatus,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          {row?.orderStatus === "pending" ? (
            <div>
              <button
                type="button"
                className="btn btn-outline-success py-sm px-2 m-1"
                onClick={() => handleToggleModal("Approve", row)}
              >
                Approve
              </button>
              <button
                type="button"
                className="btn btn-danger py-sm px-2 m-1"
                onClick={() => handleToggleModal("Decline", row)}
              >
                Decline
              </button>
            </div>
          ) : (
            <>
              {" "}
              <Check size={16} />
            </>
          )}
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  const executeOrder = async () => {
    let status = "";
    if (statusExecute === "Approve") {
      status = "done";
    } else {
      status = "declined";
    }

    const payload = {
      note: note,
      time: selectedOrder.time,
      orderStatus: status,
      clientStatus: selectedOrder.clientStatus,
    };
    const orderExecuted = await dispatch(
      executeMyOrder({
        data: payload,
        orderId: selectedOrder._id,
      })
    );
    console.log("orderExecuted: ", orderExecuted);
    if (executeMyOrder.rejected.match(orderExecuted)) {
      toast.error(orderExecuted.payload || "Error executing order !");
    } else {
      toast.success(
        orderExecuted?.payload?.message || "order executed Successfully...!"
      );
      await dispatch(
        fetchOrdersAsync({
          establishmentId: affectationSelected?.establishments?._id,
          page,
          perPage,
          services,
        })
      );
    }
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (affectationSelected) {
        try {
          const res = await dispatch(
            fetchOrdersAsync({
              establishmentId: affectationSelected?.establishments?._id,
              page,
              perPage,
              services,
            })
          );
        } catch (error) {
          console.error("Error fetching orders:", error);
          // Handle error as needed
        }
      }
    };

    fetchData();
  }, [dispatch, affectationSelected, page, perPage]);

  const canExecute = usePermission("static", "orders", "execute");

  return (
    <div>
      <div className="table-responsive support-table">
        <DataTable
          columns={supportColumns}
          data={ordersData?.data}
          striped={true}
          pagination
          paginationServer={true} // Enable server-side pagination
          paginationTotalRows={ordersData?.totalData} // Total number of rows for pagination
          onChangePage={handlePageChange} // Handler for page change
          paginationPerPage={perPage} // Number of rows per page
          paginationRowsPerPageOptions={[10, 20, 30, 40]} // Options for rows per page
          onChangeRowsPerPage={handlePerRowsChange} // Handler for rows per page change
          selectableRows
          onSelectedRowsChange={handleRowSelected}
          expandOnRowClicked={true}
          highlightOnHover={true}
          fixedHeader={true}
        />
      </div>
      {open && (
        <Modal
          isOpen={open}
          size="lg"
          centered={true}
          toggle={handleToggleModal}
        >
          <ModalHeader
            style={{ width: "100%", position: "relative" }}
            className="custom-modal-header"
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h5 style={{ paddingRight: "10px", margin: "0" }}>
                  {selectedOrder.affectationId.user.firstName}
                </h5>
                <h5 style={{ margin: "0" }}>
                  {selectedOrder.affectationId.user.lastName}
                </h5>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  paddingRight: "20px",
                }}
              >
                {" "}
                Room number : {selectedOrder?.roomId?.roomNumber}{" "}
              </div>
            </div>
          </ModalHeader>
          <ModalBody style={{ padding: "10px" }}>
            <Col xl="12">
              <Row>
                <Label size="xl" style={{ fontWeight: "bold" }}>
                  Order details :
                </Label>
                <div
                  style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ paddingRight: "10px" }}>
                      Service name :
                    </Label>
                    <Label>
                      {selectedOrder?.products[0]?.product?.service?.name}
                    </Label>
                  </div>

                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ paddingRight: "10px" }}>Time :</Label>
                    <Label>
                      {moment(selectedOrder.time).locale("fr").format("LLL")}
                    </Label>
                  </div>

                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ paddingRight: "10px" }}>
                      Order Status :
                    </Label>
                    <Label>{selectedOrder.orderStatus}</Label>
                  </div>

                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ paddingRight: "10px" }}>
                      Client Status :
                    </Label>
                    <Label>{selectedOrder.clientStatus}</Label>
                  </div>

                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ paddingRight: "10px" }}>Total :</Label>
                    <Label>{selectedOrder.total} TND</Label>
                  </div>

                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ paddingRight: "10px" }}>Note :</Label>
                    <Label>{selectedOrder.note}</Label>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "10px",
                    }}
                  >
                    <Label style={{ fontWeight: "bold", width: "100%" }}>
                      Products:
                    </Label>
                    {selectedOrder.products.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "10px",
                        }}
                      >
                        <Label style={{ paddingRight: "10px" }}>
                          Product {index + 1}:
                        </Label>
                        <Label style={{ paddingLeft: "10px" }}>
                          Name: {item.product.name}
                        </Label>
                        <Label style={{ paddingLeft: "10px" }}>
                          Quantity: {item.quantity}
                        </Label>
                      </div>
                    ))}
                  </div>
                </div>
              </Row>

              <Label style={{ fontWeight: "bold" }}>
                {statusExecute} note :
              </Label>
              <Row style={{ padding: "10px" }}>
                <Input
                  type="textarea"
                  name="text"
                  id="exampleText"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Row>
            </Col>
          </ModalBody>
          <ModalFooter>
            <Btn color="primary" onClick={handleToggleModal}>
              Close
            </Btn>
            <Button
              onClick={executeOrder}
              color={statusExecute === "Approve" ? `success` : `danger`}
              disabled={!canExecute}
            >
              {statusExecute}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default Orders;
