import React from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";

const index = ({ isOpen, toggleClose, titleSideBar, children, style }) => {
  return (
    <div>
      <Offcanvas
        autoFocus={true}
        isOpen={isOpen}
        direction="end"
        fade={true}
        toggle={function noRefCheck() {}}
        style={style}
      >
        <OffcanvasHeader toggle={toggleClose}>{titleSideBar}</OffcanvasHeader>
        <OffcanvasBody>{children}</OffcanvasBody>
      </Offcanvas>
    </div>
  );
};

export default index;
