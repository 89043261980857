import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getServicesByCategoryIdAsync } from '../../../redux-toolkit/slices/categorySlice';
import { Accordion, Col } from 'react-bootstrap';
import { Card, CardBody, Collapse } from 'reactstrap';
import HeadingCommon from '../../../Common/HeadingCommon';
import moment from "moment";
import "moment/locale/fr";
import { Image } from '../../../AbstractElements';
import { dynamicImage } from '../../../Service';
import './collapseStyle.css'; 

const CollapseCategory = ({ id }) => {
  /************* Start Hooks **************/
  // useState
  const [isOpen, setIsOpen] = useState(1);
  const [services, setServices] = useState();

  // useDispatch
  const dispatch = useDispatch();

  // Start UseEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          const servicess = await dispatch(getServicesByCategoryIdAsync({ categoryId: id }));
          setServices(servicess?.payload?.data);
        }
      } catch (error) {
        console.error("Error fetching category tree:", error);
      }
    };

    fetchData();
  }, [dispatch, id]);
  // End useEffect
  /************* End Hooks **************/

  /************* Start Functions & handlers **************/
  const toggle = (id) => {
    setIsOpen((prevOpen) => (prevOpen === id ? null : id));
  };

  /************* End Functions & handlers **************/
  return (
    <Col sm="12" xl="12">
      <Accordion defaultActiveKey="0">
        <Card>
          <CardBody>
            <div className="default-according" id="accordion1">
              {services?.map((item, index) => (
                <Card key={index}>
                  <HeadingCommon
                    className={index + 1 === isOpen ? "bg-primary" : " heading-common-white "}
                    H5ClassName="mb-0"
                    Btn={true}
                    span={true}
                    BtnclassName={index + 1 === isOpen ? "btn-link txt-white" : "btn-link txt-dark "}
                    BtnColor="default"
                    BtnOnClick={() => toggle(index + 1)}
                    BtnText={item.name}
                    BtnSpanClass="digits"
                  />
                  <Collapse isOpen={isOpen === index + 1}>
                    <CardBody>
                      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center" }}>
                        <div style={{ maxWidth: "50%", flex: "1" }}>
                          <Image
                            src={item.images?.length > 0 ? process.env.REACT_APP_MINIO_URL + item.images[0] : dynamicImage("ecommerce/options.png")}
                            className="m-auto d-block img-fluid"
                            alt="Item"
                          />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", flex: "1", marginLeft: "1rem" }}>
                          <h6>Description :</h6>
                          <p>{item.description}</p>
                          <h6>Created at :</h6>
                          <p>{moment(item.createdAt).locale("fr").format("LLL")}</p>
                        </div>
                      </div>
                    </CardBody>
                  </Collapse>
                </Card>
              ))}
            </div>
          </CardBody>
        </Card>
      </Accordion>
    </Col>
  );
};

export default CollapseCategory;
