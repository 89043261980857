import React from 'react'
import Breadcrumbs from '../../CommonElements/Breadcrumbs'
import Appointment from '../../Components/Appointment/Appointment'

const Appointments = () => {
  return (
    <div className="page-body">
    <Breadcrumbs title="Appointments" />
    <div>
      <Appointment />
    </div>
  </div>
  )
}

export default Appointments