import React from 'react';
import { Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Btn, Image } from "../../AbstractElements";
import Swipper from '../Swiper/Swiper';
import { dynamicImage } from "../../Service";

const ComplaintDetails = ({ open, setOpen, sectedComplaint }) => {

    /************ Start functions and handlers ************/
    const handleToggleModal = () => {
        setOpen(!open);
    };

    /************ End functions and handlers ************/
    return open ? (
        <div>
            <Modal
                isOpen={open}
                size="lg"
                centered={true}
                toggle={handleToggleModal}
            >
                <ModalHeader className="w-100 position-relative custom-modal-header">
                    <div className="d-flex justify-content-between w-100">
                        <div>Service: {sectedComplaint?.service?.name || ""}</div>
                        <div className="position-absolute end-0 pe-3">Status: {sectedComplaint?.status}</div>
                    </div>
                </ModalHeader>
                <ModalBody className="p-2">
                <Row>   
                <Col xl="3">
                               <Label size="xl" style={{ fontWeight: "bold" }}>
                               Images :
                               </Label>
                              {sectedComplaint && sectedComplaint.images?.length > 0 ? (
                                <Swipper
                                  oldImages={sectedComplaint.images}
                                  canDelete={false}
                                />
                              ) : (
                                <div className="d-flex align-item-center justify-content-center w-100 h-100">
                                  <Image
                                    src={dynamicImage("ecommerce/options.png")}
                                    className=" m-auto col-sm-4  d-block img-fluid  "
                                  />
                                </div>
                              )}
                      </Col>
                      <Col xl="9">
                         <Row>
                         <Label size="xl" style={{ fontWeight: "bold" }}>
                           Description :
                         </Label>
                               <p>{sectedComplaint?.description}</p>    
                         </Row>
                          <Row>
                          <Col xl="">
                         <Label size="xl" style={{ fontWeight: "bold" }}>
                           Client :
                         </Label>
                              <div>
                               <p> Name: {sectedComplaint?.affectationUser?.user?.firstName} {sectedComplaint?.affectationUser?.user?.lastName}</p>
                               <p> Email: {sectedComplaint?.affectationUser?.user?.email} </p>
                               <p> Phone: {sectedComplaint?.affectationUser?.user?.phone} </p>
                              </div>  
                      </Col>
                          </Row>
                      </Col>
                      </Row>
                      <Row>
                     
                      </Row>
                      <Row>
                     
                      </Row>
                </ModalBody>
                <ModalFooter>
                  <Btn color="primary" onClick={handleToggleModal}>
                   Close
                 </Btn>
                </ModalFooter>
            </Modal>
        </div>
    ) : null;
}

export default ComplaintDetails;
