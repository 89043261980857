import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllOrders, executeOrder } from "../services/ordersService";

const initialState = {
  orders: [],
  ordersData: {},
  loading: false,
  error: null,
};

export const fetchOrdersAsync = createAsyncThunk(
  "orders/fetchData",
  async ({establishmentId, page, perPage, services}) => {
    try {
      const response = await getAllOrders(establishmentId, page, perPage, services);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const executeMyOrder = createAsyncThunk(
  "orders/execute",
  async ({ data, orderId }, { rejectWithValue }) => {
    try {
      const response = await executeOrder(data, orderId);
      console.log("ressspp", response.data)
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrdersAsync.fulfilled, (state, action) => {
      state.orders = action.payload.data; //this line must be removed 
      state.ordersData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(executeMyOrder.fulfilled, (state, action) => {
      state.ordersData.data = state.ordersData.data?.map((elem) => elem._id === action.payload?.data?._id ? action.payload?.data : elem )
      state.loading = false;
      state.error = null;
    });
    builder.addCase(executeMyOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const ordersReducer = ordersSlice.reducer;
export const { actions } = ordersSlice;
