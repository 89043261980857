import React, { useState } from 'react'
import {
    Button,
    Col,
    Row,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
  } from "reactstrap";
import { useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/fr";
import { toast } from "react-toastify";
import { executeAppointmentAsync } from "../../redux-toolkit/slices/appointmentSlice";
import { Btn } from "../../AbstractElements";
import { usePermission } from '../../Service/usePermission';

const AppointmentDetailsModal = ({open, handleToggleModal, statusExecute, selectedAppointment, setOpen}) => {

/***************** Start Hooks ****************/ 
  //useDispatch
  const dispatch = useDispatch();

  const canExecute = usePermission('static', "appointment", 'execute');

/***************** End Hooks ****************/ 
  

/***************** Start functions and handlers ****************/ 
   const executeOrder = async () => {
    let status = selectedAppointment?.status;
    if (statusExecute === "Approve") {
      status = "accepted";
    } else {
      status = "rejected";
    }

    const payload = {
      status: status,
    };
    const appointmentExecuted = await dispatch(
        executeAppointmentAsync({
        data: payload,
        appointmentId: selectedAppointment._id,
      })
    );
    console.log(appointmentExecuted)
    if (executeAppointmentAsync.rejected.match(appointmentExecuted)) {
        toast.error(appointmentExecuted.payload || "Error executing Appointment !");
    } else {
        toast.success(appointmentExecuted.payload?.data?.message || "appointment executed Successfully...!");
    }
    setOpen(false);
  };
/***************** End functions and handlers ****************/ 

  return (
    <div>
    {open && (
        <Modal
          isOpen={open}
          size="lg"
          centered={true}
          toggle={handleToggleModal}
        >
          <ModalHeader
            style={{ width: "100%", position: "relative" }}
            className="custom-modal-header"
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h5 style={{ paddingRight: "10px", margin: "0" }}>
                  {selectedAppointment?.user?.firstName}
                </h5>
                <h5 style={{ margin: "0" }}>
                  {selectedAppointment?.user?.lastName}
                </h5>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  paddingRight: "20px",
                }}
              >
                {" "}
                Number of persons : {selectedAppointment?.numberOfPerson}{" "}
              </div>
            </div>
          </ModalHeader>
          <ModalBody style={{ padding: "10px" }}>
            <Col xl="12">
              <Row>
                <Label size="xl" style={{ fontWeight: "bold" }}>
                  Appointment details :
                </Label>
                <div
                  style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                >
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ paddingRight: "10px" }}>
                      Details :
                    </Label>
                    <Label>
                      {selectedAppointment?.details}
                    </Label>
                  </div>

                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ paddingRight: "10px" }}>Time :</Label>
                    <Label>
                      {moment(selectedAppointment?.date).locale("fr").format("LLL")}
                    </Label>
                  </div>

                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ paddingRight: "10px" }}>
                      Appointment Status :
                    </Label>
                    <Label>{selectedAppointment?.status}</Label>
                  </div>

                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Label style={{ paddingRight: "10px" }}>
                      Phone :
                    </Label>
                    <Label>{selectedAppointment?.user?.phone}</Label>
                  </div>
                </div>
              </Row>

            </Col>
          </ModalBody>
          <ModalFooter>
            <Btn color="primary" onClick={handleToggleModal}>
              Close
            </Btn>
            <Button
              onClick={executeOrder}
              color={statusExecute === "Approve" ? `success` : `danger`}
              disabled={!canExecute}
            >
              {statusExecute}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      </div>
  )
}

export default AppointmentDetailsModal