import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, CardBody, CardFooter, Col, Label, Row } from 'reactstrap'
import SimpleInput from '../Inputs/simpleInput'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { updateEmployeeAsync } from '../../redux-toolkit/slices/employeeSlice'
import { usePermission } from '../../Service/usePermission'

const UpdateForm = () => {
    const dispatch = useDispatch();
    const affectationSelected = useSelector(
      (state) => state.auth.affectationSelected
    );
    const schema = yup.object().shape({
      firstname: yup.string().required("You must enter the first name"),
      lastname: yup.string().required("You must enter the last name"),
      email: yup.string().email("Invalid email").required("You must enter the email"),
      phone: yup.string().required("You must enter the phone number"),
      username: yup.string().required("You must enter the username"),
      status: yup.string().required("You must enter the status"),
    });
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
      });

      useEffect(() => {
        if (affectationSelected?.admin) {
          reset({
            firstname: affectationSelected.admin.firstName || "",
            lastname: affectationSelected.admin.lastName || "",
            email: affectationSelected.admin.email || "",
            phone: affectationSelected.admin.phone || "",
            username: affectationSelected.admin.username || "",
            status: affectationSelected.admin.status || "",
          });
        }
      }, [affectationSelected, reset]);
    
      const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("firstName", data?.firstname);
        formData.append("lastName", data?.lastname);
        formData.append("email", data?.email);
        formData.append("phone", data?.phone);
        formData.append("username", data?.username);
        formData.append("status", data?.status);
    
        try {
          await dispatch(updateEmployeeAsync({
            employeeId: affectationSelected?.admin?._id,
            updatedAdminData: formData 
          }));
          toast.success("Profile updated successfully");
        } catch (error) {
          console.log("An error occurred while updating the profile", error);
          toast.error("An error occurred while updating the profile");
        }
      };

  return (
    <div>
         <form className="card">
          <CardBody>
            <Row className="w-100">
              <Col xl="12">
                <Row>
                  <Col md="6">
                    <Label>Firstname</Label>
                    <Controller
                      name="firstname"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Firstname"
                          errors={errors.firstname?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>Lastname</Label>
                    <Controller
                      name="lastname"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Lastname"
                          errors={errors.lastname?.message}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col md="6">
                    <Label>Email</Label>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          style={{ backgroundColor: "#FAFAFA" }}
                          disabled
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Email"
                          errors={errors.email?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>Username</Label>
                    <Controller
                      name="username"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          disabled
                          style={{ backgroundColor: "#FAFAFA" }}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Username"
                          errors={errors.username?.message}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col md="6">
                    <Label>Phone number</Label>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          disabled
                          style={{ backgroundColor: "#FAFAFA" }}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Phone"
                          errors={errors.phone?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>Status</Label>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          disabled
                          style={{ backgroundColor: "#FAFAFA" }}
                          errclassname="error-msg-input"
                          className="form-control"
                          placeholder="Status"
                          errors={errors.status?.message}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-end">
              <Button onClick={handleSubmit(onSubmit)} color="secondary" disabled={!usePermission('static', "profile", 'update') }>
                Save
              </Button>
           
          </CardFooter>
        </form>
    </div>
  )
}

export default UpdateForm