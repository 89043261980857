import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClients } from "../../redux-toolkit/slices/clientSlice";
import { fetchUsers } from "../../redux-toolkit/slices/userSlice";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import { Edit, Delete } from "react-feather";
import ClientTable from "../../Components/Client/dataTableClient";
import UpdateAffectation from "../../Components/Client/updateAffectation";
import { updateAffectationUserAsync } from "../../redux-toolkit/slices/affectationSlice";
import { useTranslation } from "react-i18next";
import SweetAlertDelete from "../../Components/sweetAlertComponet/sweetAlertDelete";
import SweetAlert from "sweetalert2";
import { usePermission } from "../../Service/usePermission";

const UsersPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const usersData = useSelector((state) => state.users.users);
  const clientsData = useSelector((state) => state.clients.clientsData);
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showAlert, setShowAlert] = useState(false); // New state for SweetAlert
  
  const canDelete = usePermission('static', "clients", 'delete');

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  const confirmDisableAffectation = async () => {
    try {
      const affectationId = selectedRow?._id;
      console.log(
        "🚀 ~ confirmDisableAffectation ~ affectationId:",
        affectationId
      );
      const updatedAffectation = await dispatch(
        updateAffectationUserAsync({
          id: affectationId,
          affectationData: { status: "inactive" },
        })
      );

      setSelectedRow(null);

      if (updateAffectationUserAsync.fulfilled.match(updatedAffectation)) {
        SweetAlert.fire(
          t("Deactivated"),
          t("Affectation deactivated successfully!"),
          "success"
        );
        setShowAlert(false);
        dispatch(
          fetchClients({
            establishmentId: affectationSelectedData.establishments._id,
            page,
            perPage,
          })
        );
      } else {
        SweetAlert.fire(t("Error!"), `${updatedAffectation.payload}`, "error");
      }
    } catch (error) {
      SweetAlert.fire(
        t("Error!"),
        t("Affectation could not be deactivated."),
        "error"
      );
      console.error("Error deactivating Affectation:", error);
    }
  };

  const supportColumns = [
    {
      name: t("First Name"),
      selector: (row) => row["user"]["firstName"],
      sortable: true,
      center: true,
    },
    {
      name: t("Last Name"),
      selector: (row) => row["user"]["lastName"],
      sortable: true,
      center: true,
    },
    {
      name: t("Phone"),
      selector: (row) => row["user"]["phone"],
      sortable: true,
      center: true,
    },
    {
      name: t("Email"),
      selector: (row) => row["user"]["email"],
      sortable: true,
      center: true,
    },
    {
      name: t("Rooms"),
      selector: (row) => {
        if (row["rooms"] && row["rooms"].length > 0) {
          return row["rooms"].map((room) => room["roomNumber"]).join(", ");
        } else {
          return "N/A";
        }
      },
      sortable: true,
      center: true,
    },
    {
      name: t("Action"),
      selector: (row) => (
        <div style={{ display: "flex" }}>
          <div className="btn-xs" style={{ cursor: "pointer" }}>
            <Edit
              width={20}
              color="#05579e"
              onClick={() => {
                setIsOpen2(true);
                setSelectedRow(row);
              }}
            />
          </div>
          <div className="btn-xs" style={{ cursor: "pointer" }}>
            <Delete
              width={20}
              color="red"
              onClick={canDelete ? () => {
                setShowAlert(true);
                setSelectedRow(row);
              } : null}
              style={{ 
                cursor: !canDelete ? 'not-allowed' : 'pointer', 
                opacity: !canDelete  ? 0.5 : 1 
              }}
            />
          </div>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (affectationSelectedData) {
        await dispatch(
          fetchClients({
            establishmentId: affectationSelectedData?.establishments._id,
            page,
            perPage,
          })
        );
      }
    };

    fetchData();
  }, [dispatch, affectationSelectedData, page, perPage]);

  return (
    <div className="page-body">
      <Breadcrumbs title={t("Clients")} />
      {clientsData.data && affectationSelectedData && (
        <div>
          <ClientTable
            columns={supportColumns}
            data={clientsData.data}
            highlightOnHover={true}
            fixedHeader={true}
            paginationTotalRows={clientsData?.totalData}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            
          />
          <UpdateAffectation
            isOpen2={isOpen2}
            setIsOpen2={setIsOpen2}
            selectedAffectation={selectedRow}
            
          />
          {showAlert && (
            <SweetAlertDelete
              title={t("Are you sure?")}
              text={t("Are you sure you want to deactivate this assignment?")}
              icon="warning"
              confirmButtonText={t("Ok")}
              cancelButtonText={t("Cancel")}
              onConfirm={confirmDisableAffectation}
              setShowAlert={setShowAlert}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default UsersPage;
