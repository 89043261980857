import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllUsers,
  getUserByUniqueId,
  updateUser,
  getUsersFromAffectationByEstablishmentId,
  updateRoleUser,
  CreateUser,
  passportOcrTun,
  ScanIdentity,
} from "../services/userService";

const initialStateUsers = {
  users: [],
  loading: false,
  error: null,
  loadingScan: false
};

export const fetchUsers = createAsyncThunk("users/fetchData", async () => {
  try {
    const response = await getAllUsers();
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const getUsersFromAffectation = createAsyncThunk(
  "users/fetchDataAff",
  async (establishmentId) => {
    try {
      const response = await getUsersFromAffectationByEstablishmentId(
        establishmentId
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserByUniqueIdAsync = createAsyncThunk(
  "users/fetchDataById",
  async (uniqueId) => {
    try {
      const response = await getUserByUniqueId(uniqueId);
      console.log("🚀 ~ response:aaaa", response);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "users/update",
  async ({ userId, email, status, firstName, lastName, role }) => {
    try {
      const response = await updateUser(
        userId,
        email,
        status,
        firstName,
        lastName,
        role
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateRoleUserAsync = createAsyncThunk(
  "users/updateRole",
  async (updateUserRole) => {
    console.log("🚀 ~ updateUserRole:", updateUserRole);
    try {
      const response = await updateRoleUser(updateUserRole);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const passportOcrTunAsync = createAsyncThunk(
  "users/passportOcrTun",
  async (file) => {
    try {
      const response = await passportOcrTun(file);
      return response; // Return the entire response object
    } catch (error) {
      throw error;
    }
  }
);
export const createUserAsync = createAsyncThunk(
  "users/create",
  async (transformedData, { rejectWithValue }) => {
    console.log("🚀 ~ transformedData:", transformedData);
    try {
      const response = await CreateUser(transformedData);
      console.log("🚀 ~ response:", response);
      return response.user;
    } catch (error) {
      console.log("🚀 ~ error:", error.response.data.message);
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


//scan identity
export const scanIdentityAsync = createAsyncThunk(
  "users/scan",
  async ({scanData}, { rejectWithValue }) => {
    console.log("🚀 ~ scanData:", scanData);
    try {
      const response = await ScanIdentity(scanData);
      console.log("🚀 ~ response:", response);
      return response;
    } catch (error) {
      console.log("🚀 ~ error:", error.response.data.message);
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: initialStateUsers,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(scanIdentityAsync.fulfilled, (state, action) => {
      state.loadingScan = false;
      state.error = null;
    });
    builder.addCase(scanIdentityAsync.rejected, (state, action) => {
      state.loadingScan = false;
      state.error = action.error;
    });
    builder.addCase(scanIdentityAsync.pending, (state, action) => {
      state.loadingScan = true;
      state.error = action.error;
    });
    

  },
});

export const usersReducer = usersSlice.reducer;
const { actions } = { usersSlice };
