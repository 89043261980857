import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  // getAllUsers,
  // getUserByUniqueId,
  // updateUser,
  // getUsersFromAffectationByEstablishmentId,
  getClientsFromAffectationByEstablishmentId,
  // updateRoleUser,
  getAllClients,
  // CreateUser,
  passportOcrTun,
} from "../services/clientService";

// const initialStateUsers = {
const initialStateClients = {
  // users: [],
  // clients: [],
  clientsData: {},
  loading: false, // Add the type annotation for boolean
  error: null,
};

// export const fetchUsers = createAsyncThunk("users/fetchData", async () => {
//   try {
//     const response = await getAllUsers();
//     return response.data;
   
//   } catch (error) {
//     throw error;
//   }
// });
export const fetchClients = createAsyncThunk(
  "clients/fetchData",
  async ({establishmentId, page, perPage}) => {
    try {
      const response = await getAllClients(establishmentId, page, perPage);
      console.log(
        "🚀 ~ file: clientSlice.jsx:30 ~ fetchClients ~ response:",
        response
      );
      console.log("clientsssss",response.data)
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
// export const getUsersFromAffectation = createAsyncThunk(
//   "users/fetchData",
//   async (establishmentId) => {
//     try {
//       const response = await getUsersFromAffectationByEstablishmentId(
//         establishmentId
//       );

//       return response.data.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );
export const getClientsFromAffectation = createAsyncThunk(
  "clients/fetchDataAff",
  async (establishmentId) => {
    try {
      const response = await getClientsFromAffectationByEstablishmentId(
        establishmentId
      );

      return response.data.data;
    } catch (error) {
      throw error;
    }
  }
);
// export const getAffectationByUserId = createAsyncThunk(
//   "users/fetchData",
//   async (userId) => {
//     try {
//       const response = await getAffectationByUserId(userId);

//       return response.data.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );
// export const fetchUserByUniqueIdAsync = createAsyncThunk(
//   "users/fetchData",
//   async (uniqueId) => {
//     try {
//       const response = await getUserByUniqueId(uniqueId);
//       console.log("response===========", response);
//       return response.data.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

// export const updateUserAsync = createAsyncThunk(
//   "users/update",
//   async ({ userId, email, status, firstName, lastName, role }) => {
//     try {
//       const response = await updateUser(
//         userId,
//         email,
//         status,
//         firstName,
//         lastName,
//         role
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

// export const updateRoleUserAsync = createAsyncThunk(
//   "users/update",
//   async ({ userId, role }) => {
//     try {
//       const response = await updateRoleUser(userId, role);
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );
export const passportOcrTunAsync = createAsyncThunk(
  "users/passportOcrTun",
  async (file) => {
    try {
      const response = await passportOcrTun(file);
      return response; // Return the entire response object
    } catch (error) {
      throw error;
    }
  }
);
// export const createUserAsync = createAsyncThunk(
//   "users/create",
//   async ({
//     email,
//     password,
//     firstName,
//     lastName,
//     phone,
//     username,
//     role,
//     uniqueId,
//   }) => {
//     try {
//       const response = await CreateUser(
//         email,
//         password,
//         firstName,
//         lastName,
//         phone,
//         username,
//         role,
//         uniqueId
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

// const usersSlice = createSlice({
//   name: "users",
//   initialState: initialStateUsers,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder.addCase(fetchUsers.fulfilled, (state, action) => {
//       state.users = action.payload;
//       state.loading = false;
//       state.error = null;
//     });
//   },
// });

const clientSlice = createSlice({
  name: "clients",
  initialState: initialStateClients,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      console.log("actiiii",action.payload)
      state.clientsData = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});
// export const usersReducer = usersSlice.reducer;
export const clientsReducer = clientSlice.reducer;
const { actions } = {clientSlice };
