import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { AddNewEmployee } from "../../Constant";
import { useTranslation } from "react-i18next";
import AddUserForm from "./addUserForm";
import { Button } from "reactstrap";
import { usePermission } from "../../Service/usePermission";

const ClientTable = ({
  columns,
  data,
  highlightOnHover,
  fixedHeader,
  paginationTotalRows, // Total rows for pagination
  onChangePage, // Add page change handler
  onChangeRowsPerPage, // Add per page change handler
}) => {
  const { t } = useTranslation();
  const [toggleDelete, setToggleDelete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  

  const handelOpenModal = () => {
    setIsOpen(true);
  };

  const canExecute = usePermission('static', "clients", 'execute');
  return (
    <div className="table-responsive support-table">
      <div
        className={`d-flex align-items-center justify-content-end bg-light-info p-2`}
      >
        <Button color="secondary" onClick={() => handelOpenModal()} disabled={!canExecute}>
          {t("Add Client")}
        </Button>
      </div>
      <DataTable
        columns={columns}
        data={data}
        striped={true}
        pagination
        paginationServer
        paginationTotalRows={paginationTotalRows} // Total rows for pagination
        onChangePage={onChangePage} // Add page change handler
        onChangeRowsPerPage={onChangeRowsPerPage} // Add per page change handler
        clearSelectedRows={toggleDelete}
        highlightOnHover={highlightOnHover}
        fixedHeader={fixedHeader}
      />
      {isOpen && (
        <AddUserForm
          cancel={() => setIsOpen(false)}
          title={t(AddNewEmployee)}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </div>
  );
};

export default ClientTable;
