import React, { Fragment, useCallback, useEffect } from "react";
import { Bell, AlertCircle, ShoppingBag } from "react-feather";
import { H6, LI, P, UL } from "../../AbstractElements";
import { notification } from "../../Data/Layout/Header/BellCompo";
import { Link } from "react-router-dom";
import useSocket from "../../Service/useSocket";
import { getNotifications } from "../../redux-toolkit/slices/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import { extractServicesWithExecutePermission } from "../../Service/extractServiceWithExecutePermission";

const BellCompo = () => {
  const dispatch = useDispatch();
  const notificationsData = useSelector(
    (state) => state.notification.notifications
  );
  const totalCount = useSelector((state) => state.notification.totalCount);

  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );

  const checkUserPermissions = (affectationSelected) => {
    if (!affectationSelected || !Array.isArray(affectationSelected.role)) {
      return "";
    }

    let hasReclamationPermission = false;
    let hasOrderPermission = false;

    for (const role of affectationSelected.role) {
      if (role.isRoot === true) {
        return "Order,Reclamation";
      }

      if (Array.isArray(role?.permissions)) {
        for (const permission of role.permissions) {
          if (permission?.name === "complaints" && permission?.read === true) {
            hasReclamationPermission = true;
          } else if (
            permission?.name === "orders" &&
            permission?.read === true
          ) {
            hasOrderPermission = true;
          }

          if (hasReclamationPermission && hasOrderPermission) {
            return "Order,Reclamation";
          }
        }
      }
    }

    if (hasReclamationPermission && hasOrderPermission) {
      return "Order,Reclamation";
    } else if (hasReclamationPermission) {
      return "Reclamation";
    } else if (hasOrderPermission) {
      return "Order";
    }

    return "";
  };

  const getIcon = (type) => {
    switch (type) {
      case "Reclamation":
        return <AlertCircle size={18} className="text-danger me-2" />;
      case "Order":
        return <ShoppingBag size={18} className="text-primary me-2" />;
      default:
        return <Bell size={18} className="text-secondary me-2" />;
    }
  };
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const getLinkPath = (type) => {
    switch (type) {
      case "Reclamation":
        return "/complaints";
      case "Order":
        return "/orders";
      default:
        return "#";
    }
  };

  const fetchNotifications = useCallback(async () => {
    const type = checkUserPermissions(affectationSelected);
    const services = extractServicesWithExecutePermission(
      affectationSelected?.role
    );

    if (services) {
      await dispatch(
        getNotifications({
          type: type,
          services: services,
          page: 1,
          limit: 10,
        })
      );
    } else {
      console.log("Services not available yet. Skipping notification fetch.");
    }
  }, [dispatch, affectationSelected]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  // const handleSocketEvent = useCallback(
  //   async (receivedData) => {
  //     const type = checkUserPermissions(affectationSelected);

  //     const services = extractServicesWithExecutePermission(
  //       affectationSelected?.role
  //     );

  //     if (services) {
  //       await dispatch(
  //         getNotifications({
  //           type: type,
  //           services: services,
  //           page: 1,
  //           limit: 10,
  //         })
  //       );
  //     } else {
  //       console.log("Services not available yet. Skipping notification fetch.");
  //     }
  //   },
  //   [dispatch, affectationSelected]
  // );

  const handleSocketEvent = useCallback(
    async (receivedData) => {
      // Fetch new notifications when socket event is received
      await fetchNotifications();
    },
    [fetchNotifications]
  );

  useSocket(
    `OFFICENT_NOTIFICATION_${affectationSelected?.establishments?._id}`,
    handleSocketEvent
  );
  return (
    <Fragment>
      <LI className="onhover-dropdown">
        <div className="bell-icon-wrapper position-relative d-inline-block">
          <Bell className="bell-icon" />
          {notificationsData.length > 0 && (
            <span className="notification-count position-absolute translate-middle badge rounded-pill bg-danger">
              {totalCount > 99 ? "99+" : totalCount}
            </span>
          )}
        </div>
        <UL className="notification-dropdown onhover-show-div simple-list">
          <LI>
            <H6 className="f-w-600">Notifications</H6>
            <span className="f-12">
              You have {notificationsData?.length} unread messages
            </span>
          </LI>
          <div style={{ maxHeight: "450px", overflowY: "auto", width: "100%" }}>
            {notificationsData?.map((item, index) => (
              <LI key={item._id} className="px-3 py-2 border-bottom">
                <Link
                  to={getLinkPath(item?.type)}
                  className="d-flex align-items-center text-decoration-none text-dark"
                >
                  {getIcon(item?.type)}
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="fw-bold">{item?.type}</span>
                      <small className="text-muted">
                        {formatDate(item?.createdAt)}
                      </small>
                    </div>
                    <p className="mb-0 text-muted">{item?.description}</p>
                    <small className="text-primary">
                      {item?.onModelId?.service?.name}
                    </small>
                    <p
                      className="mb-0 small text-truncate"
                      style={{ maxWidth: "200px" }}
                    >
                      {item?.onModelId?.description}
                    </p>
                  </div>
                </Link>
              </LI>
            ))}
          </div>
          <LI className="bg-light txt-dark">
            <a href="#javascript">All </a> notification
          </LI>
        </UL>
      </LI>
    </Fragment>
  );
};

export default BellCompo;
