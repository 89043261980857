import UsersPage from "../page/ClientPage";
import EmployeesPage from "../page/EmployeesPage";
import HomePage from "../page/HomePage";
import ServicePage from "../page/ServiceManagerPage/ServicePage";
import RoomsPage from "../page/RoomsPage";
import OrdersPage from "../page/ordersPage";
import CategoryPage from "../page/ServiceManagerPage/CategoryPage";
import CategoryForm from "../page/ServiceManagerPage/CategoryPage/CategoryForm";
import DepartmentPage from "../page/ServiceManagerPage/DepartmentPage";
import ServiceManagerPage from "../page/ServiceManagerPage/";
import SingleCategory from "../page/ServiceManagerPage/CategoryPage/category[id]";
import DepartementForm from "../page/ServiceManagerPage/DepartmentPage/DepartementForm";
import ServiceForm from "../page/ServiceManagerPage/ServicePage/serviceForm";
import SettingsPage from "../page/settingsPage";
import RoomsForm from "../page/RoomsPage/roomsForm";
// import Swiper from "../Components/Swiper/Swiper";
// import Swipper from "../Components/Swiper/Swiper";
import Profile from "../page/ProfilePage/Profile";
import ComplaintsPage from "../page/Complaints/index";
import Appointments from "../page/AppointmentsPage/Appointments";
import Service from "../page/NavigationService/Service";
import BookingCalendarPage from "../page/Booking";

const routes = [
  { path: "/dashboard", Component: <HomePage /> },
  { path: "/services-manager", Component: <ServiceManagerPage /> },
  { path: "/services-manager/services", Component: <ServicePage /> },
  { path: "/services-manager/services/new", Component: <ServiceForm /> },
  { path: "/services-manager/services/edit/:id", Component: <ServiceForm /> },
  { path: "/services-manager/category", Component: <CategoryPage /> },
  {
    path: "/services-manager/category/:categoryId",
    Component: <SingleCategory />,
  },
  {
    path: "/services-manager/category/:categoryId/subCategory/new",
    Component: <CategoryForm />,
  },
  {
    path: "/services-manager/category/:categoryId/subCategory/edit/:subCategoryId",
    Component: <CategoryForm />,
  },
  { path: "/services-manager/category/new", Component: <CategoryForm /> },
  // { path: "/services-manager/category/new/:id", Component: <CategoryForm /> },
  {
    path: "/services-manager/category/edit/:categoryId",
    Component: <CategoryForm />,
  },
  { path: "/services-manager/department", Component: <DepartmentPage /> },
  { path: "/services-manager/department/new", Component: <DepartementForm /> },
  {
    path: "/services-manager/department/:departmentId/edit",
    Component: <DepartementForm />,
  },
  { path: "/employees", Component: <EmployeesPage /> },
  { path: "/users", Component: <UsersPage /> },
  { path: "/employees/:id/edit", Component: <EmployeesPage /> },
  { path: "/rooms", Component: <RoomsPage /> },
  { path: "/rooms/new", Component: <RoomsForm /> },
  { path: "/rooms/edit/:id", Component: <RoomsForm /> },
  { path: "/orders", Component: <OrdersPage /> },
  { path: "/settings-page", Component: <SettingsPage /> },
  { path: "/user/user-profile", Component: <Profile /> },
  { path: "/booking", Component: <BookingCalendarPage /> },
  { path: "/appointments", Component: <Appointments /> },
  { path: "/service/:id", Component: <Service /> },
  { path: "/complaints", Component: <ComplaintsPage /> },
];
export default routes;
