import React from "react";

const CardOfRoom = ({ data, isSelected, onSelect }) => {
  console.log("🚀 ~ CardRoom ~ data:", data);
  return (
    <div
      style={{
        width: "250px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: isSelected ? "#e6f7ff" : "#fff",
        cursor: "pointer",
      }}
      onClick={onSelect}
    >
      <div style={{ position: "relative" }}>
        <img
          src={`${process.env.REACT_APP_MINIO_URL}${data?.images[0]}`}
          alt={`Room ${data.roomNumber}`}
          style={{ width: "100%", height: "150px", objectFit: "cover" }}
        />
        <input
          type="radio"
          checked={isSelected}
          onChange={onSelect}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            width: "20px",
            height: "20px",
          }}
        />
      </div>
      <div style={{ padding: "1rem" }}>
        <h4 style={{ marginBottom: "0.5rem" }}>Room {data.roomNumber}</h4>
        <p style={{ marginBottom: "0.5rem" }}>{data.description}</p>
        <p style={{ marginBottom: "0.5rem" }}>Type: {data.roomType}</p>
        <p>View: {data.roomView}</p>
        <p>Capacity: {data.roomCapacity.join(", ")} person(s)</p>
      </div>
    </div>
  );
};

export default CardOfRoom;
