  
  import { Server } from "react-feather";
  import MENUITEMS, { PRESTATION_MENUITEMS } from "../Layout/SideBar-Layout/Menu";
  
  function MenuItemm ({title, icon, url, type}) {
    return  {
      title: title,
      icon: <Server />,
      url: url,
      type: type || "link",
    }
  }
  
  export function getPermissions({roles, establishment}) {
    console.log("rolessss",roles)
    const Menu = establishment?.onModel === "Establishment" ? MENUITEMS : PRESTATION_MENUITEMS;

      // Exit early if isRoot is true for any role
    if (roles?.some(role => role.isRoot === true)) {
      return Menu;
    }

    let allMatchedMenuItems = [];
    let allDynamicItems = [];
    const menuItemss = roles?.flatMap((elem) => {
      
      const permissionsArray = elem.permissions?.map((permission) => {
        if (permission.read === true) return permission;
        else return null;
      }).filter(Boolean); // Filter out null values
       console.log("permissionsArray", permissionsArray)
      const matchedMenuItems = permissionsArray.map(permissionn => {
        return Menu.find(menuItem => menuItem.title.toUpperCase() === permissionn.name.toUpperCase());
      }).filter(Boolean); // Filter out undefined values
  
      const dynamicItems = permissionsArray.map(permissionn => {
        // Check if permissionn is not in matchedMenuItems
        if (!matchedMenuItems.some(item => item.title.toUpperCase() === permissionn.name.toUpperCase()) && permissionn?.service) {
          // Generate dynamic item using MenuItemm function
          return MenuItemm({ title: permissionn.name, icon: "", url: `/service/${permissionn.service}`, type: "link" });
        }
        return null; // Return null for items already in matchedMenuItems
      }).filter(Boolean); // Filter out null values
      allMatchedMenuItems = [...allMatchedMenuItems, ...matchedMenuItems];
      allDynamicItems = [...allDynamicItems, ...dynamicItems];
      return [...matchedMenuItems, ...dynamicItems];
    });
     
     // Use a Set to remove duplicates based on the title property
     const finalItemsWithoutRedundancy = [];
     const seenTitles = new Set();
     for (const item of [...allMatchedMenuItems, ...allDynamicItems]) {
       if (!seenTitles.has(item.title)) {
        finalItemsWithoutRedundancy.push(item);
         seenTitles.add(item.title);
       }
     }
     console.log("finally", finalItemsWithoutRedundancy)
    return finalItemsWithoutRedundancy || []; // Return an empty array if roles is undefined or empty
  }
  