import React, { useState, useEffect } from "react";
import TableComp from "../../../Components/dataTable/TableComp";
import { Eye, Edit } from "react-feather";
import { Btn } from "../../../AbstractElements";
import DepartmentView from "./DepartementView";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteDepartmentAsync,
  fetchDepartment,

  
} from "../../../redux-toolkit/slices/departementSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { usePermission } from "../../../Service/usePermission";

const DepartmentList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const departmentData = useSelector((state) => state.departement.departmentsData);
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  const [page, setPage] = useState(1); 
  const [perPage, setPerPage] = useState(10); 


  useEffect(() => {
    const fetchData = async () => {
      if (affectationSelectedData?.establishments._id) {
        const res = await dispatch(fetchDepartment(
          {
            establishmentId: affectationSelectedData?.establishments._id,
            page,
            perPage,
          }));
      }
    };
    fetchData();
  }, [dispatch, affectationSelectedData, page, perPage]);

  const categoryTableColumns = [
    {
      name: t("Name"),
      selector: (row) => row["name"],
      sortable: true,
      center: true,
    },
    {
      name: t("Services"),
      selector: (row) => row?.services?.length,
      sortable: true,
      center: true,
    },
    {
      name: t("Action"),
      selector: (row) => (
        <div>
          <a
            className="btn-xs"
            onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Eye width={20} />
          </a>
          <a
            className="btn-xs"
            onClick={() => handelNavigateToFormEdit(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  const handelOpenToView = (category) => {
    setSelectedCategory(category);
    setIsOpen(true);
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handelDeleteDepartment = async () => {
    try {
      const response = await dispatch(
        deleteDepartmentAsync(selectedRows?.[0]?._id)
      );
      if(deleteDepartmentAsync.rejected.match(response)) { 
        toast.error(response.payload); 
      }else{     
        if ( Math.ceil(departmentData?.totalData / perPage) < page )  // this is useful when deleting the last department in a page != 1
        setPage(page-1)
        toast.success(response?.payload?.message || "Department deleted successfully!");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the Department.");
    }
  };
  
  const handelNavigateToForm = () => {
    navigate("/services-manager/department/new");
  };

  const handelNavigateToFormEdit = (row) => {
    navigate(`/services-manager/department/${row?._id}/edit`);
  };

  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-end bg-light-info p-2">
        <Btn
          color="danger mb-2 m-r-15"
          onClick={handelDeleteDepartment}
          disabled={
            (usePermission('static', "service manager", '!delete')) || !selectedRows.some((e) =>
              departmentData.data.some((item) => item._id === e._id)
            )
          }
        >
          {t("Delete")}
        </Btn>
        <Btn
          color="btn btn-primary mb-2"
          onClick={() => handelNavigateToForm()}
          disabled={usePermission('static', "service manager", '!execute')} 
        >
          {t("Add new department")}
        </Btn>
      </div>
      <TableComp
        data={departmentData.data}
        columns={categoryTableColumns}
        handleRowSelected={handleRowSelected}
        toggleDelete={false}
        expandableRows={false}
        highlightOnHover={true}
        fixedHeader={true}
        customStyles={customStyles}
        onChangePage={setPage}
        onChangeRowsPerPage={(newPerPage, newPage) => {
          setPerPage(newPerPage);
          setPage(newPage);
        }}
        paginationTotalRows={departmentData.totalData}
        pagination
        selectableRows={true}
        selectableRowsSingle={true}
      />
      <DepartmentView
        isOpen={isOpen}
        toggleClose={() => setIsOpen(false)}
        data={selectedCategory}
      />
    </div>
  );
};

export default DepartmentList;
