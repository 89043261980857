import axiosInstance from "../../api/axios";

const GET_ALL_ROLE_BY_ESTABLISHMENT_ID = `${process.env.REACT_APP_BACK_URL}/api/v1/role/getAllRolesByEstablishmentId`;
const ADD_ROLE = `${process.env.REACT_APP_BACK_URL}/api/v1/role/createRole`;
const UPDATE_ROLE = `${process.env.REACT_APP_BACK_URL}/api/v1/role/updateRole`;

const DELETE_ROLE = `${process.env.REACT_APP_BACK_URL}/api/v1/role/deleteRole`;

export const getAllRole = async (establishementId, page, limit) => {
  try {
    const response = await axiosInstance.get(
      `${GET_ALL_ROLE_BY_ESTABLISHMENT_ID}?establishmentId=${establishementId}&limit=${limit}&page=${page}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const addRole = async (data) => {
  try {
    const response = await axiosInstance.post(ADD_ROLE, data);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateRole = async (id, data) => {
  console.log("id", id);
  console.log("data", data);
  try {
    const response = await axiosInstance.put(`${UPDATE_ROLE}/${id}`, data);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteRole = async (id) => {
  try {
    const response = await axiosInstance.post(`${DELETE_ROLE}/${id}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};
