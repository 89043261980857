import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import { useTranslation } from "react-i18next";
import TableComp from "../../Components/dataTable/TableComp";
import { Btn } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchComplaints,
  updateComplaintsStatus,
} from "../../redux-toolkit/slices/complaintsSlice";
import { Edit, Eye } from "react-feather";
import { Image } from "react-bootstrap";
import { dynamicImage } from "../../Service";
import ComplaintDetails from "../../Components/Complaints/ComplaintDetails";
import { toast } from "react-toastify";
import { usePermission } from "../../Service/usePermission";
import { extractServicesWithExecutePermission } from "../../Service/extractServiceWithExecutePermission";
import Calendar from "../../Components/Booking/Calendar";
import CalenderContainer from "../../Components/Booking/calender";

const BookingCalendarPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const rooms = [
    { id: 1, name: 'Standard Room' },
    { id: 2, name: 'Double Room' },
    { id: 3, name: 'Luxury Room' },
  ];
  
  const bookings = {
    1: [1, 2, 3, 15, 16], // Booked dates for room 1
    2: [5, 6, 7],         // Booked dates for room 2
    3: [8, 9, 10],        // Booked dates for room 3
  };






  return (
    <div className="page-body">
      {/* <Breadcrumbs />
      <div>
        <div className="d-flex align-items-center justify-content-end bg-light-info p-2">
          <Btn
            color=" btn btn-success mb-2 m-r-15"
            onClick={() => handleResolveComplaint()}
            disabled={
              !selectedRows.some((e) =>
                complaintData.data.some((item) => item._id === e._id)
              ) || !canExecute
            }
          >
            {t("Resolve")}
          </Btn>
        </div>
        <TableComp
          data={complaintData?.data}
          columns={complaintsTableColumns}
          handleRowSelected={handleRowSelected}
          selectableRowsSingle={true}
          toggleDelete={false}
          expandableRows={false}
          highlightOnHover={true}
          fixedHeader={true}
          customStyles={customStyles}
          onChangePage={setPage}
          onChangeRowsPerPage={(newPerPage, newPage) => {
            setPerPage(newPerPage);
            setPage(newPage);
          }}
          paginationTotalRows={complaintData.totalData}
          pagination
          selectableRows={true}
        />
      </div>
      <div>
        <ComplaintDetails
          open={open}
          setOpen={setOpen}
          sectedComplaint={sectedComplaint}
        />
      </div> */}
      {/* <Calendar rooms={rooms} bookings={bookings}/> */}
      <CalenderContainer/>
    </div>
  );
};

export default BookingCalendarPage;
