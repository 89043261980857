import axios from "axios";
import axiosInstance from "../../api/axios";

const GET_AFFECTATION_BY_USER_ID = (userId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/affectationUser/getAffectationsByUserId/${userId}`;
const GET_ROOMS_BY_ESTABLISHMENT_ID = (establishmentId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/rooms/getRoomsByEstablishmentId/${establishmentId}`;
const CREATE_AFFECTATION_USER = `${process.env.REACT_APP_BACK_URL}/api/v1/affectationUser/createAffectationUser`;
const UPDATE_AFFECTATION_USER = `${process.env.REACT_APP_BACK_URL}/api/v1/affectationUser/updateUserAffectationById`;
// const GET_AFFECTATIONS_BY_ESTABLISHMENT_ID = (establishmentId) =>
//   `${process.env.REACT_APP_BACK_URL}/api/v1/affectationUser/${establishmentId}`;
const GET_ROOMS_FOR_BOOKING = (establishmentId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/rooms/getAllRoomsForBooking/${establishmentId}`;
export const createAffectationUser = async (
  userId,
  establishmentId,
  expiredDate,
  rooms,
  status
) => {
  try {
    const response = await axiosInstance.post(CREATE_AFFECTATION_USER, {
      userId,
      status,
      establishmentId,
      expiredDate,
      rooms,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateAffectationUser = async (id, affectationData) => {
  try {
    const response = await axiosInstance.post(
      `${UPDATE_AFFECTATION_USER}/${id}`,
      affectationData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAffectationByUserId = async (userId) => {
  try {
    const response = await axiosInstance.get(
      GET_AFFECTATION_BY_USER_ID(userId)
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};
export const getRoomsByEstablishmentId = async (
  establishmentId,
  page,
  perPage
) => {
  try {
    const response = await axiosInstance.get(
      GET_ROOMS_BY_ESTABLISHMENT_ID(establishmentId) +
        `?page=${page}&perPage=${perPage}`
    );
    console.log("abbb", response);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getRoomsBooking = async (
  establishmentId
  
) => {
  try {
    const response = await axiosInstance.get(
      GET_ROOMS_FOR_BOOKING(establishmentId)
    );
    console.log("abbb", response);
    return response;
  } catch (error) {
    console.error(error);
  }
};

// export const getAffectationByEstablishmentId = async (establishmentId) => {
//   try {
//     const response = await axiosInstance.get(GET_AFFECTATIONS_BY_ESTABLISHMENT_ID(establishmentId));
//     console.log(
//       "🚀 ~ file: affectationService.js:10 ~ getAffectationByEstablishmentId ~ response:",
//       response
//     );
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };
