import { ChevronRight, LogIn, User } from "react-feather";
import { Image, LI, UL } from "../../AbstractElements";
import { ProfilesMessage } from "../../Data/Layout/Header/Profiles";
import { dynamicImage } from "../../Service";
import { Admin, ElanaSaint, Logout, Profile } from "../../Constant";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import axiosInstance from "../../api/axios";
import {
  getAdminAuthAsync,
  logoutAuthAsync,
} from "../../redux-toolkit/slices/authSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";

const UserProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  console.log(
    "🚀 ~ file: UserProfile.jsx:20 ~ UserProfile ~ userAdmin:",
    userAdmin
  );
  const adminId = localStorage.getItem("admin");
  useEffect(() => {
    if (!userAdmin) {
      dispatch(getAdminAuthAsync(adminId));
    }
  }, []);

  const handleDispatchLogout = async () => {
    try {
      // await axiosInstance.post(`${LogoutApi}`).then((resp) => {

      //   navigate(`/login`);
      // });
      const logout = await dispatch(logoutAuthAsync());
      if (logoutAuthAsync.rejected.match(logout)) {
        toast.error("Error");
      } else {
        toast.success("Logout Success...!");
        navigate("/login");
      }
      console.log("dispatch done");
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <LI className="onhover-dropdown px-0">
      <span className="d-flex user-header">
        <Image
          className="me-2 rounded-circle img-35"
          src={dynamicImage("dashboard/user.png")}
          alt="userdp"
        />
        <span className="flex-grow-1">
          <span className="f-12 f-w-600">
            {userAdmin?.firstName} {userAdmin?.lastName}
          </span>
          <span className="d-block">{userAdmin?.role?.name}</span>
        </span>
      </span>
      <UL className="profile-dropdown  onhover-show-div simple-list">
        <LI>
          <Link to={`${process.env.PUBLIC_URL}/user/user-profile`}>
            <User />
            {Profile}
          </Link>
        </LI>
        <LI className="f-w-600">Home</LI>
        {ProfilesMessage.map((detail, index) => (
          <LI key={index} className="f-12">
            <Link to={`${process.env.PUBLIC_URL}/email/email-app`}>
              <ChevronRight />
              {detail.name}
            </Link>
          </LI>
        ))}
        <LI onClick={handleDispatchLogout}>
          <LogIn />
          {Logout}
        </LI>
      </UL>
    </LI>
  );
};

export default UserProfile;
