import { configureStore } from "@reduxjs/toolkit";
import { BookMarkReducer } from "./reducers/BookMarkReducer";
import { ThemeCustomizer } from "./reducers/ThemeCustomizer";
import { serviceReducer } from "./reducers/serviceReducer";
import { refreshTokenReducer } from "./reducers/refreshTokenReducer";
import { employeeReducer } from "./slices/employeeSlice";
import { roleReducer } from "./slices/roleSlice";
import { servicesReducer } from "./slices/serviceSlice";
import { clientsReducer } from "./slices/clientSlice";
import { usersReducer } from "./slices/userSlice";
import { affectationsReducer } from "./slices/affectationSlice";
import { roomsReducer } from "./slices/roomsSlice";
import { ordersReducer } from "./slices/ordersSlice";
import { categoryReducer } from "./slices/categorySlice";
import { departmentReducer } from "./slices/departementSlice";
import { productsReducer } from "./slices/productSlice";
import { permissionReducer } from "./slices/permissionSlice";
import { etablishementReducer } from "./slices/etablishementSlice";
import { authReducer } from "./slices/authSlice";
import { complaintReducer } from "./slices/complaintsSlice";
import { appointmentsReducer } from "./slices/appointmentSlice";
import { notificationReducer } from "./slices/notificationSlice";
import { mealsReducer } from "./slices/mealsSlice";
import { bookingsReducer } from "./slices/bookingSlice";

export const store = configureStore({
  reducer: {
    BookMarkReducer,
    ThemeCustomizer,
    auth: authReducer,
    serviceReducer,
    refreshTokenReducer,
    employee: employeeReducer,
    services: servicesReducer,
    role: roleReducer,
    clients: clientsReducer,
    users: usersReducer,
    affectations: affectationsReducer,
    rooms: roomsReducer,
    orders: ordersReducer,
    category: categoryReducer,
    departement: departmentReducer,
    products: productsReducer,
    permission: permissionReducer,
    establishment: etablishementReducer,
    complaints: complaintReducer,
    appointments: appointmentsReducer,
    notification: notificationReducer,
    meals: mealsReducer,
    bookings:bookingsReducer
  },
});
