import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { Col, Row, Label } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../Inputs/simpleInput";
import { useDispatch, useSelector } from "react-redux";
import { fetchRole } from "../../redux-toolkit/slices/roleSlice";
import {
  createAffectationAdminAsync,
  createEmployeeAsync,
  updateAffectationAdminn,
} from "../../redux-toolkit/slices/employeeSlice";
import Select from "react-select";
import * as yup from "yup";
import { AddNewEmployee } from "../../Constant";
import { useTranslation } from "react-i18next";
import { Btn } from "../../AbstractElements";
import { toast } from "react-toastify";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { usePermission } from "../../Service/usePermission";

const NewEmployee = (props) => {

  /*********************** Start hooks *******************/
    //useState
    const [roles, setRoles] = useState();

    //Start useSelector
    const rolesData = useSelector((state) => state.role.roles);
    const affectationSelectedData = useSelector(
      (state) => state.auth.affectationSelected
    );
    //End useSelector

    //useDispatch
    const dispatch = useDispatch();

    //useNavigation
    const navigate = useNavigate();

    //useLocation
    let location = useLocation();

    //useTranslation
    const { t } = useTranslation();

    //useParams
    const routeParams = useParams();

    //Start useEffect
    useEffect(() => {
      const fetchData = async () => {
        if (affectationSelectedData) {
          try {
            await dispatch(
              fetchRole({
                establishementId: affectationSelectedData?.establishments._id,
                page: 1,
                limit: 800,
              })
            );
          } catch (error) {
            console.error('Failed to fetch role:', error);
          }
        }
      };
    
      fetchData();
    }, [dispatch, affectationSelectedData]);

    useEffect(() => {
      if (rolesData) setRoles(rolesData?.data);
    }, [rolesData?.data]);

    useEffect(() => {
      //reset the form with selected employee information from the location's state 
        if (routeParams.id) {
          const employeeWithRoles = location.state;        
            reset({
              firstName: employeeWithRoles?.admin?.firstName,
              lastName: employeeWithRoles?.admin?.lastName,
              email: employeeWithRoles?.admin?.email,
              phone: employeeWithRoles?.admin?.phone,
              username: employeeWithRoles?.admin?.username,
              role: employeeWithRoles?.role?.map((r) => ({
                label: r.name,
                value: r._id,
              })),
            });
        } else {
          reset(dirtyFields);
        }
    }, [routeParams, props.isOpen]);
    //End useEffect

    const canExecute = usePermission('static', "employees", 'execute');
    const canUpdate = usePermission('static', "employees", 'update');
  /*********************** End hooks *******************/


  /*********************** Start Constants and variables *******************/
  const passwordSchema = yup.string()
  .required('You must enter a password')
  .matches(
    /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{3,}$/,
    'Password must contain at least one uppercase letter, one number, one special character and be at least 3 characters long'
  );

  const repeatPasswordSchema = yup.string()
  .oneOf([yup.ref('password'), null], 'Passwords must match')
  .required('You must repeat the password');

  const schema = yup.object().shape({
    firstName: yup.string().required("You must enter a first name"),
    lastName: yup.string().required("You must enter a last name"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("You must enter an email"),
    phone: yup.string().required("You must enter a phone number"),
    username: yup.string().required("You must enter a username"),
    role: yup.array().min(1, "You must select at least one role").required("You must select at least one role"),
    password: !routeParams.id ? passwordSchema : yup.string(),
    repeatPassword: !routeParams.id ? repeatPasswordSchema : yup.string(),
    
  });
  const pathname = location.pathname.split("/");
  const titlePopup = pathname.includes("edit")?"Update Employee":AddNewEmployee

  //useForm hook
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, dirtyFields, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  /*********************** End Constants and variables *******************/

 
  /*********************** Start functions and handlers *******************/
  async function onSubmit(data) {
    const { id, __typename } = data;
  if (!routeParams.id) {
      const transformedData = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phone: data?.phone,
        username: data?.username,
        password: data?.password,
        status: "active",
      };
     try {
        
        const employeeCreated = await dispatch(
          createEmployeeAsync(transformedData)
        );
        if (createEmployeeAsync.rejected.match(employeeCreated)) {
          toast.error(employeeCreated.payload || "Error while creating admin!");
        } else {
            const dataAffectation=data.role?.map((e) => e.value)
            const affectationAdminCreated = await dispatch(
             createAffectationAdminAsync({
                adminId: employeeCreated?.payload?.data?._id,
                establishmentId: affectationSelectedData?.establishments._id,
                roleIds: dataAffectation, 
               })
            );
            
             if (createAffectationAdminAsync.rejected.match(affectationAdminCreated)) {
               toast.error(affectationAdminCreated.payload || "Error while creating affectation admin!");
               
             } else {
               toast.success(affectationAdminCreated.payload.message || "affectaion admin created successfully!");
               reset();
               props.setIsOpen(false);
               navigate(`/employees`);
             }
          }
   
     } catch (error) {
        toast.error("Error creating employee !");
     }
  }
    else {
      try {
      const adminData = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phone: data?.phone,
        username: data?.username,
        status: "active",
      };

      const rolesIds = data?.role.map((role)=> role.value);
      const updatedAffectationAdminData = {adminId:location?.state?.admin._id,establishmentId:affectationSelectedData?.establishments._id,roleIds:rolesIds,adminData:adminData}
      const employeeUpdated = await dispatch(
          updateAffectationAdminn({affectationId:routeParams.id, updatedAffectationAdminData: {...updatedAffectationAdminData, onModel:"Etablissement"}} )
        );
      if (updateAffectationAdminn.rejected.match(employeeUpdated)) {
        toast.error(employeeUpdated.payload || "Error while updating affectation-admin!");
        
      } else {
        toast.success(employeeUpdated.payload.message || "affectaion admin updated-successfully!");
        props.setIsOpen(false);
        navigate(`/employees`);
      }
    }
    catch (error) {
      toast.error("An unexpected error occurred while updating affectation-admin.");
    }

    }
  }
  /*********************** End functions and handlers *******************/

  return (
    <Modal isOpen={props.isOpen} size="lg" centered={true}>
      {/* <ModalHeader>{t(AddNewEmployee)}</ModalHeader> */}
      <ModalHeader>{t(titlePopup)}</ModalHeader>
      <ModalBody>
        <Col xl="12">
          <Row>
            <Col lg="6" className="m-b-15">
              <Label>{t("First Name")}</Label>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <SimpleInput
                    {...field}
                    errClassName="error-msg-input"
                    className="form-control"
                    placeholder={t("First name")}
                    errors={errors.firstName?.message}
                  />
                )}
              />
            </Col>
            <Col lg="6" className="m-b-15">
              <Label>{t("Last Name")}</Label>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <SimpleInput
                    {...field}
                    errClassName="error-msg-input"
                    className="form-control"
                    placeholder={t("Last name")}
                    errors={errors.lastName?.message}
                  />
                )}
              />
            </Col>
            <Col lg="12" className="m-b-15">
              <Label>{t("Email")}</Label>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("Email address")}
                    {...field}
                  />
                )}
              />
              <span className="error-msg-input">{errors.email?.message}</span>
            </Col>
            <Col lg="6" className="m-b-15">
              <Label>{t("Phone number")}</Label>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <input
                    className="form-control"
                    type="text"
                    placeholder={t("Phone number")}
                    {...field}
                  />
                )}
              />
              <span className="error-msg-input">{errors.phone?.message}</span>
            </Col>
            <Col lg="6" className="m-b-15">
              <Label>{t("Username")}</Label>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <SimpleInput
                    {...field}
                    errClassName="error-msg-input"
                    className="form-control"
                    placeholder={t("Custom username")}
                    errors={errors.username?.message}
                  />
                )}
              />
            </Col>
          </Row>
          <Col sm="12">
            <Row>
              {!routeParams.id && (
                <>
                  <Col lg="12" className="m-b-15">
                    <Label>{t("Password")}</Label>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="password"
                          placeholder={t("Password")}
                          {...field}
                        />
                      )}
                    />
                    <span className="error-msg-input">
                      {errors.password?.message}
                    </span>
                  </Col>
                  <Col lg="12" className="m-b-15">
                    <Label>{t("Repeat password")}</Label>
                    <Controller
                      name="repeatPassword"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="password"
                          placeholder={t("Repeat password")}
                          {...field}
                        />
                      )}
                    />
                    <span className="error-msg-input">
                      {errors.repeatPassword?.message}
                    </span>
                  </Col>
                </>
              )}
              <Col lg="12" className="m-b-15">
                <Label>{t("Roles")}</Label>
                <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <Select
                        options={roles?.map((e) => ({
                          label: e.name,
                          value: e._id,
                        }))}
                        isMulti
                        {...field}
                      />
                    )}
                 />
                 <span className="error-msg-input">{errors.role?.message}</span>
              </Col>
            </Row>
          </Col>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Btn
          color="primary"
          onClick={() => {
            props.setIsOpen(false);
            reset();
            navigate(`/employees`);
          }}
        >
          {t("Close")}
        </Btn>
        <Button onClick={handleSubmit(onSubmit)} color="secondary" 
          disabled={
             (pathname.includes("edit") && !canUpdate) ||
                (!pathname.includes("edit") && !canExecute)
           }
        >
          {t("Save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NewEmployee;
