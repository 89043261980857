import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEmployeeWithRoles,
} from "../../redux-toolkit/slices/employeeSlice";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import Table from "../../Components/dataTable/Table";
import { useNavigate } from "react-router-dom";
import { Btn } from "../../AbstractElements";
import { Edit } from "react-feather";
import { useTranslation } from "react-i18next";

const ExpandedComponent = ({ data }) => {
  return <div>{data?.email}</div>;
};
const EmployeesPage = () => {

  /*************Start Hooks ***************/
    //Start useState
    const [page, setPage] = useState(1); // Add state for current page
    const [perPage, setPerPage] = useState(11); // Add state for items per page  (perPage = 10 + 1 because I will not show the current admin)
    const [isOpen, setIsOpen] = useState(false);
    //End useState

    //Start useSelector
    const adminAffectaions = useSelector((state) => state.employee.adminAffectaionsData);
    const navigate = useNavigate();
    const affectationSelectedData = useSelector(
      (state) => state.auth.affectationSelected
    );
    //End useSelector

    //useDispatch
    const dispatch = useDispatch();
    
    //useTranslation
    const { t } = useTranslation();

    //useEffect
    useEffect(() => {
      const fetchData = async () => {
        if (affectationSelectedData) {
          const res = await dispatch(
            fetchEmployeeWithRoles({
              establishmentId: affectationSelectedData?.establishments?._id,
              page,
              perPage,
            })
          );
          // setTotalEmployees(res.payload.totalData); // Update the total number of employees
        }
      };
    
      fetchData();
    }, [dispatch, affectationSelectedData, page, perPage]);

  /*************End Hooks ***************/

  /************* Start Constants and variables ***************/
  const supportColumns = [
    {
      name: t("First Name"),
      selector: (row) => row.admin?.firstName || "N/A",
      sortable: true,
      center: true,
    },
    {
      name: t("Last Name"),
      selector: (row) => row.admin?.lastName || "N/A",
      sortable: true,
      center: true,
    },
    {
      name: t("Phone"),
      selector: (row) => row.admin?.phone || "N/A",
      sortable: true,
      center: true,
    },
    {
      name: t("Email"),
      selector: (row) => row.admin?.email || "N/A",
      sortable: true,
      center: true,
    },
    {
      name: t("Role"),
      selector: (row) =>
        row.role?.map((role) => role.name).join(", ") || "N/A",
      sortable: true,
      center: true,
      cell: (row) => (
        <div className="d-flex flex-column">
          {row.role && row.role.length > 0
            ? row.role.map((role, index) => (
                <Btn key={index} className="btn-pill btn-xs px-2  my-1">
                  {role.name}
                </Btn>
              ))
            : "N/A"}
        </div>
      ),
    },
    {
      name: t("Action"),
      selector: (row) => (
        <div>
          <a className="btn-xs" onClick={() => handelOpenToEdit(row)}>
            <Edit width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];
  /************* End Constants and variables ***************/

  /************* Start functions and handlers ***************/
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerRowsChange = async (newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  const handelOpenToEdit = (row) => {
    console.log("rowwwwwwwwww", row?.admin?._id);
    navigate(`/employees/${row?._id}/edit`, { state: row });
    setIsOpen(true);
  };

  /************* End functions and handlers ***************/

  return (
    <div className="page-body">
      <Breadcrumbs />
      <div>
        <Table
          columns={supportColumns}
          data={adminAffectaions.data ? adminAffectaions.data.filter((elem) => elem._id !== affectationSelectedData?._id) : []}
          expandableRowsComponent={ExpandedComponent}
          expandOnRowClicked={true}
          highlightOnHover={true}
          fixedHeader={true}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          onChangePage={handlePageChange} // Add page change handler
          onChangeRowsPerPage={handlePerRowsChange} // Add per page change handler
          pagination // Enable pagination
          paginationServer // Indicate that pagination is server-side
          paginationTotalRows={adminAffectaions.totalData-1} // Total rows for pagination (Minus 1 because I will not show the current Admin)
       />
      </div>
    </div>
  );
};

export default EmployeesPage;
