import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Col, Label, Row, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Btn } from '../../AbstractElements';
import UploadDropzone from '../Dropzone/UploadDropzone';
import { toast } from "react-toastify";
import { scanIdentityAsync } from '../../redux-toolkit/slices/userSlice';

const Scan = ({setAddAffectation, setAddNewClient, setScan, setScanData}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [scanDisabled, setScanDisabled] = useState(true)
    const schema = yup.object().shape({
    });
    const isLoading = useSelector((state) => state.users.loadingScan);
    const {
      control,
      handleSubmit,
      reset,
      resetField,
      setValue,
      getValues,
      watch,
      formState: { isValid, dirtyFields, errors },
    } = useForm({
      mode: "onChange",
      resolver: yupResolver(schema),
    });
  
    const getUploadParams = (body) => {
        return { url: "https://httpbin.org/post" };
      };
    
      const handleSubmitFile = ({ meta, file, remove }, status) => {
        setValue("images", [file]);
        if (status === "headers_received") {
          toast.success(`${meta.name} uploaded!`);
          setScanDisabled(false)
        } else if (status === "aborted") {
          setScanDisabled(true)
          toast(`${meta.name}, upload failed...`);
        } else if (status === "removed") {
          resetField("images");
        }
      };
  
    async function onSubmit(data) {
        if (data.images && data.images.length) {
            const formData = new FormData();
            data.images.forEach((file) => {
              formData.append("images", file);
            });
            try {
              const identityScanned = await dispatch(
                  scanIdentityAsync({
                      scanData: formData,
                  })
              );
              console.log("identityScanned", identityScanned);
              
              if (scanIdentityAsync.rejected.match(identityScanned)) {
                  toast.error(identityScanned?.payload || "Error while scanning the identity");
              } else {
                  toast.success(identityScanned?.payload?.message || "Identity was scanned successfully!");
              }
  
              setScanData({
                  firstName: identityScanned?.payload?.data?.['First Name'],
                  lastName: identityScanned?.payload?.data?.['Last Name'],
                  uniqueId: identityScanned?.payload?.data?.['Identity N°'] || identityScanned?.payload?.data?.['Passeport N°'],
                  Nationality: identityScanned?.payload?.data?.['Nationality'],
                  dateOfBirth: identityScanned?.payload?.data?.['Date of birth'],
              });
              setAddAffectation(false);
              setAddNewClient(true);
              setScan(false);
          } catch (error) {
              toast.error("An error occurred while processing your request. Please try again.");
              console.error("Error in onSubmit:", error);
          }
        }
        else {
            toast.warn("please upload a file first!")
        }
    }
  
    return (
      <>
        <Col xl="12">
          <Row>
            <Col xl="12" className="p-3">
               <Label className="fw-bold">{t("Upload a file")}:</Label>
               <Controller
                 name="images"
                 control={control}
                 defaultValue={[]}
                 render={({ field }) => (
                   <UploadDropzone
                     getUploadParams={getUploadParams}
                     handleChangeStatus={(file, status) => handleSubmitFile(file, status)}
                     {...field}
                   />
                 )}
               />
             </Col>
           
           
          </Row>
          <Row className="pt-3">
         
            <Col lg="12" md="6" className="m-b-15 d-flex justify-content-end">
              <Btn color="success" onClick={handleSubmit(onSubmit)} disabled={scanDisabled || isLoading}>
              {isLoading ? <Spinner size="sm" /> : "Scan"}
              </Btn>
            </Col>
          </Row>
        </Col>
      </>
    );
}

export default Scan