import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addPermissions,
  updatepermissions,
} from "../services/permissionService";

const initialStatePermissions = {
  permissions: [],
  loading: false,
  loadingAddPermissions: false,
  error: null,
};

export const addNewPermissions = createAsyncThunk(
  "permission/addNewPermissions",
  async (data) => {
    console.log("data disapatched", data);
    try {
      const response = await addPermissions(data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updatePermissionsData = createAsyncThunk(
  "permission/update",
  async (data) => {
    console.log("data disapatched", data);
    try {
      const response = await updatepermissions(data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const permissionSlice = createSlice({
  name: "permission",
  initialState: initialStatePermissions,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addNewPermissions.fulfilled, (state, action) => {
      state.loadingAddPermissions = false;
      state.error = null;
    });
    builder.addCase(addNewPermissions.pending, (state, action) => {
      state.loadingAddPermissions = true;
      state.error = null;
    });
    builder.addCase(addNewPermissions.rejected, (state, action) => {
      state.loadingAddPermissions = false;
      state.error = action.error;
    });
    builder.addCase(updatePermissionsData.fulfilled, (state, action) => {
      state.loadingAddPermissions = false;
      state.error = null;
    });
    builder.addCase(updatePermissionsData.pending, (state, action) => {
      state.loadingAddPermissions = true;
      state.error = null;
    });
    builder.addCase(updatePermissionsData.rejected, (state, action) => {
      state.loadingAddPermissions = false;
      state.error = action.error;
    });
  },
});

export const permissionReducer = permissionSlice.reducer;
const { actions } = permissionSlice;
