import React from "react";

const SimpleInput = React.forwardRef((props, ref) => {
  return (
    <>
      <input
        ref={ref}
        id={props.id}
        name={props.name}
        className={props.className}
        type="text"
        placeholder={props.placeholder}
        {...props}
      />
      <div className="w-100"> {/* to display the error on a new line */}
        <span className={props.errclassname}>{props.errors}</span>
      </div>
    </>
  );
});

export default SimpleInput;
