import { useState } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import UpdateForm from "../../Components/Profile/updateForm";
import UpdatePasswordFom from "../../Components/Profile/updatePasswordFom";


const Profile = () => {
  
  return (
    <div className="page-body">
      <Breadcrumbs />
      <UpdateForm /> {/*this form is for updating admin info except password */}
      <UpdatePasswordFom />  {/*this form is for updating admin password */}
    </div>
  );
};

export default Profile;
