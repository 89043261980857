import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Btn } from "../../AbstractElements";
import SweetAlert from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteRooms, fetchRoomsAsync } from "../../redux-toolkit/slices/roomsSlice";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { usePermission } from "../../Service/usePermission";

const DataTableRooms = ({
  columns,
  data,
  expandableRowsComponent,
  expandOnRowClicked,
  highlightOnHover,
  fixedHeader,
  pagination,
  paginationServer,
  paginationTotalRows,
  onChangePage,
  onChangeRowsPerPage,
  page,
  perPage,
  selectableRowsSingle,
  setPage,
}) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelete, setToggleDelete] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roomsData = useSelector((state) => state.rooms.roomsData);
   // Call usePermission to get necessary permissions we called outSide to avoid condionally call of hooks
  const canExecute = usePermission('static', "room", 'execute');
  const canDelete = usePermission('static', "room", 'delete');

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleOpenModal = () => {
    navigate(`/rooms/new`);
  };

  const handleDelete = async () => {
    try {
      const roomsIds = selectedRows.map((row) => row._id);
      console.log("rooomsIddd", roomsIds)
      SweetAlert.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      }).then(async (result) => {
      
        if (result.value) {
          try {
             const response =  await dispatch(deleteRooms(roomsIds[0])); 
             console.log("response.action.payload",response)
             if(deleteRooms.rejected.match(response)){
              SweetAlert.fire(
                "conf",
                response.payload,
                "error"
              );
            
            }else{
              SweetAlert.fire(
                "Room",
                response?.payload?.data?.data?.message,
                "success"
              );
              if ( Math.ceil(roomsData?.data?.totalData / perPage) < page )  // this is useful when deleting the last room in a page != 1
              setPage(page-1)
            }
          } catch (error) {
            SweetAlert.fire("Error!", "Rooms could not be deleted.", "error");
            console.error("Error deleting rooms:", error);
          }
        }
      });
    } catch (error) {
      console.error("Error in deletion process:", error);
    }
  };

  return (
    <div className="table-responsive support-table">
      <div className="d-flex align-items-center justify-content-end bg-light-info p-2">
        <Btn
          color="danger mb-0 m-r-15"
          onClick={handleDelete}
          disabled={selectedRows.length === 0 || (!canDelete)}
        >
          {t("Delete")}
        </Btn>
        <Button color="secondary" onClick={handleOpenModal} disabled={!canExecute}>
          {t("Add Room")}
        </Button>
      </div>
      <DataTable
        columns={columns}
        data={data}
        striped={true}
        pagination={pagination}
        paginationServer={paginationServer}
        paginationTotalRows={paginationTotalRows}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleDelete}
        // expandableRows
        expandableRowsComponent={expandableRowsComponent}
        expandOnRowClicked={expandOnRowClicked}
        highlightOnHover={highlightOnHover}
        fixedHeader={fixedHeader}
        selectableRowsSingle
      />
    </div>
  );
};

export default DataTableRooms;
