import React from "react";

const BookingSummary = ({ bookingData }) => {
  const tableHeaderStyle = {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
    textAlign: "left",
  };

  const tableDataStyle = {
    border: "1px solid #ddd",
    padding: "8px",
  };
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Booking Summary</h2>
      <p>
        <strong>Arrival Date:</strong> {bookingData.dateArrive}
      </p>
      <p>
        <strong>Departure Date:</strong> {bookingData.dateDepart}
      </p>
      <p>
        <strong>Number of Days:</strong> {bookingData.numberOfDays}
      </p>

      <h3>Room Details</h3>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>Room Number</th>
            <th style={tableHeaderStyle}>Room Type</th>
            <th style={tableHeaderStyle}>Adults</th>
            <th style={tableHeaderStyle}>Children</th>
            <th style={tableHeaderStyle}>Room Total Price</th>
            <th style={tableHeaderStyle}>Meal Plan</th>
            <th style={tableHeaderStyle}>Daily Prices</th>
          </tr>
        </thead>
        <tbody>
          {bookingData.bookings.map((booking, index) => (
            <tr key={booking.roomId}>
              <td style={tableDataStyle}>{booking.roomNumber}</td>
              <td style={tableDataStyle}>{booking.roomType}</td>
              <td style={tableDataStyle}>{booking?.nbAdulte}</td>
              <td style={tableDataStyle}>{booking.nbEnfant}</td>
              <td style={tableDataStyle}>${booking.totalRoomPrice}</td>
              <td style={tableDataStyle}>
                {booking.mealInfo.name} (${booking.mealInfo.totalPrice})
              </td>
              <td style={tableDataStyle}>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {booking.dailyPrices.map((priceDetail) => (
                    <li key={priceDetail.date}>
                      {priceDetail.date}: ${priceDetail.price}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3>Grand Total: ${bookingData.grandTotal}</h3>
    </div>
  );
};

export default BookingSummary;
