import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getComplaints,
  updateComplaintStatus,
} from "../services/copmlaintsService";
const initialState = {
  complaintsData: {},
  loading: false,
  error: null,
};

export const fetchComplaints = createAsyncThunk(
  "complaints/fetchData",
  async ({ establishmentId, page, perPage, services }) => {
    try {
      const response = await getComplaints(
        establishmentId,
        page,
        perPage,
        services
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateComplaintsStatus = createAsyncThunk(
  "complaints/updateStatus",
  async ({ reclamationId }, { rejectWithValue }) => {
    try {
      const response = await updateComplaintStatus(reclamationId);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const complaintSlice = createSlice({
  name: "complaints",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchComplaints.fulfilled, (state, action) => {
      state.complaintsData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchComplaints.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(updateComplaintsStatus.fulfilled, (state, action) => {
      state.complaintsData.totalData--;
      state.complaintsData.data = state.complaintsData.data.filter(
        (elem) => elem._id !== action.payload.data._id
      );
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateComplaintsStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});
export const complaintReducer = complaintSlice.reducer;
export const { actions } = complaintSlice;
