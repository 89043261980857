import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
    en: {
        translations: {
            "Delete": "Delete",
            "Add new employee": "Add new employee",
            "Add New Client": "Add new client",
            "Dashboard": "Dashboard",
            "Service": "Service",
            "Default": "Default",
            "Ecommerce": "Ecommerce",
            "Widgets": "Widgets",
            "General": "General",
            "Chart": "Chart",
            "Project": "Project",
            "Project List": "Project List",
            "Create New": "Create New",
            "Product": "Product",
            "Product Page": "Product Page",
            "Product List": "Product List",
            "Add Product": "Add Product",
            "Payment Detail": "Payment Detail",
            "Order History": "Order History",
            "Pricing": "Pricing",
            "Invoice": "Invoice",
            "Cart": "Cart",
            "Checkout": "Checkout",
            "Whishlist": "Whishlist",
            "Users": "Users",
            "Users Profile": "Users Profile",
            "Users Edit": "Users Edit",
            "Users Cards": "Users Cards",
            "Calender": "Calender",
            "Chat": "Chat",
            "Chat App": "Chat App",
            "Video App": "Video App",
            "Email": "Email",
            "Mail Inbox": "Mail Inbox",
            "Read Mail": "Read Mail",
            "Compose": "Compose",
            "File Manager": "File Manager",
            "Kanban Board": "Kanban Board",
            "Bookmark": "Bookmark",
            "Task": "Task",
            "Social App": "Social App",
            "Contacts": "Contacts",
            "To-Do": "To-Do",
            "Ui-Kits": "Ui-Kits",
            "Typography": "Typography",
            "Avatars": "Avatars",
            "Helper-Classes": "Helper-Classes",
            "Grid": "Grid",
            "Tag & Pills": "Tag & Pills",
            "Progress": "Progress",
            "Modal": "Modal",
            "Alert": "Alert",
            "Popover": "Popover",
            "Tooltip": "Tooltip",
            "Spinners": "Spinners",
            "Dropdown": "Dropdown",
            "Accordion": "Accordion",
            "Tabs": "Tabs",
            "Bootstrap Tabs": "Bootstrap Tabs",
            "Line Tabs": "Line Tabs",
            "Shadow": "Shadow",
            "List": "List",
            "Bonus Ui": "Bonus Ui",
            "Scrollable": "Scrollable",
            "Tree": "Tree",
            "Rating": "Rating",
            "Dropzone": "Dropzone",
            "Tour": "Tour",
            "SweetAlert": "SweetAlert",
            "Ribbons": "Ribbons",
            "Pagination": "Pagination",
            "Breadcrumb": "Breadcrumb",
            "Range Slider": "Range Slider",
            "Image Cropper": "Image Cropper",
            "Sticky": "Sticky",
            "Drag and Drop": "Drag and Drop",
            "Upload": "Upload",
            "Basic Card": "Basic Card",
            "Draggable Card": "Draggable Card",
            "Timeline": "Timeline",
            "Icons": "Icons",
            "Flag Icon": "Flag Icon",
            "Fontawesome Icon": "Fontawesome Icon",
            "Ico Icon": "Ico Icon",
            "Themify Icon": "Themify Icon",
            "Feather Icon": "Feather Icon",
            "Whether Icon": "Whether Icon",
            "Buttons": "Buttons",
            "Default Style": "Default Style",
            "Button Group": "Button Group",
            "Charts": "Charts",
            "Apex Chart": "Apex Chart",
            "Google Chart": "Google Chart",
            "Chartjs": "Chartjs",
            "Chartist": "Chartist",
            "Forms": "Forms",
            "Form Controls": "Form Controls",
            "Form Validation": "Form Validation",
            "Basic Input": "Basic Input",
            "Checkbox & Radio": "Checkbox & Radio",
            "Input Groups": "Input Groups",
            "Mega Option": "Mega Option",
            "Form Widgets": "Form Widgets",
            "Datepicker": "Datepicker",
            "Typeahead": "Typeahead",
            "rangepicker": "rangepicker",
            "Touchspin": "Touchspin",
            "Select2": "Select2",
            "Switch": "Switch",
            "Clipboard": "Clipboard",
            "Form Default": "Form Default",
            "Form Wizard": "Form Wizard",
            "Tables": "Tables",
            "Reactstrap Table": "Reactstrap Table",
            "Basic Table": "Basic Table",
            "Sizing Table": "Sizing Table",
            "Border Table": "Border Table",
            "Styling Table": "Styling Table",
            "Data Tables": "Data Tables",
            "Others": "Others",
            "Coming Soon": "Coming Soon",
            "Coming Sample": "Coming Sample",
            "Coming with Bg-video": "Coming with Bg-video",
            "Coming with Bg-Image": "Coming with Bg-Image",
            "Authentication": "Authentication",
            "Login Simple": "Login Simple",
            "Login With bg image": "Login With bg image",
            "Login with image two": "Login with image two",
            "Login with validation": "Login with validation",
            "Login with tooltip": "Login with tooltip",
            "Login with sweetaleart": "Login with sweetaleart",
            "Register Simple": "Register Simple",
            "Register with Bg image": "Register with Bg image",
            "Register with Bg Video": "Register with Bg Video",
            "Unlock User": "Unlock User",
            "Forgot Password": "Forgot Password",
            "Create Passward": "Create Passward",
            "Maintenance": "Maintenance",
            "Gallery": "Gallery",
            "Gallery Grid": "Gallery Grid",
            "Gallery Grid  Desc": "Gallery Grid  Desc",
            "Masonry Gallery": "Masonry Gallery",
            "Masonry With Desc": "Masonry With Desc",
            "Hover Effect": "Hover Effect",
            "Blog": "Blog",
            "Blog Details": "Blog Details",
            "Blog Single": "Blog Single",
            "Add Post": "Add Post",
            "Job Search": "Job Search",
            "Cards View": "Cards View",
            "List View": "List View",
            "Job Details": "Job Details",
            "Apply": "Apply",
            "Learning": "Learning",
            "Learning List": "Learning List",
            "Detail Course": "Detail Course",
            "Maps": "Maps",
            "Google Maps": "Google Maps",
            "Editor": "Editor",
            "CK  Editor": "CK  Editor",
            "MDE Editor": "MDE Editor",
            "ACE code editor": "ACE code editor",
            "FAQ": "FAQ",
            "knowledgebase": "knowledgebase",
            "KnowledgeCategory": "KnowledgeCategory",
            "KnowledgeDetail": "KnowledgeDetail",
            "sub": "sub",
            "Services Manager": "Services Manager",
            "Services": "Services",
            "Category": "Category",
            "Department": "Department",
            "Employees": "Employees",
            "Clients": "Clients",
            "Rooms": "Rooms",
            "Orders": "Orders",
            "Settings": "Settings",
            "Add new service": "Add new service",
            "Image": "Image",
            "Name": "Name",
            "Description": "Description",
            "Products": "Products",
            "Action": "Action",
            "Sub Category": "Sub Category",
            "Add New Category": "Add New Category",
            "Add new department": "Add new department",
            "Service name": "Service name",
            "Save": "Save",
            "Price": "Price",
            "Duration": "Duration",
            "Product name": "Product name",
            "Type": "Type",
            "Add": "Add",
            "Edit": "Edit",
            "Category Name": "Category Name",
            "Parent Category": "Parent Category",
            "Department Name": "Department Name",
            "Day": "Day",
            "Open time": "Open time",
            "Close time": "Close time",
            "24H open": "24H open",
            "Monday": "Monday",
            "Tuesday": "Tuesday",
            "Wednesday": "Wednesday",
            "Thursday": "Thursday",
            "Friday": "Friday",
            "Saturday": "Saturday",
            "Sunday": "Sunday",
            "First Name": "First Name",
            "Last Name": "Last Name",
            "Phone": "Phone",
            "Role": "Role",
            "Email address": "Email address",
            "Phone number": "Phone number",
            "Username": "Username",
            "Custom username": "Custom username",
            "Repeat password": "Repeat password",
            "Password": "Password",
            "Roles": "Roles",
            "Close": "Close",
            "Unique Id": "Unique Id",
            "Please enter the unique Identifier": "Please enter the unique Identifier",
            "Search": "Search",
            "Add with Scan": "Add with Scan",
            "Send File": "Send File",
            "Update Affectation": "Update Affectation",
            "Status": "Status",
            "Date": "Date",
            "Room Number": "Room Number",
            "Images": "Images",
            "Number": "Number",
            "Establishment": "Establishment",
            "Contact": "Contact",
            "Establishment name": "Establishment name",
            "Region": "Region",
            "Price per night average": "Price per night average",
            "Select your localisation": "Select your localisation",
            "Permissions": "Permissions",
            "Owner": "Owner",
            "Add Role": "Add Role",
            "Update Role": "Update Role",
            "Update": "Update",
            "Add Client": "Add Client",
            "rooms": "rooms",
            "users": "users",
            "employees": "employees",
            "edit": "edit",
            "services-manager": "services-manager",
            "services": "services",
            "new": "new",
            "dashboard": "dashboard",
            "category": "category",
            "department": "department",
            "Add Room": "Add Room",
            "Copyright 2024 © Officent All rights reserved.": "Copyright 2024 © Officent All rights reserved.",
            "Hand crafted": "Hand crafted",
            "made with": "made with",
            "orders": "orders"
        }
    },
      cn: {
        translations: {
          "Delete": "刪除",
          "Add new employee": "添加新员工",
          "Add New Client": "添加新客户",
            "Dashboard": "仪表板",
            "Service":"服务",
            "Deafult": "默认",
            "Ecommerce": "电子商务",
            "Widgets": "小部件",
            "General": "一般的",
            "Chart": "图表",
            "Project": "项目",
            "Project List": "项目清单",
            "Create New": "创建新的",
            "Product": "产品",
            "Product Page": "产品页面",
            "Product List": "产品列表",
            "Add Product": "添加产品",
            "Payment Detail": "付款明细",
            "Order History": "订单历史",
            "Pricing": "价钱",
            "Invoice": "发票",
            "Cart": "大车",
            "Checkout": "查看",
            "Whishlist": "心愿单",
            "Users": "用户",
            "Users Profile": "用户资料",
            "Users Edit": "用户编辑",
            "Users Cards": "用户卡",
            "Calender": "日历",
            "Chat": "聊天",
            "Chat App": "聊天应用",
            "Video App": "视频应用",
            "Email": "电子邮件",
            "Mail Inbox": "邮件收件箱",
            "Read Mail": "阅读邮件",
            "Compose": "撰写",
            "File Manager": "文件管理器",
            "Kanban Board": "看板",
            "Bookmark": "书签",
            "Task": "任务",
            "Social App": "社交应用",
            "Contacts": "联系人",
            "To-Do": "去做",
            "Ui-Kits": "媚俗",
            "Typography": "排版",
            "Avatars": "化身",
            "Helper-Classes": "辅助类",
            "Grid": "网格",
            "Tag & Pills": "标签和药丸",
            "Progress": "进步",
            "Modal": "模态",
            "Alert": "警报",
            "Popover": "弹窗",
            "Tooltip": "工具提示",
            "Spinners": "纺车",
            "Dropdown": "落下",
            "Accordion": "手风琴",
            "Tabs": "选项卡",
            "Bootstrap Tabs": "引导标签",
            "Line Tabs": "行选项卡",
            "Shadow": "阴影",
            "List": "列表",
            "Bonus Ui": "奖金用户界面",
            "Scrollable": "可滚动",
            "Tree": "树",
            "Rating": "评分",
            "Dropzone": "拖放区",
            "Tour": "旅游",
            "SweetAlert": "甜蜜提醒",
            "Ribbons": "丝带",
            "Pagination": "分页",
            "Breadcrumb": "面包屑",
            "Range Slider": "范围滑块",
            "Image Cropper": "图像裁剪器",
            "Sticky": "黏",
            "Drag and Drop": "拖放",
            "Upload": "上传",
            "Basic Card": "基本卡",
            "Draggable Card": "可拖动卡片",
            "Timeline": "时间线",
            "Icons": "图标",
            "Flag Icon": "国旗图标",
            "Fontawesome Icon": "字体真棒 图标",
            "Ico Icon": "图标",
            "Themify Icon": "主题化图标",
            "Feather Icon": "羽毛图标",
            "Whether Icon": "是否图标",
            "Buttons": "纽扣",
            "Default Style": "默认样式",
            "Button Group": "按钮组",
            "Charts": "图表",
            "Apex Chart": "顶点图表",
            "Google Chart": "谷歌图表",
            "Chartjs": "图表",
            "Chartist": "宪章主义者",
            "Forms": "形式",
            "Form Controls": "表单控件",
            "Form Validation": "表单验证",
            "Basic Input": "基本输入",
            "Checkbox & Radio": "复选框和收音机",
            "Input Groups": "输入组",
            "Mega Option": "超级选项",
            "Form Widgets": "表单小部件",
            "Datepicker": "日期选择器",
            "Typeahead": "提前输入",
            "rangepicker": "测距仪",
            "Touchspin": "触旋",
            "Select2": "选择2",
            "Switch": "转变",
            "Clipboard": "剪贴板",
            "Form Default": "表单默认",
            "Form Wizard": "表单向导",
            "Tables": "表",
            "Reactstrap Table": "反应表",
            "Basic Table": "基本表",
            "Sizing Table": "尺码表",
            "Border Table": "边框表",
            "Styling Table": "造型台",
            "Data Tables": "数据表",
            "Others": "其他的",
            "Coming Soon": "即将推出",
            "Coming Sample": "来样",
            "Coming with Bg-video": "附带背景视频",
            "Coming with Bg-Image": "附带 背景图片",
            "Authentication": "验证",
            "Login Simple": "登录简单",
            "Login With bg image": "使用背景图片登录",
            "Login with image two": "图二登录",
            "Login with validation": "登录验证",
            "Login with tooltip": "使用工具提示登录",
            "Login with sweetaleart": "使用甜心登录",
            "Register Simple": "注册简单",
            "Register with Bg image": "使用背景图片注册",
            "Register with Bg Video": "注册后台视频",
            "Unlock User": "解锁用户",
            "Forgot Password": "忘记密码",
            "Create Passward": "创建密码",
            "Maintenance": "维护",
            "Gallery": "画廊",
            "Gallery Grid": "画廊网格",
            "Gallery Grid  Desc": "画廊网格描述",
            "Masonry Gallery": "砌体画廊",
            "Masonry With Desc": "带描述的砌体 ",
            "Hover Effect": "悬停效果",
            "Blog": "博客",
            "Blog Details": "博客详情",
            "Blog Single": "博客单",
            "Add Post": "添加帖子",
            "Job Search": "求职",
            "Cards View": "卡片视图",
            "List View": "列表显示",
            "Job Details": "职位详情",
            "Apply": "申请",
            "Learning": "学习",
            "Learning List": "学习清单",
            "Detail Course": "详细课程",
            "Maps": "地图",
            "Google Maps": "谷歌地图",
            "Editor": "编辑",
            "CK  Editor": "CK编辑器",
            "MDE Editor": "MDE 编辑器",
            "ACE code editor": "ACE编辑器",
            "FAQ": "常问问题",
            "knowledgebase": "知识库",
            "KnowledgeCategory": "知识类",
            "KnowledgeDetail": "知识详情",
            "sub": "子",
            "Services Manager":"服務經理",
            "Services": "服務",
            "Category": "類別",
            "Department": "部門",
            "Employees": "僱員",
            "Clients": "客戶",
            "Rooms": "客房",
            "Orders": "命令",
            "Settings": "設定",
            "Add new service": "新增服務",
            "Image": "影像",
            "Name": "姓名",
            "Description": "描述",
            "Products": "產品",
            "Action": "行動",
            "Sub Category":  "子類別",
            "Add New Category": "新增類別",
            "Add new department": "新增部門",
            "Service name": "服務名稱",
            "Save": "節省",
            "Price": "價格",
            "Duration": "期間",
            "Product name": "產品名稱",
            "Type": "類型",
            "Add": "添加",
            "Edit": "編輯",
            "Category Name": "分類名稱",
            "Parent Category": "家長類別",
            "Department Name": "部門名稱",
            "Day": "天",
            "Open time": "開放時間",
            "Close time": "關閉時間",
            "24H open": "24小時開放",
            "Monday": "週一",
            "Tuesday": "週二",
            "Wednesday": "週三",
            "Thursday": "週四",
            "Friday": "星期五",
            "Saturday": "週六",
            "Sunday": "星期日",
            "First Name": "名",
            "Last Name": "姓",
            "Phone": "電話",
            "Role": "角色",
            "Email address": "電子郵件地址",
            "Phone number": "電話號碼",
           "Username": "使用者名稱",
           "Custom username": "自訂使用者名稱",
           "Repeat password" : "重複輸入密碼",
           "Password": "密碼",
           "Roles": "角色",
           "Close": "關閉",
           "Unique Id": "唯一身份",
           "Please enter the unique Identifier": "請輸入唯一識別符",
           "Search": "搜尋",
           "Add with Scan": "掃描添加",
           "Send File": "傳送文件",
           "Update Affectation": "更新感情",
           "Status": "地位",
           "Date": "日期",
           "Room Number": "房號",
           "Images": "圖片",
           "Number": "數位",
           "Establishment": "設立",
           "Contact": "接觸",
           "Establishment name": "機構名稱",
           "Region": "地區",
           "Price per night average": "每晚平均價格",
           "Select your localisation": "選擇您的本地化",
           "Permissions": "權限",
           "Owner": "擁有者",
           "Add Role": "添加角色",
           "Update Role": "更新角色",
           "Update": "更新",
           "Add Client": "新增客戶端",
           "rooms": "客房",
           "users": "使用者",
           "employees": "僱員",
           "edit": "編輯",
           "services-manager": "服務經理",
           "services": "服務",
           "new": "新的",
           "dashboard": "儀表板",
           "category": "類別",
           "department": "部門",
           "Add Room": "添加房間",
           "Copyright 2024 © Officent All rights reserved.": "版權所有 2024 © Officent 保留所有權利。",
           "Hand crafted": "手工製作",
           "made with": "用做的",
           "orders": "命令"
           
          
          },
      },
      "ae": {
        "translations": {
            "Delete": "حذف",
            "Add new employee": "إضافة موظف جديد",
            "Add New Client": "إضافة عميل جديد",
            "Dashboard": "لوحة القيادة",
            "Service": "خدمة",
            "Deafult": "افتراضي",
            "Ecommerce": "التجارة الإلكترونية",
            "Widgets": "الحاجيات",
            "General": "عام",
            "Chart": "مخطط",
            "Project": "مشروع",
            "Project List": "قائمة المشاريع",
            "Create New": "إنشاء جديد",
            "Product": "منتج",
            "Product Page": "صفحة المنتج",
            "Product List": "قائمة المنتجات",
            "Add Product": "إضافة منتج",
            "Payment Detail": "تفاصيل الدفع",
            "Order History": "تاريخ الطلبات",
            "Pricing": "التسعير",
            "Invoice": "فاتورة",
            "Cart": "عربة التسوق",
            "Checkout": "إتمام الشراء",
            "Whishlist": "قائمة الرغبات",
            "Users": "المستخدمين",
            "Users Profile": "ملف المستخدمين",
            "Users Edit": "تعديل المستخدمين",
            "Users Cards": "بطاقات المستخدمين",
            "Calender": "التقويم",
            "Chat": "دردشة",
            "Chat App": "تطبيق الدردشة",
            "Video App": "تطبيق الفيديو",
            "Email": "البريد الإلكتروني",
            "Mail Inbox": "علبة الوارد",
            "Read Mail": "قراءة البريد",
            "Compose": "إنشاء",
            "File Manager": "مدير الملفات",
            "Kanban Board": "لوحة كانبان",
            "Bookmark": "إشارة مرجعية",
            "Task": "مهمة",
            "Social App": "التطبيق الاجتماعي",
            "Contacts": "جهات الاتصال",
            "To-Do": "قائمة المهام",
            "Ui-Kits": "مجموعات واجهة المستخدم",
            "Typography": "الطباعة",
            "Avatars": "الصور الرمزية",
            "Helper-Classes": "فئات المساعد",
            "Grid": "شبكة",
            "Tag & Pills": "العلامات والحبوب",
            "Progress": "التقدم",
            "Modal": "نافذة منبثقة",
            "Alert": "تنبيه",
            "Popover": "النافذة المنبثقة",
            "Tooltip": "تلميح الأداة",
            "Spinners": "الدوارات",
            "Dropdown": "القائمة المنسدلة",
            "Accordion": "الأكورديون",
            "Tabs": "علامات التبويب",
            "Bootstrap Tabs": "علامات تبويب Bootstrap",
            "Line Tabs": "علامات تبويب الخط",
            "Shadow": "ظل",
            "List": "قائمة",
            "Bonus Ui": "واجهة المستخدم الإضافية",
            "Scrollable": "قابل للتمرير",
            "Tree": "شجرة",
            "Rating": "التقييم",
            "Dropzone": "منطقة الإسقاط",
            "Tour": "جولة",
            "SweetAlert": "تنبيه لطيف",
            "Ribbons": "أشرطة",
            "Pagination": "ترقيم الصفحات",
            "Breadcrumb": "شريط التصفح",
            "Range Slider": "شريط التمرير للنطاق",
            "Image Cropper": "أداة قص الصور",
            "Sticky": "ثابت",
            "Drag and Drop": "السحب والإفلات",
            "Upload": "تحميل",
            "Basic Card": "بطاقة أساسية",
            "Draggable Card": "بطاقة قابلة للسحب",
            "Timeline": "الجدول الزمني",
            "Icons": "الأيقونات",
            "Flag Icon": "أيقونة العلم",
            "Fontawesome Icon": "أيقونة Fontawesome",
            "Ico Icon": "أيقونة Ico",
            "Themify Icon": "أيقونة Themify",
            "Feather Icon": "أيقونة Feather",
            "Whether Icon": "أيقونة الطقس",
            "Buttons": "الأزرار",
            "Default Style": "النمط الافتراضي",
            "Button Group": "مجموعة الأزرار",
            "Charts": "الرسوم البيانية",
            "Apex Chart": "مخطط Apex",
            "Google Chart": "مخطط Google",
            "Chartjs": "Chartjs",
            "Chartist": "Chartist",
            "Forms": "النماذج",
            "Form Controls": "عناصر التحكم في النموذج",
            "Form Validation": "التحقق من النموذج",
            "Basic Input": "إدخال أساسي",
            "Checkbox & Radio": "خانة اختيار وراديو",
            "Input Groups": "مجموعات الإدخال",
            "Mega Option": "خيار كبير",
            "Form Widgets": "عناصر واجهة النموذج",
            "Datepicker": "منتقي التاريخ",
            "Typeahead": "Typeahead",
            "rangepicker": "منتقي النطاق",
            "Touchspin": "Touchspin",
            "Select2": "Select2",
            "Switch": "تبديل",
            "Clipboard": "الحافظة",
            "Form Default": "النموذج الافتراضي",
            "Form Wizard": "معالج النموذج",
            "Tables": "الجداول",
            "Reactstrap Table": "جدول Reactstrap",
            "Basic Table": "جدول أساسي",
            "Sizing Table": "جدول التحجيم",
            "Border Table": "جدول الحدود",
            "Styling Table": "جدول التصميم",
            "Data Tables": "جداول البيانات",
            "Others": "أخرى",
            "Coming Soon": "قريبا",
            "Coming Sample": "نموذج قادم",
            "Coming with Bg-video": "قادم مع فيديو خلفية",
            "Coming with Bg-Image": "قادم مع صورة خلفية",
            "Authentication": "المصادقة",
            "Login Simple": "تسجيل دخول بسيط",
            "Login With bg image": "تسجيل دخول مع صورة خلفية",
            "Login with image two": "تسجيل دخول مع صورة ثانية",
            "Login with validation": "تسجيل دخول مع التحقق",
            "Login with tooltip": "تسجيل دخول مع تلميح أداة",
            "Login with sweetaleart": "تسجيل دخول مع تنبيه لطيف",
            "Register Simple": "تسجيل بسيط",
            "Register with Bg image": "تسجيل مع صورة خلفية",
            "Register with Bg Video": "تسجيل مع فيديو خلفية",
            "Unlock User": "فتح المستخدم",
            "Forgot Password": "نسيت كلمة المرور",
            "Create Passward": "إنشاء كلمة مرور",
            "Maintenance": "الصيانة",
            "Gallery": "معرض الصور",
            "Gallery Grid": "شبكة المعرض",
            "Gallery Grid Desc": "وصف شبكة المعرض",
            "Masonry Gallery": "معرض البناء",
            "Masonry With Desc": "بناء مع وصف",
            "Hover Effect": "تأثير التحويم",
            "Blog": "مدونة",
            "Blog Details": "تفاصيل المدونة",
            "Blog Single": "مدونة فردية",
            "Add Post": "إضافة منشور",
            "Job Search": "البحث عن وظيفة",
            "Cards View": "عرض البطاقات",
            "List View": "عرض القائمة",
            "Job Details": "تفاصيل الوظيفة",
            "Apply": "تقديم",
            "Learning": "التعلم",
            "Learning List": "قائمة التعلم",
            "Detail Course": "تفاصيل الدورة",
            "Maps": "خرائط",
            "Google Maps": "خرائط Google",
            "Editor": "المحرر",
            "CK Editor": "محرر CK",
            "MDE Editor": "محرر MDE",
            "ACE code editor": "محرر أكواد ACE",
            "FAQ": "الأسئلة الشائعة",
            "knowledgebase": "قاعدة المعرفة",
            "KnowledgeCategory": "فئة المعرفة",
            "KnowledgeDetail": "تفاصيل المعرفة",
            "sub": "فرعي",
            "Services Manager": "مدير الخدمات",
            "Services": "الخدمات",
            "Category": "الفئة",
            "Department": "القسم",
            "Employees": "الموظفون",
            "Clients": "العملاء",
            "Rooms": "الغرف",
            "Orders": "الأوامر",
            "Settings": "الإعدادات",
            "Add new service": "إضافة خدمة جديدة",
            "Image": "صورة",
            "Name": "الاسم",
            "Description": "الوصف",
            "Products": "المنتجات",
            "Action": "الإجراء",
            "Sub Category": "الفئة الفرعية",
            "Add New Category": "إضافة فئة جديدة",
            "Add new department": "إضافة قسم جديد",
            "Service name": "اسم الخدمة",
            "Save": "حفظ",
            "Price": "السعر",
            "Duration": "المدة",
            "Product name": "اسم المنتج",
            "Type": "النوع",
            "Add": "إضافة",
            "Edit": "تعديل",
            "Category Name": "اسم الفئة",
            "Parent Category": "الفئة الأصلية",
            "Department Name": "اسم القسم",
            "Day": "اليوم",
            "Open time": "وقت الفتح",
            "Close time": "وقت الإغلاق",
            "24H open": "مفتوح 24 ساعة",
            "Monday": "الإثنين",
            "Tuesday": "الثلاثاء",
            "Wednesday": "الأربعاء",
            "Thursday": "الخميس",
            "Friday": "الجمعة",
            "Saturday": "السبت",
            "Sunday": "الأحد",
            "First Name": "الاسم الأول",
            "Last Name": "الاسم الأخير",
            "Phone": "الهاتف",
            "Role": "الدور",
            "Email address": "عنوان البريد الإلكتروني",
            "Phone number": "رقم الهاتف",
            "Username": "اسم المستخدم",
            "Custom username": "اسم مستخدم مخصص",
            "Repeat password": "أعد إدخال كلمة المرور",
            "Password": "كلمة المرور",
            "Roles": "الأدوار",
            "Close": "إغلاق",
            "Unique Id": "معرف فريد",
            "Please enter the unique Identifier": "يرجى إدخال المعرف الفريد",
            "Search": "بحث",
            "Add with Scan": "إضافة بالمسح الضوئي",
            "Send File": "إرسال الملف",
            "Update Affectation": "تحديث التعيين",
            "Status": "الحالة",
            "Date": "التاريخ",
            "Room Number": "رقم الغرفة",
            "Images": "الصور",
            "Number": "الرقم",
            "Establishment": "المؤسسة",
            "Contact": "الاتصال",
            "Establishment name": "اسم المؤسسة",
            "Region": "المنطقة",
            "Price per night average": "متوسط السعر لكل ليلة",
            "Select your localisation": "اختر موقعك",
            "Permissions": "الأذونات",
            "Owner": "المالك",
            "Add Role": "إضافة دور",
            "Update Role": "تحديث الدور",
            "Update": "تحديث",
            "Add Client": "إضافة عميل",
            "rooms": "الغرف",
            "users": "المستخدمين",
            "employees": "الموظفين",
            "edit": "تعديل",
            "services-manager": "مدير الخدمات",
            "services": "الخدمات",
            "new": "جديد",
            "dashboard": "لوحة القيادة",
            "category": "الفئة",
            "department": "القسم",
            "Add Room": "إضافة غرفة",
            "Copyright 2024 © Officent All rights reserved.": "حقوق النشر 2024 © Officent جميع الحقوق محفوظة.",
            "Hand crafted": "مصنوع يدويا",
            "made with": "مصنوع بـ",
            "orders": "الأوامر"
        }
    },
    "fr": {
      "translations": {
          "Delete": "Supprimer",
          "Add new employee": "Ajouter un nouvel employé",
          "Add New Client": "Ajouter un nouveau client",
          "Dashboard": "Tableau de bord",
          "Service": "Service",
          "Default": "Par défaut",
          "Ecommerce": "E-commerce",
          "Widgets": "Widgets",
          "General": "Général",
          "Chart": "Graphique",
          "Project": "Projet",
          "Project List": "Liste des projets",
          "Create New": "Créer nouveau",
          "Product": "Produit",
          "Product Page": "Page produit",
          "Product List": "Liste des produits",
          "Add Product": "Ajouter un produit",
          "Payment Detail": "Détail de paiement",
          "Order History": "Historique des commandes",
          "Pricing": "Tarification",
          "Invoice": "Facture",
          "Cart": "Panier",
          "Checkout": "Paiement",
          "Whishlist": "Liste de souhaits",
          "Users": "Utilisateurs",
          "Users Profile": "Profil des utilisateurs",
          "Users Edit": "Modifier les utilisateurs",
          "Users Cards": "Cartes des utilisateurs",
          "Calender": "Calendrier",
          "Chat": "Chat",
          "Chat App": "Application de chat",
          "Video App": "Application vidéo",
          "Email": "Email",
          "Mail Inbox": "Boîte de réception",
          "Read Mail": "Lire l'email",
          "Compose": "Composer",
          "File Manager": "Gestionnaire de fichiers",
          "Kanban Board": "Tableau Kanban",
          "Bookmark": "Signet",
          "Task": "Tâche",
          "Social App": "Application sociale",
          "Contacts": "Contacts",
          "To-Do": "À faire",
          "Ui-Kits": "Kits d'interface utilisateur",
          "Typography": "Typographie",
          "Avatars": "Avatars",
          "Helper-Classes": "Classes d'aide",
          "Grid": "Grille",
          "Tag & Pills": "Tag & Pilules",
          "Progress": "Progression",
          "Modal": "Modal",
          "Alert": "Alerte",
          "Popover": "Popover",
          "Tooltip": "Info-bulle",
          "Spinners": "Spinners",
          "Dropdown": "Menu déroulant",
          "Accordion": "Accordéon",
          "Tabs": "Onglets",
          "Bootstrap Tabs": "Onglets Bootstrap",
          "Line Tabs": "Onglets de ligne",
          "Shadow": "Ombre",
          "List": "Liste",
          "Bonus Ui": "Bonus UI",
          "Scrollable": "Défilable",
          "Tree": "Arbre",
          "Rating": "Évaluation",
          "Dropzone": "Zone de dépôt",
          "Tour": "Visite",
          "SweetAlert": "SweetAlert",
          "Ribbons": "Rubans",
          "Pagination": "Pagination",
          "Breadcrumb": "Fil d'Ariane",
          "Range Slider": "Curseur de plage",
          "Image Cropper": "Recadrage d'image",
          "Sticky": "Sticky",
          "Drag and Drop": "Glisser-déposer",
          "Upload": "Téléverser",
          "Basic Card": "Carte de base",
          "Draggable Card": "Carte draggable",
          "Timeline": "Chronologie",
          "Icons": "Icônes",
          "Flag Icon": "Icône de drapeau",
          "Fontawesome Icon": "Icône Fontawesome",
          "Ico Icon": "Icône Ico",
          "Themify Icon": "Icône Themify",
          "Feather Icon": "Icône Feather",
          "Whether Icon": "Icône de temps",
          "Buttons": "Boutons",
          "Default Style": "Style par défaut",
          "Button Group": "Groupe de boutons",
          "Charts": "Graphiques",
          "Apex Chart": "Graphique Apex",
          "Google Chart": "Graphique Google",
          "Chartjs": "Chartjs",
          "Chartist": "Chartist",
          "Forms": "Formulaires",
          "Form Controls": "Contrôles de formulaire",
          "Form Validation": "Validation de formulaire",
          "Basic Input": "Entrée de base",
          "Checkbox & Radio": "Case à cocher & Radio",
          "Input Groups": "Groupes d'entrée",
          "Mega Option": "Méga option",
          "Form Widgets": "Widgets de formulaire",
          "Datepicker": "Sélecteur de date",
          "Typeahead": "Typeahead",
          "rangepicker": "Sélecteur de plage",
          "Touchspin": "Touchspin",
          "Select2": "Select2",
          "Switch": "Commutateur",
          "Clipboard": "Presse-papiers",
          "Form Default": "Formulaire par défaut",
          "Form Wizard": "Assistant de formulaire",
          "Tables": "Tables",
          "Reactstrap Table": "Tableau Reactstrap",
          "Basic Table": "Table de base",
          "Sizing Table": "Tableau de dimensionnement",
          "Border Table": "Tableau de bordure",
          "Styling Table": "Styling de tableau",
          "Data Tables": "Tables de données",
          "Others": "Autres",
          "Coming Soon": "Prochainement",
          "Coming Sample": "Exemple à venir",
          "Coming with Bg-video": "Arrive avec vidéo de fond",
          "Coming with Bg-Image": "Arrive avec image de fond",
          "Authentication": "Authentification",
          "Login Simple": "Connexion simple",
          "Login With bg image": "Connexion avec image de fond",
          "Login with image two": "Connexion avec image deux",
          "Login with validation": "Connexion avec validation",
          "Login with tooltip": "Connexion avec info-bulle",
          "Login with sweetaleart": "Connexion avec SweetAlert",
          "Register Simple": "Inscription simple",
          "Register with Bg image": "Inscription avec image de fond",
          "Register with Bg Video": "Inscription avec vidéo de fond",
          "Unlock User": "Déverrouiller l'utilisateur",
          "Forgot Password": "Mot de passe oublié",
          "Create Passward": "Créer un mot de passe",
          "Maintenance": "Maintenance",
          "Gallery": "Galerie",
          "Gallery Grid": "Grille de galerie",
          "Gallery Grid Desc": "Description de la grille de galerie",
          "Masonry Gallery": "Galerie en maçonnerie",
          "Masonry With Desc": "Maçonnerie avec description",
          "Hover Effect": "Effet de survol",
          "Blog": "Blog",
          "Blog Details": "Détails du blog",
          "Blog Single": "Blog unique",
          "Add Post": "Ajouter un article",
          "Job Search": "Recherche d'emploi",
          "Cards View": "Vue en cartes",
          "List View": "Vue en liste",
          "Job Details": "Détails du poste",
          "Apply": "Appliquer",
          "Learning": "Apprentissage",
          "Learning List": "Liste d'apprentissage",
          "Detail Course": "Cours détaillé",
          "Maps": "Cartes",
          "Google Maps": "Google Maps",
          "Editor": "Éditeur",
          "CK Editor": "CK Editor",
          "MDE Editor": "MDE Editor",
          "ACE code editor": "Éditeur de code ACE",
          "FAQ": "FAQ",
          "Knowledgebase": "Base de connaissances",
          "KnowledgeCategory": "Catégorie de connaissances",
          "KnowledgeDetail": "Détail des connaissances",
          "Sub": "Sous",
          "Services Manager": "Gestionnaire de services",
          "Services": "Services",
          "Category": "Catégorie",
          "Department": "Département",
          "Employees": "Employés",
          "Clients": "Clients",
          "Rooms": "Chambres",
          "Orders": "Commandes",
          "Settings": "Paramètres",
          "Add new service": "Ajouter un nouveau service",
          "Image": "Image",
          "Name": "Nom",
          "Description": "Description",
          "Products": "Produits",
          "Action": "Action",
          "Sub Category": "Sous-catégorie",
          "Add New Category": "Ajouter une nouvelle catégorie",
          "Add new department": "Ajouter un nouveau département",
          "Service name": "Nom du service",
          "Save": "Enregistrer",
          "Price": "Prix",
          "Duration": "Durée",
          "Product name": "Nom du produit",
          "Type": "Type",
          "Add": "Ajouter",
          "Edit": "Modifier",
          "Category Name": "Nom de la catégorie",
          "Parent Category": "Catégorie parent",
          "Department Name": "Nom du département",
          "Day": "Jour",
          "Open time": "Heure d'ouverture",
          "Close time": "Heure de fermeture",
          "24H open": "Ouvert 24h/24",
          "Monday": "Lundi",
          "Tuesday": "Mardi",
          "Wednesday": "Mercredi",
          "Thursday": "Jeudi",
          "Friday": "Vendredi",
          "Saturday": "Samedi",
          "Sunday": "Dimanche",
          "First Name": "Prénom",
          "Last Name": "Nom de famille",
          "Phone": "Téléphone",
          "Role": "Rôle",
          "Email address": "Adresse e-mail",
          "Phone number": "Numéro de téléphone",
          "Username": "Nom d'utilisateur",
          "Custom username": "Nom d'utilisateur personnalisé",
          "Repeat password": "Répéter le mot de passe",
          "Password": "Mot de passe",
          "Roles": "Rôles",
          "Close": "Fermer",
          "Unique Id": "Identifiant unique",
          "Please enter the unique Identifier": "Veuillez entrer l'identifiant unique",
          "Search": "Rechercher",
          "Add with Scan": "Ajouter avec Scan",
          "Send File": "Envoyer le fichier",
          "Update Affectation": "Mise à jour de l'affectation",
          "Status": "Statut",
          "Date": "Date",
          "Room Number": "Numéro de chambre",
          "Images": "Images",
          "Number": "Numéro",
          "Establishment": "Établissement",
          "Contact": "Contact",
          "Establishment name": "Nom de l'établissement",
          "Region": "Région",
          "Price per night average": "Prix moyen par nuit",
          "Select your localisation": "Sélectionnez votre localisation",
          "Permissions": "Permissions",
          "Owner": "Propriétaire",
          "Add Role": "Ajouter un rôle",
          "Update Role": "Mettre à jour le rôle",
          "Update": "Mettre à jour",
          "Add Client": "Ajouter un client",
          "rooms": "Chambres",
          "users": "Utilisateurs",
          "employees": "Employés",
          "edit": "Modifier",
          "services-manager": "Gestionnaire de services",
          "services": "Services",
          "new": "Nouveau",
          "dashboard": "Tableau de bord",
          "category": "Catégorie",
          "department": "Département",
          "Add Room": "Ajouter une chambre",
          "Copyright 2024 © Officent All rights reserved.": "Droits d'auteur 2024 © Officent. Tous droits réservés.",
          "Hand crafted": "Fabriqué à la main",
          "made with": "fabriqué avec",
          "orders": "commandes"
      }
  },
      du: {
        translations: {
            "Delete": "Löschen",
            "Add new employee": "Neuen Mitarbeiter hinzufügen",
            "Add New Client": "Neuen Kunden hinzufügen",
            "Dashboard": "Dashboard",
            "Service": "Dienstleistung",
            "Default": "Standard",
            "Ecommerce": "E-Commerce",
            "Widgets": "Widgets",
            "General": "Allgemein",
            "Chart": "Diagramm",
            "Project": "Projekt",
            "Project List": "Projektliste",
            "Create New": "Neu erstellen",
            "Product": "Produkt",
            "Product Page": "Produktseite",
            "Product List": "Produktliste",
            "Add Product": "Produkt hinzufügen",
            "Payment Detail": "Zahlungsdetails",
            "Order History": "Bestellverlauf",
            "Pricing": "Preisgestaltung",
            "Invoice": "Rechnung",
            "Cart": "Warenkorb",
            "Checkout": "Kasse",
            "Whishlist": "Wunschliste",
            "Users": "Benutzer",
            "Users Profile": "Benutzerprofil",
            "Users Edit": "Benutzer bearbeiten",
            "Users Cards": "Benutzerkarten",
            "Calender": "Kalender",
            "Chat": "Chat",
            "Chat App": "Chat-Anwendung",
            "Video App": "Video-Anwendung",
            "Email": "E-Mail",
            "Mail Inbox": "E-Mail Posteingang",
            "Read Mail": "E-Mail lesen",
            "Compose": "Verfassen",
            "File Manager": "Dateimanager",
            "Kanban Board": "Kanban-Board",
            "Bookmark": "Lesezeichen",
            "Task": "Aufgabe",
            "Social App": "Soziale App",
            "Contacts": "Kontakte",
            "To-Do": "To-Do",
            "Ui-Kits": "UI-Kits",
            "Typography": "Typografie",
            "Avatars": "Avatare",
            "Helper-Classes": "Hilfsklassen",
            "Grid": "Raster",
            "Tag & Pills": "Tag & Pillen",
            "Progress": "Fortschritt",
            "Modal": "Modal",
            "Alert": "Warnung",
            "Popover": "Popover",
            "Tooltip": "Tooltip",
            "Spinners": "Spinner",
            "Dropdown": "Dropdown",
            "Accordion": "Akkordeon",
            "Tabs": "Tabs",
            "Bootstrap Tabs": "Bootstrap Tabs",
            "Line Tabs": "Linien-Tabs",
            "Shadow": "Schatten",
            "List": "Liste",
            "Bonus Ui": "Bonus-UI",
            "Scrollable": "Scrollbar",
            "Tree": "Baum",
            "Rating": "Bewertung",
            "Dropzone": "Dropzone",
            "Tour": "Tour",
            "SweetAlert": "SweetAlert",
            "Ribbons": "Bänder",
            "Pagination": "Seitennummerierung",
            "Breadcrumb": "Breadcrumb",
            "Range Slider": "Bereichsschieberegler",
            "Image Cropper": "Bildbeschneider",
            "Sticky": "Sticky",
            "Drag and Drop": "Drag & Drop",
            "Upload": "Hochladen",
            "Basic Card": "Einfache Karte",
            "Draggable Card": "Verschiebbare Karte",
            "Timeline": "Zeitachse",
            "Icons": "Icons",
            "Flag Icon": "Flaggen-Icon",
            "Fontawesome Icon": "Fontawesome-Icon",
            "Ico Icon": "Ico-Icon",
            "Themify Icon": "Themify-Icon",
            "Feather Icon": "Feather-Icon",
            "Whether Icon": "Whether-Icon",
            "Buttons": "Schaltflächen",
            "Default Style": "Standard-Stil",
            "Button Group": "Schaltflächengruppe",
            "Charts": "Diagramme",
            "Apex Chart": "Apex-Diagramm",
            "Google Chart": "Google-Diagramm",
            "Chartjs": "Chartjs",
            "Chartist": "Chartist",
            "Forms": "Formulare",
            "Form Controls": "Formularsteuerelemente",
            "Form Validation": "Formularvalidierung",
            "Basic Input": "Grundlegende Eingabe",
            "Checkbox & Radio": "Checkbox & Radio",
            "Input Groups": "Eingabegruppen",
            "Mega Option": "Mega-Option",
            "Form Widgets": "Formular-Widgets",
            "Datepicker": "Datumsauswahl",
            "Typeahead": "Typeahead",
            "rangepicker": "Zeitraumauswahl",
            "Touchspin": "Touchspin",
            "Select2": "Select2",
            "Switch": "Schalter",
            "Clipboard": "Zwischenablage",
            "Form Default": "Standardformular",
            "Form Wizard": "Formular-Assistent",
            "Tables": "Tabellen",
            "Reactstrap Table": "Reactstrap-Tabelle",
            "Basic Table": "Grundlegende Tabelle",
            "Sizing Table": "Größentabelle",
            "Border Table": "Rahmentabelle",
            "Styling Table": "Tabellenstyling",
            "Data Tables": "Daten-Tabellen",
            "Others": "Andere",
            "Coming Soon": "Kommt bald",
            "Coming Sample": "Kommendes Beispiel",
            "Coming with Bg-video": "Kommt mit Hintergrundvideo",
            "Coming with Bg-Image": "Kommt mit Hintergrundbild",
            "Authentication": "Authentifizierung",
            "Login Simple": "Einfacher Login",
            "Login With bg image": "Login mit Hintergrundbild",
            "Login with image two": "Login mit Bild zwei",
            "Login with validation": "Login mit Validierung",
            "Login with tooltip": "Login mit Tooltip",
            "Login with sweetaleart": "Login mit SweetAlert",
            "Register Simple": "Einfache Registrierung",
            "Register with Bg image": "Registrieren mit Hintergrundbild",
            "Register with Bg Video": "Registrieren mit Hintergrundvideo",
            "Unlock User": "Benutzer entsperren",
            "Forgot Password": "Passwort vergessen",
            "Create Passward": "Passwort erstellen",
            "Maintenance": "Wartung",
            "Gallery": "Galerie",
            "Gallery Grid": "Galerie Raster",
            "Gallery Grid Desc": "Galerie Raster Beschreibung",
            "Masonry Gallery": "Mauerwerk Galerie",
            "Masonry With Desc": "Mauerwerk mit Beschreibung",
            "Hover Effect": "Hover-Effekt",
            "Blog": "Blog",
            "Blog Details": "Blog Details",
            "Blog Single": "Einzelner Blog",
            "Add Post": "Beitrag hinzufügen",
            "Job Search": "Jobsuche",
            "Cards View": "Kartenansicht",
            "List View": "Listenansicht",
            "Job Details": "Jobdetails",
            "Apply": "Bewerben",
            "Learning": "Lernen",
            "Learning List": "Lernliste",
            "Detail Course": "Detailkurs",
            "Maps": "Karten",
            "Google Maps": "Google Maps",
            "Editor": "Editor",
            "CK Editor": "CK Editor",
            "MDE Editor": "MDE Editor",
            "ACE code editor": "ACE Code Editor",
            "FAQ": "FAQ",
            "Knowledgebase": "Wissensbasis",
            "KnowledgeCategory": "Wissenskategorie",
            "KnowledgeDetail": "Wissensdetail",
            "Sub": "Sub",
            "Services Manager": "Dienstleistungsmanager",
            "Services": "Dienstleistungen",
            "Category": "Kategorie",
            "Department": "Abteilung",
            "Employees": "Mitarbeiter",
            "Clients": "Kunden",
            "Rooms": "Räume",
            "Orders": "Bestellungen",
            "Settings": "Einstellungen",
            "Add new service": "Neuen Service hinzufügen",
            "Image": "Bild",
            "Name": "Name",
            "Description": "Beschreibung",
            "Products": "Produkte",
            "Action": "Aktion",
            "Sub Category": "Unterkategorie",
            "Add New Category": "Neue Kategorie hinzufügen",
            "Add new department": "Neue Abteilung hinzufügen",
            "Service name": "Service Name",
            "Save": "Speichern",
            "Price": "Preis",
            "Duration": "Dauer",
            "Product name": "Produktname",
            "Type": "Typ",
            "Add": "Hinzufügen",
            "Edit": "Bearbeiten",
            "Category Name": "Kategoriename",
            "Parent Category": "Übergeordnete Kategorie",
            "Department Name": "Abteilungsname",
            "Day": "Tag",
            "Open time": "Öffnungszeit",
            "Close time": "Schließzeit",
            "24H open": "24 Stunden geöffnet",
            "Monday": "Montag",
            "Tuesday": "Dienstag",
            "Wednesday": "Mittwoch",
            "Thursday": "Donnerstag",
            "Friday": "Freitag",
            "Saturday": "Samstag",
            "Sunday": "Sonntag",
            "First Name": "Vorname",
            "Last Name": "Nachname",
            "Phone": "Telefon",
            "Role": "Rolle",
            "Email address": "E-Mail-Adresse",
            "Phone number": "Telefonnummer",
            "Username": "Benutzername",
            "Custom username": "Benutzerdefinierter Benutzername",
            "Repeat password": "Passwort wiederholen",
            "Password": "Passwort",
            "Roles": "Rollen",
            "Close": "Schließen",
            "Unique Id": "Eindeutige ID",
            "Please enter the unique Identifier": "Bitte geben Sie die eindeutige Kennung ein",
            "Search": "Suchen",
            "Add with Scan": "Hinzufügen mit Scan",
            "Send File": "Datei senden",
            "Update Affectation": "Zuordnung aktualisieren",
            "Status": "Status",
            "Date": "Datum",
            "Room Number": "Zimmernummer",
            "Images": "Bilder",
            "Number": "Nummer",
            "Establishment": "Einrichtung",
            "Contact": "Kontakt",
            "Establishment name": "Einrichtungsname",
            "Region": "Region",
            "Price per night average": "Durchschnittspreis pro Nacht",
            "Select your localisation": "Wählen Sie Ihre Lokalisierung",
            "Permissions": "Berechtigungen",
            "Owner": "Eigentümer",
            "Add Role": "Rolle hinzufügen",
            "Update Role": "Rolle aktualisieren",
            "Update": "Aktualisieren",
            "Add Client": "Kunden hinzufügen",
            "rooms": "Räume",
            "users": "Benutzer",
            "employees": "Mitarbeiter",
            "edit": "Bearbeiten",
            "services-manager": "Dienstleistungsmanager",
            "services": "Dienstleistungen",
            "new": "Neu",
            "dashboard": "Dashboard",
            "category": "Kategorie",
            "department": "Abteilung",
            "Add Room": "Raum hinzufügen",
            "Copyright 2024 © Officent All rights reserved.": "Copyright 2024 © Officent Alle Rechte vorbehalten.",
            "Hand crafted": "Handgefertigt",
            "made with": "hergestellt mit",
            "orders": "Bestellungen"
        }
    },
      es: {
        translations: {
            "Delete": "Eliminar",
            "Add new employee": "Agregar nuevo empleado",
            "Add New Client": "Agregar nuevo cliente",
            "Dashboard": "Tablero",
            "Service": "Servicio",
            "Default": "Predeterminado",
            "Ecommerce": "Comercio electrónico",
            "Widgets": "Widgets",
            "General": "General",
            "Chart": "Gráfico",
            "Project": "Proyecto",
            "Project List": "Lista de proyectos",
            "Create New": "Crear nuevo",
            "Product": "Producto",
            "Product Page": "Página de producto",
            "Product List": "Lista de productos",
            "Add Product": "Agregar producto",
            "Payment Detail": "Detalles de pago",
            "Order History": "Historial de pedidos",
            "Pricing": "Precios",
            "Invoice": "Factura",
            "Cart": "Carrito",
            "Checkout": "Pagar",
            "Whishlist": "Lista de deseos",
            "Users": "Usuarios",
            "Users Profile": "Perfil de usuario",
            "Users Edit": "Editar usuario",
            "Users Cards": "Tarjetas de usuario",
            "Calender": "Calendario",
            "Chat": "Chat",
            "Chat App": "Aplicación de chat",
            "Video App": "Aplicación de video",
            "Email": "Correo electrónico",
            "Mail Inbox": "Bandeja de entrada",
            "Read Mail": "Leer correo",
            "Compose": "Redactar",
            "File Manager": "Gestor de archivos",
            "Kanban Board": "Tablero Kanban",
            "Bookmark": "Marcador",
            "Task": "Tarea",
            "Social App": "Aplicación social",
            "Contacts": "Contactos",
            "To-Do": "Lista de tareas",
            "Ui-Kits": "Ui-Kits",
            "Typography": "Tipografía",
            "Avatars": "Avatares",
            "Helper-Classes": "Clases de ayuda",
            "Grid": "Cuadrícula",
            "Tag & Pills": "Etiquetas y píldoras",
            "Progress": "Progreso",
            "Modal": "Modal",
            "Alert": "Alerta",
            "Popover": "Popover",
            "Tooltip": "Tooltip",
            "Spinners": "Spinners",
            "Dropdown": "Desplegable",
            "Accordion": "Acordeón",
            "Tabs": "Pestañas",
            "Bootstrap Tabs": "Pestañas Bootstrap",
            "Line Tabs": "Pestañas de línea",
            "Shadow": "Sombra",
            "List": "Lista",
            "Bonus Ui": "Interfaz de usuario adicional",
            "Scrollable": "Desplazable",
            "Tree": "Árbol",
            "Rating": "Valoración",
            "Dropzone": "Zona de soltar",
            "Tour": "Tour",
            "SweetAlert": "SweetAlert",
            "Ribbons": "Cintas",
            "Pagination": "Paginación",
            "Breadcrumb": "Breadcrumb",
            "Range Slider": "Slider de rango",
            "Image Cropper": "Recortador de imágenes",
            "Sticky": "Pegajoso",
            "Drag and Drop": "Arrastrar y soltar",
            "Upload": "Subir",
            "Basic Card": "Tarjeta básica",
            "Draggable Card": "Tarjeta arrastrable",
            "Timeline": "Línea de tiempo",
            "Icons": "Iconos",
            "Flag Icon": "Icono de bandera",
            "Fontawesome Icon": "Icono de Fontawesome",
            "Ico Icon": "Icono Ico",
            "Themify Icon": "Icono de Themify",
            "Feather Icon": "Icono de Feather",
            "Whether Icon": "Icono de Whether",
            "Buttons": "Botones",
            "Default Style": "Estilo predeterminado",
            "Button Group": "Grupo de botones",
            "Charts": "Gráficos",
            "Apex Chart": "Gráfico Apex",
            "Google Chart": "Gráfico de Google",
            "Chartjs": "Chartjs",
            "Chartist": "Chartist",
            "Forms": "Formularios",
            "Form Controls": "Controles de formulario",
            "Form Validation": "Validación de formulario",
            "Basic Input": "Entrada básica",
            "Checkbox & Radio": "Checkbox y Radio",
            "Input Groups": "Grupos de entrada",
            "Mega Option": "Opción mega",
            "Form Widgets": "Widgets de formulario",
            "Datepicker": "Selector de fechas",
            "Typeahead": "Typeahead",
            "rangepicker": "rangepicker",
            "Touchspin": "Touchspin",
            "Select2": "Select2",
            "Switch": "Interruptor",
            "Clipboard": "Portapapeles",
            "Form Default": "Formulario predeterminado",
            "Form Wizard": "Asistente de formulario",
            "Tables": "Tablas",
            "Reactstrap Table": "Tabla Reactstrap",
            "Basic Table": "Tabla básica",
            "Sizing Table": "Tabla de tamaños",
            "Border Table": "Tabla de bordes",
            "Styling Table": "Estilización de tabla",
            "Data Tables": "Tablas de datos",
            "Others": "Otros",
            "Coming Soon": "Próximamente",
            "Coming Sample": "Muestra próxima",
            "Coming with Bg-video": "Próximamente con video de fondo",
            "Coming with Bg-Image": "Próximamente con imagen de fondo",
            "Authentication": "Autenticación",
            "Login Simple": "Inicio de sesión simple",
            "Login With bg image": "Inicio de sesión con imagen de fondo",
            "Login with image two": "Inicio de sesión con imagen dos",
            "Login with validation": "Inicio de sesión con validación",
            "Login with tooltip": "Inicio de sesión con tooltip",
            "Login with sweetaleart": "Inicio de sesión con sweetaleart",
            "Register Simple": "Registro simple",
            "Register with Bg image": "Registro con imagen de fondo",
            "Register with Bg Video": "Registro con vídeo de fondo",
            "Unlock User": "Desbloquear usuario",
            "Forgot Password": "Olvidé mi contraseña",
            "Create Passward": "Crear contraseña",
            "Maintenance": "Mantenimiento",
            "Gallery": "Galería",
            "Gallery Grid": "Cuadrícula de galería",
            "Gallery Grid  Desc": "Descripción de cuadrícula de galería",
            "Masonry Gallery": "Galería de albañilería",
            "Masonry With Desc": "Albañilería con descripción",
            "Hover Effect": "Efecto de hover",
            "Blog": "Blog",
            "Blog Details": "Detalles del blog",
            "Blog Single": "Entrada de blog individual",
            "Add Post": "Agregar entrada",
            "Job Search": "Búsqueda de empleo",
            "Cards View": "Vista de tarjetas",
            "List View": "Vista de lista",
            "Job Details": "Detalles del trabajo",
            "Apply": "Aplicar",
            "Learning": "Aprendizaje",
            "Learning List": "Lista de aprendizaje",
            "Detail Course": "Curso detallado",
            "Maps": "Mapas",
            "Google Maps": "Google Maps",
            "Editor": "Editor",
            "CK  Editor": "Editor CK",
            "MDE Editor": "Editor MDE",
            "ACE code editor": "Editor de código ACE",
            "FAQ": "FAQ",
            "knowledgebase": "Base de conocimientos",
            "KnowledgeCategory": "Categoría de conocimientos",
            "KnowledgeDetail": "Detalle de conocimientos",
            "sub": "sub",
            "Services Manager": "Gerente de servicios",
            "Services": "Servicios",
            "Category": "Categoría",
            "Department": "Departamento",
            "Employees": "Empleados",
            "Clients": "Clientes",
            "Rooms": "Habitaciones",
            "Orders": "Pedidos",
            "Settings": "Configuraciones",
            "Add new service": "Agregar nuevo servicio",
            "Image": "Imagen",
            "Name": "Nombre",
            "Description": "Descripción",
            "Products": "Productos",
            "Action": "Acción",
            "Sub Category": "Subcategoría",
            "Add New Category": "Agregar nueva categoría",
            "Add new department": "Agregar nuevo departamento",
            "Service name": "Nombre del servicio",
            "Save": "Guardar",
            "Price": "Precio",
            "Duration": "Duración",
            "Product name": "Nombre del producto",
            "Type": "Tipo",
            "Add": "Agregar",
            "Edit": "Editar",
            "Category Name": "Nombre de la categoría",
            "Parent Category": "Categoría principal",
            "Department Name": "Nombre del departamento",
            "Day": "Día",
            "Open time": "Hora de apertura",
            "Close time": "Hora de cierre",
            "24H open": "Abierto las 24 horas",
            "Monday": "Lunes",
            "Tuesday": "Martes",
            "Wednesday": "Miércoles",
            "Thursday": "Jueves",
            "Friday": "Viernes",
            "Saturday": "Sábado",
            "Sunday": "Domingo",
            "First Name": "Nombre",
            "Last Name": "Apellido",
            "Phone": "Teléfono",
            "Role": "Rol",
            "Email address": "Dirección de correo electrónico",
            "Phone number": "Número de teléfono",
            "Username": "Nombre de usuario",
            "Custom username": "Nombre de usuario personalizado",
            "Repeat password": "Repetir contraseña",
            "Password": "Contraseña",
            "Roles": "Roles",
            "Close": "Cerrar",
            "Unique Id": "ID único",
            "Please enter the unique Identifier": "Por favor, ingrese el identificador único",
            "Search": "Buscar",
            "Add with Scan": "Agregar con escaneo",
            "Send File": "Enviar archivo",
            "Update Affectation": "Actualizar asignación",
            "Status": "Estado",
            "Date": "Fecha",
            "Room Number": "Número de habitación",
            "Images": "Imágenes",
            "Number": "Número",
            "Establishment": "Establecimiento",
            "Contact": "Contacto",
            "Establishment name": "Nombre del establecimiento",
            "Region": "Región",
            "Price per night average": "Precio promedio por noche",
            "Select your localisation": "Seleccionar tu localización",
            "Permissions": "Permisos",
            "Owner": "Propietario",
            "Add Role": "Agregar rol",
            "Update Role": "Actualizar rol",
            "Update": "Actualizar",
            "Add Client": "Agregar cliente",
            "rooms": "habitaciones",
            "users": "usuarios",
            "employees": "empleados",
            "edit": "editar",
            "services-manager": "gerente-de-servicios",
            "services": "servicios",
            "new": "nuevo",
            "dashboard": "tablero",
            "category": "categoría",
            "department": "departamento",
            "Add Room": "Agregar habitación",
            "Copyright 2024 © Officent All rights reserved.": "Derechos de autor 2024 © Officent. Todos los derechos reservados.",
            "Hand crafted": "Hecho a mano",
            "made with": "hecho con",
            "orders": "órdenes"
        }
    },
    pt: {
      translations: {
          "Delete": "Excluir",
          "Add new employee": "Adicionar novo funcionário",
          "Add New Client": "Adicionar novo cliente",
          "Dashboard": "Painel de Controle",
          "Service": "Serviço",
          "Default": "Padrão",
          "Ecommerce": "Comércio Eletrônico",
          "Widgets": "Widgets",
          "General": "Geral",
          "Chart": "Gráfico",
          "Project": "Projeto",
          "Project List": "Lista de Projetos",
          "Create New": "Criar Novo",
          "Product": "Produto",
          "Product Page": "Página do Produto",
          "Product List": "Lista de Produtos",
          "Add Product": "Adicionar Produto",
          "Payment Detail": "Detalhes de Pagamento",
          "Order History": "Histórico de Pedidos",
          "Pricing": "Preços",
          "Invoice": "Fatura",
          "Cart": "Carrinho",
          "Checkout": "Finalizar Compra",
          "Whishlist": "Lista de Desejos",
          "Users": "Usuários",
          "Users Profile": "Perfil de Usuário",
          "Users Edit": "Editar Usuário",
          "Users Cards": "Cartões de Usuário",
          "Calender": "Calendário",
          "Chat": "Chat",
          "Chat App": "Aplicativo de Chat",
          "Video App": "Aplicativo de Vídeo",
          "Email": "E-mail",
          "Mail Inbox": "Caixa de Entrada",
          "Read Mail": "Ler E-mail",
          "Compose": "Escrever",
          "File Manager": "Gerenciador de Arquivos",
          "Kanban Board": "Quadro Kanban",
          "Bookmark": "Marcador",
          "Task": "Tarefa",
          "Social App": "Aplicativo Social",
          "Contacts": "Contatos",
          "To-Do": "Lista de Tarefas",
          "Ui-Kits": "UI Kits",
          "Typography": "Tipografia",
          "Avatars": "Avatares",
          "Helper-Classes": "Classes de Ajuda",
          "Grid": "Grade",
          "Tag & Pills": "Tag e Pílulas",
          "Progress": "Progresso",
          "Modal": "Modal",
          "Alert": "Alerta",
          "Popover": "Popover",
          "Tooltip": "Tooltip",
          "Spinners": "Spinners",
          "Dropdown": "Dropdown",
          "Accordion": "Acordeão",
          "Tabs": "Abas",
          "Bootstrap Tabs": "Abas Bootstrap",
          "Line Tabs": "Abas Line",
          "Shadow": "Sombra",
          "List": "Lista",
          "Bonus Ui": "UI Bônus",
          "Scrollable": "Rolável",
          "Tree": "Árvore",
          "Rating": "Avaliação",
          "Dropzone": "Dropzone",
          "Tour": "Tour",
          "SweetAlert": "SweetAlert",
          "Ribbons": "Fitas",
          "Pagination": "Paginação",
          "Breadcrumb": "Breadcrumb",
          "Range Slider": "Slider de Faixa",
          "Image Cropper": "Cortador de Imagem",
          "Sticky": "Fixo",
          "Drag and Drop": "Arrastar e Soltar",
          "Upload": "Carregar",
          "Basic Card": "Cartão Básico",
          "Draggable Card": "Cartão Arrastável",
          "Timeline": "Linha do Tempo",
          "Icons": "Ícones",
          "Flag Icon": "Ícone de Bandeira",
          "Fontawesome Icon": "Ícone Fontawesome",
          "Ico Icon": "Ícone Ico",
          "Themify Icon": "Ícone Themify",
          "Feather Icon": "Ícone Feather",
          "Whether Icon": "Ícone Whether",
          "Buttons": "Botões",
          "Default Style": "Estilo Padrão",
          "Button Group": "Grupo de Botões",
          "Charts": "Gráficos",
          "Apex Chart": "Gráfico Apex",
          "Google Chart": "Gráfico Google",
          "Chartjs": "Chartjs",
          "Chartist": "Chartist",
          "Forms": "Formulários",
          "Form Controls": "Controles de Formulário",
          "Form Validation": "Validação de Formulário",
          "Basic Input": "Entrada Básica",
          "Checkbox & Radio": "Checkbox e Radio",
          "Input Groups": "Grupos de Entrada",
          "Mega Option": "Opção Mega",
          "Form Widgets": "Widgets de Formulário",
          "Datepicker": "Seletor de Data",
          "Typeahead": "Typeahead",
          "rangepicker": "Seletor de Intervalo",
          "Touchspin": "Touchspin",
          "Select2": "Select2",
          "Switch": "Switch",
          "Clipboard": "Clipboard",
          "Form Default": "Formulário Padrão",
          "Form Wizard": "Assistente de Formulário",
          "Tables": "Tabelas",
          "Reactstrap Table": "Tabela Reactstrap",
          "Basic Table": "Tabela Básica",
          "Sizing Table": "Tabela de Dimensionamento",
          "Border Table": "Tabela de Borda",
          "Styling Table": "Estilizando Tabela",
          "Data Tables": "Tabelas de Dados",
          "Others": "Outros",
          "Coming Soon": "Em Breve",
          "Coming Sample": "Exemplo Chegando",
          "Coming with Bg-video": "Chegando com Vídeo de Fundo",
          "Coming with Bg-Image": "Chegando com Imagem de Fundo",
          "Authentication": "Autenticação",
          "Login Simple": "Login Simples",
          "Login With bg image": "Login com Imagem de Fundo",
          "Login with image two": "Login com Imagem Dois",
          "Login with validation": "Login com Validação",
          "Login with tooltip": "Login com Tooltip",
          "Login with sweetaleart": "Login com SweetAlert",
          "Register Simple": "Registro Simples",
          "Register with Bg image": "Registro com Imagem de Fundo",
          "Register with Bg Video": "Registro com Vídeo de Fundo",
          "Unlock User": "Desbloquear Usuário",
          "Forgot Password": "Esqueceu a Senha",
          "Create Passward": "Criar Senha",
          "Maintenance": "Manutenção",
          "Gallery": "Galeria",
          "Gallery Grid": "Grade de Galeria",
          "Gallery Grid Desc": "Descrição da Grade da Galeria",
          "Masonry Gallery": "Galeria de Alvenaria",
          "Masonry With Desc": "Alvenaria com Descrição",
          "Hover Effect": "Efeito de Hover",
          "Blog": "Blog",
          "Blog Details": "Detalhes do Blog",
          "Blog Single": "Postagem do Blog",
          "Add Post": "Adicionar Postagem",
          "Job Search": "Busca de Emprego",
          "Cards View": "Visualização de Cartões",
          "List View": "Visualização de Lista",
          "Job Details": "Detalhes do Trabalho",
          "Apply": "Aplicar",
          "Learning": "Aprendizado",
          "Learning List": "Lista de Aprendizado",
          "Detail Course": "Curso Detalhado",
          "Maps": "Mapas",
          "Google Maps": "Google Maps",
          "Editor": "Editor",
          "CK Editor": "CK Editor",
          "MDE Editor": "MDE Editor",
          "ACE code editor": "Editor de Código ACE",
          "FAQ": "FAQ",
          "Knowledgebase": "Base de Conhecimento",
          "KnowledgeCategory": "Categoria de Conhecimento",
          "KnowledgeDetail": "Detalhe do Conhecimento",
          "Sub": "Sub",
          "Services Manager": "Gerente de Serviços",
          "Services": "Serviços",
          "Category": "Categoria",
          "Department": "Departamento",
          "Employees": "Funcionários",
          "Clients": "Clientes",
          "Rooms": "Quartos",
          "Orders": "Pedidos",
          "Settings": "Configurações",
          "Add new service": "Adicionar novo serviço",
          "Image": "Imagem",
          "Name": "Nome",
          "Description": "Descrição",
          "Products": "Produtos",
          "Action": "Ação",
          "Sub Category": "Subcategoria",
          "Add New Category": "Adicionar Nova Categoria",
          "Add new department": "Adicionar novo departamento",
          "Service name": "Nome do Serviço",
          "Save": "Salvar",
          "Price": "Preço",
          "Duration": "Duração",
          "Product name": "Nome do Produto",
          "Type": "Tipo",
          "Add": "Adicionar",
          "Edit": "Editar",
          "Category Name": "Nome da Categoria",
          "Parent Category": "Categoria Pai",
          "Department Name": "Nome do Departamento",
          "Day": "Dia",
          "Open time": "Hora de Abertura",
          "Close time": "Hora de Fechamento",
          "24H open": "Aberto 24 horas",
          "Monday": "Segunda-feira",
          "Tuesday": "Terça-feira",
          "Wednesday": "Quarta-feira",
          "Thursday": "Quinta-feira",
          "Friday": "Sexta-feira",
          "Saturday": "Sábado",
          "Sunday": "Domingo",
          "First Name": "Nome",
          "Last Name": "Sobrenome",
          "Phone": "Telefone",
          "Role": "Função",
          "Email address": "Endereço de E-mail",
          "Phone number": "Número de Telefone",
          "Username": "Nome de Usuário",
          "Custom username": "Nome de Usuário Personalizado",
          "Repeat password": "Repetir Senha",
          "Password": "Senha",
          "Roles": "Funções",
          "Close": "Fechar",
          "Unique Id": "ID Único",
          "Please enter the unique Identifier": "Por favor, insira o identificador único",
          "Search": "Buscar",
          "Add with Scan": "Adicionar com Scan",
          "Send File": "Enviar Arquivo",
          "Update Affectation": "Atualizar Atribuição",
          "Status": "Status",
          "Date": "Data",
          "Room Number": "Número do Quarto",
          "Images": "Imagens",
          "Number": "Número",
          "Establishment": "Estabelecimento",
          "Contact": "Contato",
          "Establishment name": "Nome do Estabelecimento",
          "Region": "Região",
          "Price per night average": "Preço médio por noite",
          "Select your localisation": "Selecione sua localização",
          "Permissions": "Permissões",
          "Owner": "Proprietário",
          "Add Role": "Adicionar Função",
          "Update Role": "Atualizar Função",
          "Update": "Atualizar",
          "Add Client": "Adicionar Cliente",
          "rooms": "Quartos",
          "users": "Usuários",
          "employees": "Funcionários",
          "edit": "Editar",
          "services-manager": "Gerente de Serviços",
          "services": "Serviços",
          "new": "Novo",
          "dashboard": "Painel de Controle",
          "category": "Categoria",
          "department": "Departamento",
          "Add Room": "Adicionar Quarto",
          "Copyright 2024 © Officent All rights reserved.": "Copyright 2024 © Officent. Todos os direitos reservados.",
          "Hand crafted": "Feito à mão",
          "made with": "feito com",
          "orders": "pedidos"
      }
  },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
