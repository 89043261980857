import { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";

const Loader = () => {
  const [show, setShow] = useState(true);
  const [limit, setLimit] = useState(true);
  const authState = useSelector((state) => state.auth);

  useEffect(() => {
    const timeout1 = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeout1);
     
    };
  }, []); // Run effect only once on mount

  return (
    <Fragment>
      { (show || authState.loadingAffectationSelected ) && (
        <div className="loader-wrapper">
          <div className="theme-loader" />
        </div>
      )}
    </Fragment>
  );
};

export default Loader;
