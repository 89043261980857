import axiosInstance from "../../api/axios";

const GET_ALL_PRODUCTS = `${process.env.REACT_APP_BACK_URL}/api/v1/product`;
const GET_ALL_PRODUCTS_WITH_Pagination = `${process.env.REACT_APP_BACK_URL}/api/v1/product`;
const ADD_PRODUCT = `${process.env.REACT_APP_BACK_URL}/api/v1/product/addProduct`;
const UPDATE_PRODUCTS = `${process.env.REACT_APP_BACK_URL}/api/v1/product/update`;
const DELETE_PRODUCT = `${process.env.REACT_APP_BACK_URL}/api/v1/product/delete`;


export const getProducts = async (service, page, perPage) => {
  try {
    const response = await axiosInstance.post(`${GET_ALL_PRODUCTS_WITH_Pagination}?page=${page}&perPage=${perPage}`, {
       service
    });
  
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const addNewProduct = async (productData) => {
  try {
    const response = await axiosInstance.post(ADD_PRODUCT, productData);
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const updateProduct = async (productId, productData) => {
  console.log(
    "🚀 ~ file: serviceService.jsx:48 ~ updateService ~ serviceData:",
    productData
  );
  try {
    const response = await axiosInstance.post(
      `${UPDATE_PRODUCTS}/${productId}`,
      productData
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteProduct = async (productId) => {
  try {
    const response = await axiosInstance.delete(
      `${DELETE_PRODUCT}/${productId}`
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
