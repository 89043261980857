import React from "react";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import DepartmentList from "./departmentList";
const index = () => {
  return (
    <div className="page-body">
      <Breadcrumbs />
      <DepartmentList />
    </div>
  );
};

export default index;
