import React from "react";
import "./index.scss";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@fortawesome/free-solid-svg-icons";
import { Provider } from "react-redux";
import { store } from "./redux-toolkit/store";
import "./i18n";
import registerServiceWorker from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

registerServiceWorker();
reportWebVitals();
