import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createMeals, getAllEstablishmentMeals } from "../services/mealsService";

const initialState = {
  meals: [],
  loading: false,
  error: null,
};

export const createMealsAsync = createAsyncThunk(
  "meals/create",
  async (mealsData, { rejectWithValue }) => {
    console.log("🚀 ~ mealsData:", mealsData)
    try {
      const response = await createMeals(mealsData);
      console.log("🚀 ~ response:", response)
      return response.data; // Ensure this matches the structure of the response
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllMeals = createAsyncThunk(
  "meals/fetch",
  async (establishmentId) => {
    try {
      const response = await getAllEstablishmentMeals(establishmentId);
      return response.data; // Ensure this matches the structure of the response
    } catch (error) {
      throw error;
    }
  }
);

const mealsSlice = createSlice({
  name: "meals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllMeals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllMeals.fulfilled, (state, action) => {
      console.log("Fetched Meals:", action.payload); // Debug log
      state.meals = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllMeals.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(createMealsAsync.fulfilled, (state, action) => {
      // state.meals.push(action.payload?.data);
      state.loading = false;
      state.error = null;
    });
  },
});

export const mealsReducer = mealsSlice.reducer;
