import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createAffectationUser,
  // getAffectationByEstablishmentId,
  getAffectationByUserId,
  updateAffectationUser,
} from "../services/affectationService";

const initialStateAffectations = {
  affectations: [],
  loading: false,
  error: null,
};

export const getAffectationByUserIdAsync = createAsyncThunk(
  "affectations/fetchData",
  async (userId) => {
    try {
      const response = await getAffectationByUserId(userId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createAffectationUserAsync = createAsyncThunk(
  "users/create",
  async (
    { userId, establishmentId, expiredDate, rooms, status },
    { rejectWithValue }
  ) => {
    try {
      const response = await createAffectationUser(
        userId,
        establishmentId,
        expiredDate,
        rooms,
        status
      );
      console.log("response==<<<", response);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateAffectationUserAsync = createAsyncThunk(
  "affectation/update",
  async ({ id, affectationData }, { rejectWithValue }) => {
    try {
      const response = await updateAffectationUser(id, affectationData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
// export const getAffectationUserByEstablishmentId = createAsyncThunk(
//   "affectation/fetchData",
//   async (establishmentId) => {
//     try {
//       const response = await getAffectationByEstablishmentId(establishmentId);
//       console.log(
//         "🚀 ~ file: affectationSlice.jsx:30 ~ getAffectationUserByEstablishmentId ~ response:",
//         response
//       );
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

const affectationsSlice = createSlice({
  name: "affectations",
  initialState: initialStateAffectations,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAffectationByUserIdAsync.fulfilled, (state, action) => {
      state.affectations = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateAffectationUserAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
  },
});
export const affectationsReducer = affectationsSlice.reducer;
const { actions } = affectationsSlice;
