import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateEtablishementData, getEstablishment } from "../services/etablishementService";

const initialState = {
  etablishement: null,
  loading: false,
  error: null,
};

export const updateEtablishement = createAsyncThunk(
  "etablishement/update",
  async ({ data, id }) => {
    console.log(
      "🚀 ~ file: etablishementSlice.jsx:45 ~ etablishementData:",
      data,
      id
    );
    const response = await updateEtablishementData(data, id);
    try {
      // console.log("rep",response)
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);



const etablishementSlice = createSlice({
  name: "etablishement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const etablishementReducer = etablishementSlice.reducer;
export const { actions } = etablishementSlice;
