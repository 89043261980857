import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Col, Row, Label, InputGroup } from "reactstrap";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../Inputs/simpleInput";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { formatISO } from "date-fns";
import { FaEdit, FaPlus, FaBarcode, FaSave } from "react-icons/fa"; // Assuming you're using Font Awesome icons
import DatePicker from "react-datepicker";
import { createAffectationUserAsync } from "../../redux-toolkit/slices/affectationSlice";
import {
  fetchClients,
  getClientsFromAffectation,
} from "../../redux-toolkit/slices/clientSlice";
import { updateRoleUserAsync } from "../../redux-toolkit/slices/userSlice";
import { fetchRoomsAsync } from "../../redux-toolkit/slices/roomsSlice";
import { Btn } from "../../AbstractElements";

const schema = yup.object().shape({
  rooms: yup.array().required("You must enter at least one room"),
  // status: yup.string().required("You must select a status"),
  selectedDate: yup.date().required("You must select a date"),
});

const CreateAffectation = ({
  clientCreated,
  goSteps,
  setGoSteps,
  setClientCreated,
  setIsOpen,
  setAddNewClient,
  setAddAffectation,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  const [selectedDate, setSelectedDate] = useState("");
  const [selecteDate, setDate] = useState("");
  const roomsData = useSelector((state) => state.rooms.roomsData.data);
  const [inactiveRooms, setInactiveRooms] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { isValid, dirtyFields, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  // pour réinitialiser le formulaire à chaque fermeture/ouverture de Model
  // useEffect(() => {
  //   if (!isOpen) reset();
  // }, [isOpen]);

  // pour formater la date dans un format compatible avec le backend
  useEffect(() => {
    if (selectedDate !== "") {
      setDate(formatISO(selectedDate));
    }
  }, [selectedDate]);

  console.log("Date===>", Date);

  useEffect(() => {
    const fetchData = async () => {
      if (affectationSelectedData.establishments?._id) {
        const rooms = await dispatch(
          fetchRoomsAsync({
            establishmentId: affectationSelectedData.establishments?._id,
            page: 1,
            perPage: "all",
          })
        );
        console.log("rooms==>>", rooms);
        if (rooms) {
          setInactiveRooms(
            rooms.payload?.data?.filter((room) => room.status === "available")
          );
        }
      }
    };
    fetchData();
  }, [dispatch, affectationSelectedData]);

  async function onSubmit(data) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    if (affectationSelectedData) {
      const selectedDateObj = new Date(selecteDate);
      if (selectedDateObj < now ) {
        toast.warn("The date chosen is less than the current date!");
        return; // Exit the function early if the date is invalid
      }
      try {
        const transformedData = {
          userId: clientCreated._id,
          establishmentId: affectationSelectedData?.establishments?._id,
          expiredDate: selecteDate,
          rooms: data.rooms,
          status: "active",
        };
        const AffectationCreated = await dispatch(
          createAffectationUserAsync(transformedData)
        );

        if (createAffectationUserAsync.fulfilled.match(AffectationCreated)) {
          if (clientCreated?.role !== "client") {
            const updateUserRole = {
              userId: clientCreated._id,
              role: "client",
            };
            const updateRoleUser = await dispatch(
              updateRoleUserAsync(updateUserRole)
            );

            if (updateRoleUserAsync.fulfilled.match(updateRoleUser)) {
              toast.success("Affectation created succesfully !");
            }
          } else {
            toast.success("Affectation created succesfully !");
          }
          await dispatch(
            fetchClients({
              establishmentId: affectationSelectedData?.establishments._id,
              page: 1,
              perPage: 10,
            })
          );
        } else {
          toast.error(AffectationCreated.payload);
        }
        setIsOpen(false);
        setClientCreated(null);
        setAddAffectation(false);
        setGoSteps(0);
        setAddNewClient(false);
      } catch (error) {
        toast.error("Error creating affectation !");
      }
    }
  }
  return (
    <>
      <Row>
        {inactiveRooms && (
          <Col lg="6" sm="12" className="m-b-15">
            <Label>Rooms</Label>
            <Controller
              name="rooms"
              control={control}
              render={({ field }) => (
                <InputGroup>
                  <div className="w-100">
                    <Select
                      options={roomsData
                        ?.filter((room) => room.status === "available")
                        .map((e) => ({
                          label: e.roomNumber,
                          value: e._id,
                        }))}
                      isMulti
                      onChange={(newValue) => {
                        const selectedValues = newValue.map(
                          (option) => option.value
                        );
                        console.log("selectedValues", selectedValues);
                        setValue("rooms", selectedValues);
                      }}
                      // {...field}
                    />
                  </div>
                  {errors.rooms && (
                    <div className="w-100">
                      <span className="error-msg-input">
                        {errors.rooms.message}
                      </span>
                    </div>
                  )}
                </InputGroup>
              )}
            />
          </Col>
        )}

        <Col
          lg="6"
          sm="12"
          className="d-flex"
          style={{
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "flex-start",
          }}
        >
          <Label>Date</Label>
          <DatePicker
            name="selectedDate"
            className="form-control digits"
            showPopperArrow={false}
            selected={selectedDate}
            showTimeSelect
            dateFormat="yyyy-MM-dd"
            onChange={(date) => {
              console.log("daaaate", date);
              setSelectedDate(date);
              setValue("selectedDate", date);
            }}
          />
          {errors.selectedDate && (
            <div className="w-100">
              <span className="error-msg-input">
                {errors.selectedDate.message}
              </span>
            </div>
          )}
        </Col>
      </Row>
      <Row className="d-flex justify-content-between align-items-center">
        {/* {goSteps ? (
          <Col>
            <Btn color="primary" onClick={() => setGoSteps(goSteps - 1)}>
              previous
            </Btn>
          </Col>
        ) : (
          " "
        )} */}
        <Col>
          <Button
            variant="success"
            className=" pull-right "
            style={{ marginLeft: "10px" }}
            onClick={handleSubmit(onSubmit)}
          >
            <FaSave className="mr-2" /> Save{" "}
          </Button>{" "}
        </Col>
      </Row>
    </>
  );
};

export default CreateAffectation;
