import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllRole,
  addRole,
  deleteRole,
  updateRole,
} from "../services/roleService";

const initialStateRole = {
  roles: [],
  loading: false,
  loadingAddRole: false,
  error: null,
};

export const fetchRole = createAsyncThunk(
  "role/fetchData",
  async ({ establishementId, page, limit }) => {
    try {
      const response = await getAllRole(establishementId, page, limit);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addNewRole = createAsyncThunk("role/addNewRole", async (data) => {
  console.log("data disapatched", data);
  try {
    const response = await addRole(data);
    return response.data;
  } catch (error) {
    throw error;
  }
});

export const updateOneRole = createAsyncThunk(
  "role/updateRole",
  async ({ id, data }) => {
    console.log("data disapatched id ", id);
    console.log("data disapatched data", data);

    try {
      const response = await updateRole(id, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteOneRole = createAsyncThunk(
  "role/deleteOneRole",
  async (data) => {
    console.log("data disapatched", data);
    try {
      const response = await deleteRole(data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const roleSlice = createSlice({
  name: "role",
  initialState: initialStateRole,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRole.fulfilled, (state, action) => {
      state.roles = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addNewRole.fulfilled, (state, action) => {
      // state.roles = action.payload;
      state.loadingAddRole = false;
      state.error = null;
    });
    builder.addCase(addNewRole.pending, (state, action) => {
      state.loadingAddRole = true;
      state.error = null;
    });
    builder.addCase(addNewRole.rejected, (state, action) => {
      state.loadingAddRole = false;
      state.error = action.error;
    });
    builder.addCase(deleteOneRole.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteOneRole.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteOneRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(updateOneRole.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateOneRole.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateOneRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const roleReducer = roleSlice.reducer;
const { actions } = roleSlice;
