import { useState, useCallback, useMemo } from "react";
import differenceBy from "lodash/differenceBy";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Btn } from "../../../../AbstractElements";

import HeadingCommon from "../../../../Common/HeadingCommon";
import { DataTableHeading } from "../../../../Constant";

const tableData = [
  {
    id: "1",
    name: "Product Menu",
    status: <i className="fa fa-circle font-success f-12" />,
    creat_on: "2023-04-18T00:00:00",
  },
  {
    id: "2",
    name: "Category Menu",
    status: <i className="fa fa-circle font-warning f-12" />,
    creat_on: "2023-04-18T00:00:00",
  },
  {
    id: "3",
    name: "Subcategory Menu",
    status: <i className="fa fa-circle font-success f-12" />,
    creat_on: "2023-04-18T00:00:00",
  },
  {
    id: "4",
    name: "Sales  Menu",
    status: <i className="fa fa-circle font-danger f-12" />,
    creat_on: "2023-04-18T00:00:00",
  },
  {
    id: "5",
    name: "Vendor Menu",
    status: <i className="fa fa-circle font-success f-12" />,
    creat_on: "2023-04-18T00:00:00",
  },
  {
    id: "6",
    name: "Category Menu",
    status: <i className="fa fa-circle font-warning f-12" />,
    creat_on: "2023-04-18T00:00:00",
  },
];

const WorkingHours = ({ dataWorkinghours }) => {
  console.log(
    "🚀 ~ file: workingHours.jsx:51 ~ WorkingHours ~ dataWorkinghours:",
    dataWorkinghours
  );
  const [data, setData] = useState(dataWorkinghours);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete:\r ${selectedRows.map(
            (r) => r.name
          )}?`
        )
      ) {
        setToggleCleared(!toggleCleared);
        setData(differenceBy(data, selectedRows, "name"));
        toast.success("Successfully Deleted !");
      }
    };

    return (
      <Btn color="danger" onClick={handleDelete} key="delete">
        Delete
      </Btn>
    );
  }, [data, selectedRows, toggleCleared]);

  const tableColumns = [
    {
      name: "Day",
      selector: (Row) => Row["day"],
      sortable: true,
      center: true,
    },
    {
      name: "Open time",
      selector: (Row) => Row["openTime"],
      sortable: true,
      center: true,
    },
    {
      name: "Close time",
      selector: (Row) => Row["closeTime"],
      sortable: true,
      center: true,
    },
    {
      name: "Open 24H",
      selector: (row) => (row["isOpen24Hours"] ? "Yes" : "No"),
      sortable: true,
      center: true,
    },
  ];

  return (
    <Container fluid={true} className="data-tables">
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              {dataWorkinghours && (
                <DataTable
                  data={dataWorkinghours}
                  columns={tableColumns}
                  striped={true}
                  persistTableHead
                  // contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WorkingHours;
