import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Edit, Trash } from "react-feather";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { dynamicImage } from "../../Service";
import { Image } from "../../AbstractElements";
import TableComp from "../dataTable/TableComp";
import { deleteProductAsync, fetchProducts } from "../../redux-toolkit/slices/productSlice";
import { usePermission } from "../../Service/usePermission";

const ProductList = ({ setSelectedProduct, editProductInNewService, page, perPage, setPage, setPerPage, permissions }) => {


/*****************Start hooks *******************/  
  //Start useSelector
  const productsData = useSelector((state) => state.products.productsData);
  //End useSelector

  //useDispatch
  const dispatch = useDispatch();

  //useLocation
  const location = useLocation();

  //useTranslation
  const { t } = useTranslation();

  //useParams
   const routeParams = useParams();
   console.log("routeParams", routeParams)

  //Start useEffect
   useEffect(() => {
     const fetchData = async () => {
       try {       
           const res = await dispatch(fetchProducts({ service: routeParams.id, page, perPage }));
       } catch (error) {
         console.error("Error fetching products:", error);
       }
     };
   
     fetchData();
   }, [dispatch, page, perPage, editProductInNewService, routeParams]);
   
  //End useEffect

 /*****************End hooks *******************/  
  
 /***************** Start constants and variables *******************/
 const pathname = location.pathname.split("/");

 const ProductsTableColumns = [
  {
    name: t("Image"),
    selector: (row) => (
      <Image
      src={row.images[0] ? process.env.REACT_APP_MINIO_URL + row.images[0] : dynamicImage("ecommerce/options.png")}
      style={{width:"70px",padding:"10px"}}
      />
    ),
    sortable: true,
    center: true,
  },
  {
    name: t("Name"),
    selector: (row) => row["name"],
    sortable: true,
    center: true,
  },
  {
    name: t("Description"),
    selector: (row) => row["description"],
    sortable: true,
    center: true,
  },
  {
    name: t("Price"),
    selector: (row) => row?.price,
    sortable: true,
    center: true,
  },
  {
    name: t("Duration"),
    selector: (row) => row?.duration,
    sortable: true,
    center: true,
  },
  {
    name: t("Action"),
    selector: (row) => (
      <div>
        <a
          className="btn-xs"
          // onClick={() => handelOpenToView(row)}
          style={{ cursor: "pointer" }}
        > 
          <Trash width={20} color='red'onClick={usePermission('dynamic',  routeParams?.id , 'delete') ? ()=>handelDeleteProduct(row?._id) : null} 
            style={{ 
              cursor: usePermission('dynamic',  routeParams?.id , '!delete') ? 'not-allowed' : 'pointer', 
              opacity: usePermission('dynamic',  routeParams?.id , '!delete')  ? 0.5 : 1 
            }}
          />
        </a>
        <a
          
          className="btn-xs"
          // onClick={() => handelOpenToView(row)}
          style={{ cursor: "pointer" }}
        >
          <Edit onClick={() => handelSelectProduct(row)} />
        </a>
      </div>
    ),
    sortable: false,
    center: true,
  },
];

  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };
/***************** End constants and variables *******************/
 
/*****************Start Functions and handlers *******************/ 
const handlePageChange = (newPage) => {
  setPage(newPage);
};

const handlePerRowsChange = async (newPerPage, newPage) => {
  setPerPage(newPerPage);
  setPage(newPage);
};

const handelSelectProduct = (row) => {
  setSelectedProduct(row);
};

const handelDeleteProduct = async (row) => {
  console.log("deletedProduct=",row);
    try {
      const response = await dispatch(
        deleteProductAsync(row)
      );
      if (response?.message) {
        toast.error(response.message);
      } else {
          dispatch( fetchProducts({ service:routeParams.id, page, perPage }))
        
        toast.success("Product deleted successfully!");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the Product.");
    }
  
};


/*****************End Functions and handlers *******************/   
 
  // console.log("ttttttttttttttttttttttttttt", productsData.data)
  return (
    <div>
      <TableComp
        data={ productsData.data}
        columns={ProductsTableColumns}
        toggleDelete={false}
        expandableRows={false}
        highlightOnHover={true}
        fixedHeader={true}
        customStyles={customStyles}
        onChangePage={handlePageChange} // Add page change handler
        onChangeRowsPerPage={handlePerRowsChange} // Add per page change handler
        pagination // Enable pagination
        paginationServer // Indicate that pagination is server-side
        paginationTotalRows={productsData.totalProducts} // Total rows for pagination
      />
    </div>
  );
};

export default ProductList;
