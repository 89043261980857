import React, { useState, useEffect } from "react";
import {
  Col,
  CardBody,
  Row,
  Label,
  Input,
  CardFooter,
  InputGroup,
  Table,
} from "reactstrap";
import { Button } from "reactstrap";
import Select from "react-select";

import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import UploadDropzone from "../../Components/Dropzone/UploadDropzone";
import SimpleInput from "../../Components/Inputs/simpleInput";
import {
  addRoom,
  fetchRoomsAsync,
  updateRooms,
} from "../../redux-toolkit/slices/roomsSlice";
import { useTranslation } from "react-i18next";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const accommodationOptions = [
  { label: "1 person", value: "1 person" },
  { label: "2 person", value: "2 person" },
  { label: "3 person", value: "3 person" },
  { label: "4 person", value: "4 person" },
];

const RoomsForm = () => {
  const dispatch = useDispatch();
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  const categoryParentData = useSelector((state) => state.category.parents);
  const routeParams = useParams();
  const { t } = useTranslation();

  const [activeRows, setActiveRows] = useState({
    "1 person": true,
    "2 person": false,
    "3 person": false,
    "4 person": false,
  });

  const navigate = useNavigate();
  const [prices, setPrices] = useState({});
  let location = useLocation();
  const pathname = location.pathname.split("/");
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );
  const status = [
    { label: "available", value: "available" },
    { label: "unavailable", value: "unavailable" },
  ];
  const schema = yup.object().shape({
    description: yup.string().required("You must enter a room description"),
    number: yup.string().required("You must enter a room Id"),
  });
  const handleCheckboxChange = (accommodation) => {
    setActiveRows((prev) => ({
      ...prev,
      [accommodation]: !prev[accommodation],
    }));
  };
  const handlePriceChange = (day, accommodation, value) => {
    setPrices((prevPrices) => ({
      ...prevPrices,
      [`${day}-${accommodation}`]: value,
    }));
  };

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { isValid, dirtyFields, errors },
    setValue,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const {
    fields: priceFields,
    append: appendPrice,
    remove: removePrice,
  } = useFieldArray({
    control,
    name: "pricePerDay",
  });
  useEffect(() => {
    if (location?.state) {
      console.log("Resetting form with:", location.state); // Log values for debugging
      reset({
        description: location.state.description || "",
        number: location.state.roomNumber || "",
        pricePerDay: location.state.pricePerDay || [],
        roomType: location.state.roomType || "",
        roomView: location.state.roomView || "",
        status: location.state.status || "",
        images: location.state.images || [],
      });
      const activeRows = {
        "1 person": location.state.pricePerDay.some(
          (p) => p.accommodation === "1 person"
        ),
        "2 person": location.state.pricePerDay.some(
          (p) => p.accommodation === "2 person"
        ),
        "3 person": location.state.pricePerDay.some(
          (p) => p.accommodation === "3 person"
        ),
        "4 person": location.state.pricePerDay.some(
          (p) => p.accommodation === "4 person"
        ),
      };
      setActiveRows(activeRows);
      setPrices(
        location.state.pricePerDay?.reduce(
          (acc, { day, accommodation, price }) => {
            acc[`${day}-${accommodation}`] = price;
            return acc;
          },
          {}
        )
      );
    } else {
      setActiveRows({
        "1 person": true,
        "2 person": false,
        "3 person": false,
        "4 person": false,
      });
    }
  }, [location, reset]);
  const getUploadParams = (body) => {
    return { url: "https://httpbin.org/post" };
  };

  const handleSubmitFile = ({ meta, file, remove }, status) => {
    setValue("images", [file]);
    if (status === "headers_received") {
      toast.success(`${meta.name} uploaded!`);
    } else if (status === "aborted") {
      toast(`${meta.name}, upload failed...`);
    } else if (status === "removed") {
      resetField("images");
    }
  };
  async function onSubmit(data) {
    const formData = new FormData();
    const { description, status, number, roomType, roomView } = data;

    // Append room details
    formData.append("description", description);
    formData.append("status", status?.value || data.status);
    formData.append("roomNumber", number);
    formData.append("roomType", roomType?.value || data.roomType); // Ensure roomType is a value from options
    formData.append("roomView", roomView);
    formData.append("establishment", affectationSelected?.establishments._id);

    // Convert prices state to the required format for pricePerDay
    const pricePerDayData = Object.entries(prices)
      .filter(([key, value]) => {
        const [, accommodation] = key.split("-");
        return activeRows[accommodation]; // Only include checked accommodations
      })
      .map(([key, value]) => {
        const [day, accommodation] = key.split("-");
        return {
          day,
          accommodation,
          price: parseFloat(value) || 0,
        };
      });
    console.log("pricePerDayData===>", pricePerDayData);

    // Append pricePerDay as JSON
    formData.append("pricePerDay", JSON.stringify(pricePerDayData));

    // Append images
    for (let i = 0; i < data?.images?.length; i++) {
      formData.append("images", data.images[i]);
    }

    // Log FormData entries for debugging
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    try {
      if (pathname.includes("edit")) {
        // Assume you have roomId available in your component's state or props
        const roomId = location.state._id;

        // Update the room
        await dispatch(updateRooms({ id: roomId, roomData: formData }));
        toast.success("Room updated successfully.");
      } else {
        // Create a new room
        await dispatch(addRoom(formData));
        navigate(`/rooms`);
        toast.success("Room created successfully.");

        dispatch(
          fetchRoomsAsync({
            establishmentId: affectationSelected?.establishments._id,
            page: 1,
            perPage: 10,
          })
        );
      }
    } catch (error) {
      toast.error("Error saving room.");
      console.error("Error saving room:", error);
    }
  }

  //   async function onSubmit(data) {
  //     const formData = new FormData();
  //     const { description, status, number, roomType, roomView } = data;

  //     // Append room details
  //     formData.append("description", description);
  //     formData.append("status", status?.value);
  //     formData.append("roomNumber", number);
  //     formData.append("roomType", roomType?.value); // Ensure roomType is a value from options
  //     formData.append("roomView", roomView);
  //     formData.append("establishment", affectationSelected?.establishments._id);

  //     // Convert prices state to the required format for pricePerDay
  //     const pricePerDayData = Object.entries(prices).map(([key, value]) => {
  //       const [day, accommodation] = key.split("-");
  //       return {
  //         day,
  //         accommodation,
  //         currency: currency,
  //         price: parseFloat(value) || 0, // Convert price to number
  //       };
  //     });

  //     // Append pricePerDay as JSON
  //     formData.append("pricePerDay", JSON.stringify(pricePerDayData));
  //     for (let i = 0; i < data?.images?.length; i++) {
  //       formData.append("images", data.images[i]);
  //     }
  //     // Handle images

  //     //   await dispatch(addRoom(formData));

  //     try {

  //       await dispatch(addRoom(formData));
  //       navigate(`/rooms`);
  //       toast.success("room creating successfuly.");

  //       dispatch(
  //         fetchRoomsAsync({
  //           establishmentId: affectationSelected?.establishments._id,
  //           page: 1,
  //           perPage: 10,
  //         })
  //       );
  //     } catch (error) {
  //       toast.error("Error creating room.");
  //       console.error("Error creating room:", error);
  //     }

  //   }
  return (
    <div className="page-body">
      <Breadcrumbs />
      <Col xl="12">
        <form className="card">
          <CardBody>
            <Row>
              <Col xl="3">
                <Controller
                  name="images"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <UploadDropzone
                      getUploadParams={getUploadParams}
                      handleChangeStatus={handleSubmitFile}
                    />
                  )}
                />
              </Col>
              <Col xl="9">
                <Row>
                  <Col md="12">
                    <Label>Description</Label>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input" // change to lowercase
                          className="form-control"
                          placeholder="Description"
                          errors={errors.name?.message}
                        />
                      )}
                    />
                  </Col>

                  <Col lg="12" className="p-t-15">
                    <Label>{t("Status")}</Label>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={status}
                          {...field}
                          value={
                            field.value
                              ? status.find(
                                  (option) => option.value === field.value
                                )
                              : null
                          }
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption?.value); // Ensure correct value format
                          }}
                        />
                      )}
                    />
                  </Col>
                  <Col xl="12">
                    <Row>
                      <Col md="12">
                        {/* <Label>Product name</Label> */}
                        <Label>Room ID</Label>

                        <Controller
                          name="number"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <InputGroup>
                              {/* <InputGroupText>{"0.00"}</InputGroupText> */}

                              <Input
                                {...field}
                                type="text"
                                errclassname="error-msg-input" // change to lowercase
                                className="form-control"
                                placeholder="number"
                                aria-label="Room ID"
                                errors={errors.name?.message}
                              />
                            </InputGroup>
                          )}
                        />
                      </Col>
                      {/* <Col md="6">
                                                <Label>Duration</Label>
                                                <Controller
                                                    name="duration"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Input
                                                            className="digits"
                                                            type="time"
                                                            defaultValue="08:00:00"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Col> */}
                    </Row>
                  </Col>
                  <Col lg="12" className="p-t-15">
                    <Label>{t("Room Type")}</Label>
                    <Controller
                      name="roomType"
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={[
                            { label: "Standard", value: "standard" },
                            { label: "Double", value: "double" },
                            { label: "Triple", value: "triple" },
                            { label: "Quad", value: "quad" },
                          ]}
                          {...field}
                          value={
                            field.value
                              ? [{ label: field.value, value: field.value }]
                              : null
                          }
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption?.value); // Ensure correct value format
                          }}
                        />
                      )}
                    />
                  </Col>

                  <Col lg="12" className="p-t-15">
                    <Label>{t("Room View")}</Label>
                    <Controller
                      name="roomView"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="text"
                          className="form-control"
                          placeholder={t("Room View")}
                        />
                      )}
                    />
                  </Col>

                  <Col xl="12" className="p-t-15">
                    <Label>{t("Price Per Day")}</Label>
                    <Table bordered>
                      <thead>
                        <tr>
                          <th></th>
                          {daysOfWeek.map((day) => (
                            <th key={day}>{day}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {accommodationOptions.map((option) => (
                          <tr key={option.value}>
                            <td>
                              <Input
                                type="checkbox"
                                checked={activeRows[option.value]}
                                style={{ marginRight: "5px" }}
                                onChange={() =>
                                  handleCheckboxChange(option.value)
                                }
                              />
                              {option.label}
                            </td>
                            {daysOfWeek.map((day) => (
                              <td key={`${option.value}-${day}`}>
                                <Input
                                  type="number"
                                  value={prices[`${day}-${option.value}`] || ""}
                                  onChange={(e) =>
                                    handlePriceChange(
                                      day,
                                      option.value,
                                      e.target.value
                                    )
                                  }
                                  disabled={!activeRows[option.value]}
                                />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-end">
            <Button onClick={handleSubmit(onSubmit)} color="secondary">
              Save
            </Button>
          </CardFooter>
        </form>
      </Col>
    </div>
  );
};

export default RoomsForm;
