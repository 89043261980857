import React from "react";
import Offcanvas from "../../../../CommonElements/Offcanvas";
import PrimaryColorAccordian from "./collapseDepartement";
import WorkingHours from "./workingHours";

const DepartmentView = ({ isOpen, toggleClose, data }) => {
  console.log("data=======>", data);
  return (
    <Offcanvas
      isOpen={isOpen}
      toggleClose={toggleClose}
      titleSideBar={data?.name}
      style={{ width: "50%" }}
    >
      <h4>Service ({data?.services?.length})</h4>
      <div style={{ width: "100%", paddingTop: "20px" }}>
        <PrimaryColorAccordian data={data?.services} />
      </div>
      <h4>Working hours</h4>
      <div style={{ width: "100%", paddingTop: "20px" }}>
        <WorkingHours dataWorkinghours={data?.workingHours} />
      </div>
    </Offcanvas>
  );
};

export default DepartmentView;
