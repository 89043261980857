import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './Swiper.css';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { FaTrash } from 'react-icons/fa';
import { Modal, ModalBody } from 'reactstrap';
import './Swiper.css'; 

export default function Swipper({ oldImages,setOldImages, dispatch, canDelete }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const baseUrl = process.env.REACT_APP_MINIO_URL;

  const handleDelete = (index) => {
    setOldImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const toggleModal = (image) => {
    setCurrentImage(image);
    setModalOpen(!modalOpen);
  };

  return (
    <div className='w-85 h-75'>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {oldImages?.map((image, index) => {
          if (image === "") {
            return null;
          }
          return (
            <SwiperSlide key={index} >
              <div className="image-container">
             { canDelete == false ? null : <FaTrash 
                  className="delete-icon text-danger"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDelete(index)}
                /> }
                <img
                  src={`${baseUrl}${image}`}
                  alt={`Slide ${index}`}
                  onClick={() => toggleModal(`${baseUrl}${image}`)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {oldImages?.map((image, index) => {
          if (image === "") {
            return null;
          }
          return (
            <SwiperSlide key={index}>
              <img src={`${baseUrl}${image}`} alt={`Thumbnail ${index}`} />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Modal isOpen={modalOpen} toggle={() => toggleModal('')} className="modal-full-height d-flex justify-content-center align-items-center  w-100 h-100">
        <ModalBody className="p-0 d-flex justify-content-center align-items-center w-100 h-100 ">
          <img src={currentImage} alt="Large view" className=" p-0 img-fluid d-block" style={{ maxWidth: '90vw', maxHeight: '90vh' }} />
        </ModalBody>
      </Modal>
    </div>
  );
}
