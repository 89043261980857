export function extractServicesWithExecutePermission(roles) {
    const services = roles?.flatMap((elem) => {
      return elem.permissions?.map((permission) => {
        if (permission.execute === true && permission.service !== null) return permission.service;
        return null;
      }).filter(Boolean); // Filter out null values
    });
  
    // Use a Set to remove duplicates and convert it back to an array
    const uniqueServices = [...new Set(services)];
  
    return uniqueServices;
  }