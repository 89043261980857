import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getEmployee,
  createEmployee,
  deleteEmployee,
  getEmployeeById,
  updateEmployeeById,
  createAffectationAdmin,
  getEmployeeWithRoles,
  updateAffectationAdmin,
  deleteAffectationAdmin,
  updateEmployeePassword
} from "../services/employeeService";
import { toast } from "react-toastify";

const initialState = {
  employee: [],
  adminAffectaionsData: [],
  loading: false,
  error: null,
};

export const fetchEmployee = createAsyncThunk(
  "employee/fetchData",
  async () => {
    try {
      const response = await getEmployee();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

//Get Employees with roles 
export const fetchEmployeeWithRoles = createAsyncThunk(
  "employeeWithRoles/fetchData",
  async ({establishmentId, page, perPage}, { rejectWithValue }) => {
    try {
      const response = await getEmployeeWithRoles(establishmentId, page, perPage);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//Update Affectation Admin
export const updateAffectationAdminn = createAsyncThunk(
  "employee/affectationAdminUpdate",
  async ({affectationId, updatedAffectationAdminData}, { rejectWithValue }) => {
    try {
      const response = await updateAffectationAdmin({ affectationId, updatedAffectationAdminData });
      console.log("llll",response)
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//Delete Affectation Admin
export const deleteAffectationAdminn = createAsyncThunk(
  "employee/affectationAdminDelete",
  async ( affectationId, { rejectWithValue } ) => {
    try {
      const response = await deleteAffectationAdmin( affectationId );
      return {response, affectationId};
    } catch (error) {
      
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createAffectationAdminAsync = createAsyncThunk(
  "employee/createAffectation",
  async ({ adminId, establishmentId, roleIds }, { rejectWithValue }) => {
    try {
      const response = await createAffectationAdmin(
        adminId,
        establishmentId,
        roleIds
      );
      console.log("repppponse",response.affectation)
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const createEmployeeAsync = createAsyncThunk(
  "employee/create",
  async (employeeData,  { rejectWithValue }) => {
    try {
      const response = await createEmployee(employeeData);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.error);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteEmployeeAsync = createAsyncThunk(
  "employee/delete",
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await deleteEmployee(employeeId);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getEmployeeByIdAsync = createAsyncThunk(
  "employee/getOne",
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await getEmployeeById(employeeId);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateEmployeeAsync = createAsyncThunk(
  "employee/update",
  async ( {employeeId, updatedAdminData}, { rejectWithValue } ) => {
    try {
      console.log("emmmmpSlice",employeeId,updatedAdminData)
      const response = await updateEmployeeById({employeeId, updatedAdminData});
      console.log(
        "🚀 ~ file: employeeSlice.jsx:69 ~ employeeId, updatedAdminData:",
        employeeId,
        updatedAdminData
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//update Admin password 
export const updateAdminPasswordAsync = createAsyncThunk(
  "employee/updatePassword",
  async ( {employeeId, updatedAdminPasswordData}, { rejectWithValue } ) => {
    try {
      console.log("emmmmpSlice",employeeId,updatedAdminPasswordData)
      const response = await updateEmployeePassword({employeeId, updatedAdminPasswordData});
      console.log(
        "🚀 ~ file: employeeSlice.jsx:69 ~ employeeId, updatedAdminData:",
        employeeId,
        updatedAdminPasswordData
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);



const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: { 
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmployee.fulfilled, (state, action) => {
      state.employee = action.payload?.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(createEmployeeAsync.fulfilled, (state, action) => {
      state.employee.push(action.payload?.data);
      state.loading = false;
      state.error = null;
    });
    builder.addCase(createAffectationAdminAsync.fulfilled, (state, action) => {
      state.adminAffectaionsData.data.push(action.payload.affectation)
      state.adminAffectaionsData.totalData++;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(createAffectationAdminAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(fetchEmployeeWithRoles.fulfilled, (state, action) => {
      state.adminAffectaionsData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchEmployeeWithRoles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(updateAffectationAdminn.fulfilled, (state, action) => {
      state.adminAffectaionsData.data = state.adminAffectaionsData.data.map(emp => 
        emp._id === action.payload.affectation._id ? { ...emp, ...action.payload.affectation } : emp
      );
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateAffectationAdminn.rejected, (state, action) => {
      console.error("Error:", action);
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteAffectationAdminn.fulfilled, (state, action) => {
      console.log("acctttt", action.payload)
      state.adminAffectaionsData.data = state.adminAffectaionsData.data.filter(aff => 
        aff._id !== action.payload.affectationId 
      );
      state.adminAffectaionsData.totalData --;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteAffectationAdminn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(updateAdminPasswordAsync.fulfilled, (state, action) => {
      console.log(action,"action")
        state.loading = false;
        state.error = null;
    });
    builder.addCase(updateAdminPasswordAsync.rejected, (state, action) => {
      console.log(action,"action err")
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const employeeReducer = employeeSlice.reducer;
export const { actions } = employeeSlice;
