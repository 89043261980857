export let FirmActivityDatas = [
  {
    imgSrc: "2.png",
    Tittle: "Jason Borne",
    Text: "uploaded this file",
    Time: "Just Now",
  },
  {
    imgSrc: "1.jpg",
    Tittle: "Vincent Porter",
    Text: " created this new case",
    Time: "10:00 AM Today",
  },
  {
    imgSrc: "3.jpg",
    Tittle: "Erica Hughes",
    Text: "created an event",
    Time: "4:00 PM Yesterday",
  },
  {
    imgSrc: "4.jpg",
    Tittle: "Hileri Jecno",
    Text: "completed this task",
    Time: "2:30 PM Yesterday",
  },
  {
    imgSrc: "8.jpg",
    Tittle: "Ginger Johnston",
    Text: "uploaded this file",
    Time: "11:45 AM Monday",
  },
  {
    imgSrc: "2.png",
    Tittle: "Jason Borne",
    Text: "assigned a task to",
    Time: "9:15 AM Saturday",
    assigned: "Erica Hughes",
  },
  {
    imgSrc: "1.jpg",
    Tittle: "Vincent Porter",
    Text: "created an event",
    Time: "10:00 AM Monday",
  },
];
