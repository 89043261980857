import { Container, Row, Col, BreadcrumbItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { OL } from "../../AbstractElements";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Breadcrumbs = ({ onClick, title }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = window.location.pathname.split("/").filter(Boolean);
  console.log("pathSegments", pathSegments)
  const handleBreadcrumbClick = (index) => {
    if (index === 3 && pathSegments[index] === "subCategory") {
      const newPath = `/${pathSegments.slice(0, index).join("/")}`;
      navigate(newPath, { state: location?.state?.parentCategory || location?.state });
    } else if (pathSegments[index] === "new" || pathSegments[index] === "edit") {
      // no thing to do
    } else {
      const newPath = `/${pathSegments.slice(0, index + 1).join("/")}`;
      navigate(newPath);
    }
  };

  const renderBreadcrumbItem = (segment, index) => {
    const isActive = index === pathSegments.length - 1;
    const handleOnClick = () => {
      handleBreadcrumbClick(index);
      onClick && onClick();
    };

    if(pathSegments.length === 2 && pathSegments[0] === 'service' && title) {
      if(index === 1)
      return (
        <BreadcrumbItem
          key={index}
          active={isActive}
          style={{ cursor: "pointer" }}
        >
          {t(title)}
        </BreadcrumbItem>)

        else
         return (
        <BreadcrumbItem key={index} active={isActive} style={{ cursor: "pointer" }}>
        {t(segment)}
       </BreadcrumbItem> )
    } 

    if (pathSegments.length === 6 && location?.state?.subCategory && location?.state?.parentCategory) {
      if (index === 2 && location?.state?.parentCategory?._id === pathSegments[2]) {
        return (
          <BreadcrumbItem
            key={index}
            active={isActive}
            onClick={() => navigate(`/services-manager/category/${location?.state?.parentCategory?._id}`, { state: location?.state?.parentCategory })}
            style={{ cursor: "pointer" }}
          >
            {t(location?.state?.parentCategory?.name)}
          </BreadcrumbItem>
        );
      } else if (index === 4 && pathSegments[index] === "edit") {
        return (
          <BreadcrumbItem key={index} active={isActive} style={{ cursor: "pointer" }}>
            {t(segment)}
          </BreadcrumbItem>
        );
      } else if (index === 5 && location?.state?.subCategory?._id === pathSegments[5]) {
        return (
          <BreadcrumbItem key={index} active={isActive} style={{ cursor: "pointer" }}>
            {t(location?.state?.subCategory?.name)}
          </BreadcrumbItem>
        );
      }
    }

    if (pathSegments.length === 5) {
      if (index === 2 && pathSegments[4] === "new") {
        return (
          <BreadcrumbItem key={index} active={isActive} style={{ cursor: "pointer" }}>
            {t(location?.state?.name)}
          </BreadcrumbItem>
        );
      }
    }

    if (pathSegments[0] === "services-manager" && pathSegments[1] === "category" && location?.state?._id === pathSegments[2]) {
      if (index !== 2) {
        return (
          <BreadcrumbItem key={index} active={isActive} onClick={handleOnClick} style={{ cursor: "pointer" }}>
            {t(segment)}
          </BreadcrumbItem>
        );
      } else {
        return (
          <BreadcrumbItem
            key={index}
            active={isActive}
            onClick={() => navigate(`/services-manager/category/${location?.state?._id}`, { state: location?.state })}
            style={{ cursor: "pointer" }}
          >
            {t(location?.state?.name)}
          </BreadcrumbItem>
        );
      }
    }

    if (pathSegments[2] === "edit") {
      if (index === 3) {
        return (
          <BreadcrumbItem
            key={index}
            active={isActive}
            onClick={() => navigate(`/services-manager/category/${location?.state?._id}`, { state: location?.state })}
            style={{ cursor: "pointer" }}
          >
            {t(location?.state?.name)}
          </BreadcrumbItem>
        );
      } else if (index === 2) {
        return (
          <BreadcrumbItem key={index} active={isActive} style={{ cursor: "pointer" }}>
            {t(segment)}
          </BreadcrumbItem>
        );
      }
    }

    return (
      <BreadcrumbItem key={index} active={isActive} onClick={handleOnClick} style={{ cursor: "pointer" }}>
        {t(segment)}
      </BreadcrumbItem>
    );
  };

  return (
    <Container fluid={true}>
      <div className="page-header">
        <Row>
          <Col lg="6">
            <OL className="breadcrumb">
              <BreadcrumbItem>
                <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                  <i className="f-16 fa fa-home"></i>
                </Link>
              </BreadcrumbItem>
              {pathSegments.map((segment, index) => renderBreadcrumbItem(segment, index))}
            </OL>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Breadcrumbs;
