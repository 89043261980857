import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { executeAppointment, getAllAppointments } from "../services/appointmentService";


const initialState = {
  appointmentsData: {},
  loading: false,
  error: null,
};

export const fetchAppointmentsAsync = createAsyncThunk(
  "appointments/fetchData",
  async ({establishmentId, page, perPage}, { rejectWithValue }) => {
    try {
      const response = await getAllAppointments(establishmentId, page, perPage);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const executeAppointmentAsync = createAsyncThunk(
    "appointments/execute",
    async ({ data, appointmentId }, { rejectWithValue }) => {
      try {
        const response = await executeAppointment(data, appointmentId);
        console.log("ressss", response)
        return response;
      } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
          } else {
            return rejectWithValue(error.message);
          }
      }
    }
  );
  

const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAppointmentsAsync.fulfilled, (state, action) => {
      state.appointmentsData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(executeAppointmentAsync.fulfilled, (state, action) => {
        state.appointmentsData.data = state.appointmentsData.data?.map((elem) => elem._id === action.payload?.data?.data?._id ? action.payload?.data?.data : elem )
        state.loading = false;
        state.error = null;
      });
    builder.addCase(executeAppointmentAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

export const appointmentsReducer = appointmentsSlice.reducer;
export const { actions } = appointmentsSlice;
