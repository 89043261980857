import Customizer from "./ThemeCustomizer";
import FooterLayout from "./Footer";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar-Layout/SideBar";
import { ToastContainer } from "react-toastify";
import Loader from "./Loader";
import Taptop from "./Taptop";
import { useSelector } from "react-redux";
import { ThemeCustomizerTypes } from "./ThemeCustomizer/ThemeCustomizerTypes";
import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  const affectationSelectedData = useSelector(
  (state) => state.auth.affectationSelected
);


const isRoot = affectationSelectedData?.role.some(role => role.isRoot === true);
  const { sidebar_types } = useSelector(
    (state) => state.ThemeCustomizer
  );
  return (
    <>
      <Loader />
      <Taptop />
      <div className={`page-wrapper ${isRoot?"compact-wrapper":"horizontal-wrapper"}`}>
        <Header />
        <div
          className={`page-body-wrapper ${
            isRoot? "sidebar-icon": "horizontal-menu"
          }`}
        >
          <SideBar />
          <div>
            <Outlet />
          </div>
          <Customizer />
          <FooterLayout />
        </div>
      </div>
      {/* <ToastContainer />  that makes the toast appears twise*/}
    </>
  );
};

export default Layout;
