import { useState } from "react";
import DataTable from "react-data-table-component";
import { Btn } from "../../AbstractElements";
import { useTranslation } from "react-i18next";
import SweetAlert from "sweetalert2";
import { useDispatch } from "react-redux";
import { deleteAffectationAdminn } from "../../redux-toolkit/slices/employeeSlice";
import NewEmployee from "../Employee/NewEmployee";
import { usePermission } from "../../Service/usePermission";

const Table = ({
  columns,
  data,
  expandableRowsComponent,
  expandOnRowClicked,
  highlightOnHover,
  fixedHeader,
  setIsOpen,
  isOpen,
  onChangePage, // Pagination page change handler
  onChangeRowsPerPage, // Rows per page change handler
  pagination,
  paginationServer,
  paginationTotalRows, // Total rows for pagination
}) => {

  /*********** Start hook*s******/
    //useTranslation
    const { t } = useTranslation();

    //useState
    const [selectedRows, setSelectedRows] = useState([]);

    //useDispatch
    const dispatch = useDispatch();

    //
    const canExecute = usePermission('static', "employees", 'execute');
    const canDelete = usePermission('static', "employees", 'delete');
    const canUpdate = usePermission('static', "employees", 'update');
  /*********** End hooks **********/
  
  
  /*********** Start functions and handlers ************/

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handelDeleteEmployee = async () => {
    try {
      const roomsIds = selectedRows.map((row) => row._id);
      SweetAlert.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover it!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "cancel",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {        
            const affectationDeleted = await dispatch(deleteAffectationAdminn(roomsIds[0]));
            console.log("Affff", affectationDeleted)
            if (deleteAffectationAdminn.rejected.match(affectationDeleted)) {
              SweetAlert.fire("Error!", affectationDeleted.payload || "Error while deleting affectation admin!", "error");
            } else {
              SweetAlert.fire("Deleted!", affectationDeleted?.payload?.response?.data?.message || "affectaion admin deleted successfully!", "success");
              setSelectedRows([]);
            }
           
          } 
        }
      );
    } catch (error) {
      SweetAlert.fire("Error!", "Affectation could not be deleted.", "error");
    }
  };

  /*********** End functions and handlers *************/
  
  return (
    <>
      <NewEmployee
        title={t("Add New Employee")}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <div className="table-responsive support-table">
        <div className={`d-flex align-items-center justify-content-end bg-light-info p-2`}>
          <Btn
            color="danger mb-2 m-r-15"
            onClick={() => handelDeleteEmployee()}
            disabled={!selectedRows.some((e) => data.some((item) => item._id === e._id)) || (!canDelete)}
          >
            {t("Delete")}
          </Btn>
          <Btn color="btn btn-primary mb-2" onClick={() => setIsOpen(true)}  disabled={!canExecute}>
            {t("Add new employee")}
          </Btn>
        </div>
        <DataTable
          columns={columns}
          data={data}
          striped={true}
          pagination={pagination}
          paginationServer={paginationServer}
          paginationTotalRows={paginationTotalRows}
          onChangePage={onChangePage} // Handle page change
          onChangeRowsPerPage={onChangeRowsPerPage} // Handle rows per page change
          selectableRows
          selectableRowsSingle={true}
          onSelectedRowsChange={handleRowSelected}
          expandableRows
          expandableRowsComponent={expandableRowsComponent}
          expandOnRowClicked={expandOnRowClicked}
          highlightOnHover={highlightOnHover}
          fixedHeader={fixedHeader}
        />
      </div>
    </>
  );
};

export default Table;
