import React, { useState, useEffect } from "react";
import TableComp from "../../../Components/dataTable/TableComp";
import { Eye, Edit } from "react-feather";
import { Btn, H6, Image, Spinner } from "../../../AbstractElements";
import CategoryView from "./CategoryView";
import { dynamicImage } from "../../../Service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteCategoryAsync,
  getCategoriesWithoutParentAsync,
} from "../../../redux-toolkit/slices/categorySlice";
import { useTranslation } from "react-i18next";
import { usePermission } from "../../../Service/usePermission";

const CategoryList = () => {
  /************************** Start hooks **************************/
  // Start useState
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // End useState

  // Start useSelector
  const categoryData = useSelector((state) => state.category.categoriesData);
  const isLoading = useSelector((state) => state.category.loading);
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );
  // End useSelector

  // useDispatch
  const dispatch = useDispatch();

  // useNavigate
  const navigate = useNavigate();

  // useTranslation
  const { t } = useTranslation();

  // Start UseEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (affectationSelected?.establishments?._id) {
          await dispatch(
            getCategoriesWithoutParentAsync({
              establishmentId: affectationSelected?.establishments?._id,
              page,
              perPage,
            })
          );
        }
      } catch (error) {
        console.error("Error fetching category tree:", error);
      }
    };

    fetchData();
  }, [dispatch, affectationSelected, page, perPage]);

  // End useEffect
  /************************** End hooks **************************/

  /************************** Start constants and variables **************************/
  const categoryTableColumns = [
    {
      name: t("Image"),
      selector: (row) => (
        <Image
          src={
            row.images[0]
              ? process.env.REACT_APP_MINIO_URL + row?.images[0]
              : dynamicImage("ecommerce/options.png")
          }
          style={{ width: "70px", padding: "10px" }}
        />
      ),
      sortable: true,
      center: true,
    },
    {
      name: t("Name"),
      selector: (row) => row["name"],
      sortable: true,
      center: true,
    },
    {
      name: t("Sub Category"),
      selector: (row) => typeof row.children === "number" ? row.children : 0,
      sortable: true,
      center: true,
    },
    {
      name: t("Services"),
      selector: (row) => (typeof row.services === "number" ? row.services : 0),
      sortable: true,
      center: true,
    },
    {
      name: t("Action"),
      selector: (row) => (
        <div>
          <a
            title="View"
            className="btn-xs"
            onClick={() => handleOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Eye width={20} />
          </a>

          <a
            className="btn-xs"
            title="Edit"
            onClick={() => handleNavigateEditCategory(row)}
            style={{ cursor: "pointer" }}
          >
            <Edit width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  /************************** End constants and variables **************************/

  /************************** Start functions and handlers **************************/
  const handleOpenToView = (category) => {
    setSelectedCategory(category);
    setIsOpen(true);
  };

  const handleNavigateEditCategory = (row) => {
    const rowName = row.name.replace(/ /g, "-");
    navigate(`/services-manager/category/edit/${row?._id}`, { state: row });
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleDeleteCategory = async () => {
    if (selectedRows?.[0]?.children === 0) {
    try {
      const response = await dispatch(
        deleteCategoryAsync(selectedRows?.[0]?._id)
      );
      if (deleteCategoryAsync.fulfilled.match(response)) {
        toast.success(
          response.payload.message || "Category deleted successfully!!"
        );
        // Adjust page if last item on current page is deleted
        const newTotalData = categoryData?.totalData - 1;
        if (Math.ceil(newTotalData / perPage) < page) {
          setPage((prevPage) => prevPage - 1);
        }
      } else {
        toast.error(response.payload);
      }
    } catch (error) {
      toast.error("An error occurred while deleting the category.");
    }
  }
  else {
    toast.error("This category have subCategories");
  }
};

  

  const handelNavigateToForm = () => {
    navigate("/services-manager/category/new");
  };

  const handelNavigateToSubCategory = (row) => {
    const rowName = row.name.replace(/ /g, "-");
    if (row?.children > 0)
      navigate(`/services-manager/category/${row?._id}`, { state: row });
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <div style={{ backgroundColor: "red", width: "75%" }}>
        <div>{data?.name}</div>
      </div>
    );
  };

  // console.log("page = ", page, "perPage = ", perPage);
  // console.log("categoryData", categoryData);
  // console.log("categoryData1", Math.ceil(categoryData?.totalData / perPage) < page);

  /************************** End functions and handlers **************************/

  return (
    <div>
      <div className="d-flex align-items-center justify-content-end bg-light-info p-2">
        <Btn
          color="danger mb-2 m-r-15"
          onClick={() => handleDeleteCategory()}
          disabled={usePermission('static', "service manager", '!delete')  || selectedRows.length === 0 }
          
        >
          {t("Delete")}
        </Btn>
        <Btn
          color="btn btn-primary mb-2"
          onClick={() => handelNavigateToForm()}
          disabled={usePermission('static', "service manager", '!execute')} 
        >
          {t("Add New Category")}
        </Btn>
      </div>

      {isLoading ? (
        <div style={{ position: "relative", marginTop: "200px" }}>
          <div
            className="loader-wrapper"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0",
            }}
          >
            <div className="theme-loader" />
          </div>
        </div>
      ) : (
        <TableComp
          data={categoryData?.data}
          columns={categoryTableColumns}
          handleRowSelected={handleRowSelected}
          toggleDelete={false}
          expandableRows={false}
          highlightOnHover={true}
          fixedHeader={true}
          customStyles={customStyles}
          onChangePage={setPage}
          onChangeRowsPerPage={(newPerPage, newPage) => {
            setPerPage(newPerPage);
            setPage(newPage);
          }}
          paginationTotalRows={categoryData?.totalData}
          pagination
          selectableRows={true}
          selectableRowsSingle={true}
          onRowClicked={(row) => handelNavigateToSubCategory(row)}
        />
      )}

      <CategoryView
        isOpen={isOpen}
        toggleClose={() => setIsOpen(false)}
        data={selectedCategory}
      />
    </div>
  );
};

export default CategoryList;
