import React, { useEffect, useState } from "react";
import { Col, CardBody, Row, Label, CardFooter } from "reactstrap";
import { Button } from "reactstrap";
import Select from "react-select";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import UploadDropzone from "../../../../Components/Dropzone/UploadDropzone";
import SimpleInput from "../../../../Components/Inputs/simpleInput";
import {
  createCategoryAsync,
  getAllCategoryTreeAsync,
  getSubCategoryAsync,
  updateCategoryAsync,
} from "../../../../redux-toolkit/slices/categorySlice";
import { FaTrash } from "react-icons/fa";
import { usePermission } from "../../../../Service/usePermission";


const index = () => {

  /************************** Start hooks **************************/  
  
    //useState
    const [hasAnImage, setHasAnImage] = useState(false);

   //useSelector
   const userAdmin = useSelector((state) => state.auth.UserAdmin);
   const categoriesData = useSelector((state) => state.category?.categoriesData?.data);
   const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
   );

   //useDispatch
   const dispatch = useDispatch();

   //useLocation
   let location = useLocation();

   //useNavigate
   const navigate = useNavigate();

   //useParams
   const routeParams = useParams();

   //Start useEffect
   useEffect(() => {
    const fetchData = async () => {
      try {
        if (affectationSelected) {
          await dispatch(
            getAllCategoryTreeAsync({
              establishment: affectationSelected?.establishments._id,
              page: 1,
              perPage: "all",
              tree: "true",
            })
          );
        }
      } catch (error) {
        console.error("Error fetching category tree:", error);
      }
    };
  
    fetchData();
  }, [dispatch, affectationSelected]);

    useEffect(() => {
      
        if(location?.state?.subCategory && location?.state?.parentCategory){
          console.log("ayyy" ,location?.state?.parentCategory?.name)
          reset(
            { name: location?.state?.subCategory?.name ,
              parentCategory: { label: location?.state?.parentCategory?.name, value: location?.state?.parentCategory?._id} ,
          }
          );
        }
        else if (location?.state?.name && !location?.pathname?.includes("new")) {
        reset(
          { name: location?.state?.name },
          { parentCategory: location?.state?.parent }
        );
       }

       if (
        (!location?.pathname?.includes("new") && Array.isArray(location?.state?.images) && location.state.images.length > 0 ) ||
        (Array.isArray(location?.state?.subCategory?.images) && location.state.subCategory.images.length > 0)
      ) {
        setHasAnImage(true);
      }

    }, [location]);

   //End useEffect

     // Call usePermission to get necessary permissions we called outSide to avoid condionally call of hooks
      const canExecute = usePermission('static', "service manager", 'execute');
      const canUpdate = usePermission('static', "service manager", 'update');

   /************************** End hooks **************************/  

   /************************** Start constants and variables **************************/  

   const pathname = location.pathname.split("/");
   const schema = yup.object().shape({
    name: yup.string().required("You must enter a category name"),
    });
   const {
      control,
      handleSubmit,
      reset,
      resetField,
      formState: { isValid, dirtyFields, errors },
      setValue,
      watch,
    } = useForm({
      mode: "onChange",
      resolver: yupResolver(schema),
    });
   /************************** End constants and variables **************************/  

   /************************** Start functions and handlers**************************/
      const getUploadParams = (body) => {
       return { url: "https://httpbin.org/post" };
     };
   
     const handleSubmitFile = ({ meta, file, remove }, status) => {
       setValue("images", [file]);
       if (status === "headers_received") {
         toast.success(`${meta.name} uploaded!`);
       } else if (status === "aborted") {
         toast(`${meta.name}, upload failed...`);
       } else if (status === "removed") {
         resetField("images");
       }
     };

     
     async function onSubmit(data) {
      const formData = new FormData();
      
      try {
        // New Category
        if (pathname.includes("new") && !pathname.includes("subCategory")) {
          if (data?.parentCategory?.value) {
            formData.append("parent", data?.parentCategory?.value);
          }
          formData.append("name", data?.name);
          formData.append("cible", "internal");
          formData.append("establishment", affectationSelected?.establishments._id);
          for (let i = 0; i < data?.images?.length; i++) {
            formData.append("images", data.images[i]);
          }
    
          const categoryCreated = await dispatch(createCategoryAsync(formData));
          if (createCategoryAsync.rejected.match(categoryCreated)) {
            toast.error(categoryCreated.payload || "Error while creating a category!");
          } else {
            toast.success(categoryCreated.payload.message || "Category added successfully!");
          }
          navigate("/services-manager/category/");
          
        // Update Category
        } else if (!pathname.includes("new") && !pathname.includes("subCategory")) {
          if (data.name) {
            formData.append("name", data.name !== location?.state?.name  ? data?.name : location?.state?.name );
          }
    
          formData.append("cible", "internal");
          if (data?.parentCategory?.value) {
            formData.append("parent", data?.parentCategory?.value);
          }
          formData.append("establishment", affectationSelected?.establishments._id);
          
          for (let i = 0; i < data?.images?.length; i++) {
            formData.append("images", data.images[i]);
          }
    
          const categoryUpdated = await dispatch(updateCategoryAsync({
            idCategory: location?.state?._id,
            categoryData: formData,
          }));
    
          if (updateCategoryAsync.rejected.match(categoryUpdated)) {
            toast.error(categoryUpdated.payload || "Error while updating a category!");
          } else {
            toast.success(categoryUpdated.payload.message || "Category updated successfully!");
          }
          navigate("/services-manager/category/");
          
        // New SubCategory
        } else if (pathname.includes("new") && pathname.includes("subCategory")) {
          formData.append("parent", routeParams.categoryId);
          formData.append("name", data?.name);
          formData.append("cible", "internal");
          formData.append("establishment", affectationSelected?.establishments._id);
          for (let i = 0; i < data?.images?.length; i++) {
            formData.append("images", data.images[i]);
          }
    
          const subCategoryCreated = await dispatch(createCategoryAsync(formData));
          if (createCategoryAsync.rejected.match(subCategoryCreated)) {
            toast.error(subCategoryCreated.payload || "Error while creating a sub-category!");
          } else {
            console.log("locationnp", location)
            toast.success("SubCategory added successfully!");
            navigate(`/services-manager/category/${routeParams.categoryId}`, {state: location?.state});
            dispatch(getSubCategoryAsync({
              categoryId: routeParams.categoryId,
              establishmentId: affectationSelected?.establishments._id,
            }));
          }
          
        // Update SubCategory
        } else if (!pathname.includes("new") && pathname.includes("subCategory")) {
          
          if (data.name) {
            formData.append("name", data.name !== location?.state?.subCategory?.name ? data?.name : location?.state?.subCategory?.name);
          }
    
          formData.append("cible", "internal");
          if (data?.parentCategory?.label && data?.parentCategory?.value) {
            formData.append("parent", data?.parentCategory?.value);
          }
          formData.append("establishment", affectationSelected?.establishments._id);
          for (let i = 0; i < data?.images?.length; i++) {
            formData.append("images", data.images[i]);
          }
    
          const subCategoryUpdated = await dispatch(updateCategoryAsync({
            idCategory: location?.state?.subCategory?._id,
            categoryData: formData,
          }));
          
          if (updateCategoryAsync.rejected.match(subCategoryUpdated)) {
            toast.error(subCategoryUpdated.payload || "Error while updating a sub-category!");
          } else {
            toast.success("SubCategory updated successfully!");
            if( location?.state?.parentCategory?.children === 1 && data?.parentCategory?.value !== location?.state?.parentCategory?._id){
              navigate(`/services-manager/category/`);
            }
           else  navigate(`/services-manager/category/${routeParams.categoryId}`, {state: location?.state?.parentCategory});
          }
        }
      } catch (error) {
        toast.error("An error occurred while processing the category.");
        console.error("Error in onSubmit:", error);
      }
    }
    
    console.log("newwwCat", location)

    const mapCategories = (categories = [], excludeId) => {
      if (!Array.isArray(categories)) {
        return [];
      }
      
      return categories.flatMap(category => {
        if (category._id === excludeId) {
          return [];
        }
        return [
          { label: category.name, value: category._id },
          ...mapCategories(category.children || [], excludeId)
        ];
      });
    };
    
    const handleDeleteImage = () => {
      setHasAnImage(false);
    };
   
   /************************** End functions and handlers **************************/  

  return (
    <div className="page-body">
      {console.log("ar",location?.state)}
      <Breadcrumbs />
      <Col xl="12">
        <form className="card">
          <CardBody>
            <Row>
             <Col xl="3" className="position-relative">
                {hasAnImage ? (
                  <div className="position-relative">
                    <img
                     src={location?.state?.images
                      ? process.env.REACT_APP_MINIO_URL + location.state.images[0]
                      : location?.state?.subCategory?.images
                        ? process.env.REACT_APP_MINIO_URL + location.state.subCategory.images[0]
                        : ''
                    }
                      alt="Preview"
                      className="img-fluid"
                    />
                    <FaTrash className="position-absolute top-0 start-0 text-danger" style={{ cursor: 'pointer' }} onClick={handleDeleteImage} />
                  </div>
                ) : (
                  <Controller
                    name="images"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <UploadDropzone
                        getUploadParams={getUploadParams}
                        handleChangeStatus={handleSubmitFile}
                        {...field}
                      />
                    )}
                  />
                )}
              </Col>
              <Col xl="9">
                <Row>
                  <Col md="12">
                    <Label>Category Name</Label>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input" // change to lowercase
                          className="form-control"
                          placeholder="Name"
                          errors={errors.name?.message}
                        />
                      )}
                    />
                  </Col>
                  
                  {!(pathname.includes("new") && pathname.includes("subCategory"))&&
                  <Col lg="12" className="p-t-15">
                    <Label>Parent Category</Label>
                    {/* <Controller
                      name="parentCategory"
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={categoriesData
                            ?.filter((e) => {
                              if (location?.state?.children.length >= 0 && !pathname.includes("new")) {
                                return !((location.state.children.some(child => child._id === e._id)) || (location?.state?._id === e._id )); //In case of updating a category, the current category and its children must not be displayed in the parent category drop-down list.
                              }
                              return true;
                            })
                            ?.map((e) => {
                              return { label: e.name, value: e._id };
                            })}
                          {...field}
                          isClearable={true}
                        />
                      )}
                    /> */}
                  <Controller
                    name="parentCategory"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        options={pathname.includes("new") && categoriesData ? mapCategories(categoriesData) : mapCategories(categoriesData, location?.state?._id || location?.state?.subCategory?._id)}
                        {...field}
                        isClearable={true}
                      />
                    )}
                  />
                  </Col>}
                 
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-end">
         
            <Button onClick={handleSubmit(onSubmit)} color="secondary"
               disabled={
                    (pathname.includes("edit") && !canUpdate ) ||
                    (!pathname.includes("edit") && !canExecute)
               }
            >
              Save
            </Button>
          </CardFooter>
        </form>
      </Col>
    </div>
  );
};

export default index;