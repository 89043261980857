import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { Col, Row, Label } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../Inputs/simpleInput";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Btn } from "../../AbstractElements";
import { toast } from "react-toastify";
import {
  createUserAsync,
  passportOcrTunAsync,
} from "../../redux-toolkit/slices/userSlice";
import { FaArrowLeft } from "react-icons/fa"; // Assuming you're using Font Awesome icons

const ClientForm = ({ setAddNewClient, setGoSteps, setClientCreated, scanData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [defaultPassword, setDefaultPassword] = useState("");
  const schema = yup.object().shape({
    firstName: yup.string().required("You must enter a first name"),
    lastName: yup.string().required("You must enter a last name"),
    email: yup
      .string()
      .email("Invalid email format")
      .required("You must enter an email"),
    phone: yup.string().required("You must enter a phone number"),
    username: yup.string().required("You must enter a username"),
    uniqueId: yup.string().required("You must enter a uniqueId"),
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { isValid, dirtyFields, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setDefaultPassword(`${getValues("firstName")}-${getValues("uniqueId")}?`);
  }, [watch("firstName"), watch("uniqueId")]);

  useEffect(() => {
    if (scanData) {
      const { firstName, lastName, uniqueId } = scanData;
      reset({ firstName, lastName, uniqueId });
    }
  }, [scanData, reset]);

  async function onSubmit(data) {
    if (defaultPassword) {
      try {
        const transformedData = {
          email: data?.email,
          password: defaultPassword,
          firstName: data?.firstName,
          lastName: data?.lastName,
          phone: data?.phone,
          username: data?.username,
          role: "client",
          uniqueId: data.uniqueId,
        };
        const userCreated = await dispatch(createUserAsync(transformedData));
        console.log("🚀 ~ onSubmit ~ userCreated:", userCreated);
        if (createUserAsync.rejected.match(userCreated)) {
          toast.error(userCreated.payload);
        } else {
          setClientCreated(userCreated.payload);
          toast.success("Client created succesfully !");
          setGoSteps(1);
        }
      } catch (error) {
        toast.error("Error creating Client !");
      }
    }
  }

  return (
    <>
      <Col xl="12">
        <Row>
          <Col lg="6" className="m-b-15">
            <Label>First name</Label>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <SimpleInput
                  {...field}
                  errclassname="error-msg-input"
                  className="form-control"
                  placeholder="First name"
                  errors={errors.firstName?.message}
                />
              )}
            />
          </Col>
          <Col lg="6" className="m-b-15">
            <Label>Last name</Label>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <SimpleInput
                  {...field}
                  errclassname="error-msg-input"
                  className="form-control"
                  placeholder="Last name"
                  errors={errors.lastName?.message}
                />
              )}
            />
          </Col>
          <Col lg="12" className="m-b-15">
            <Label>Email</Label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <SimpleInput
                  {...field}
                  errclassname="error-msg-input"
                  className="form-control"
                  placeholder="Email"
                  errors={errors.email?.message}
                />
              )}
            />
          </Col>
          <Col lg="6" className="m-b-15">
            <Label>Phone number</Label>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <SimpleInput
                  {...field}
                  errclassname="error-msg-input"
                  className="form-control"
                  placeholder="Phone number"
                  errors={errors.phone?.message}
                />
              )}
            />
          </Col>
          <Col lg="6" className="m-b-15">
            <Label>Username</Label>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <SimpleInput
                  {...field}
                  errclassname="error-msg-input"
                  className="form-control"
                  placeholder="Username"
                  errors={errors.username?.message}
                />
              )}
            />
          </Col>
          <Col lg="6" className="m-b-15">
            <Label>Unique ID</Label>
            <Controller
              name="uniqueId"
              control={control}
              render={({ field }) => (
                <SimpleInput
                  {...field}
                  errclassname="error-msg-input"
                  className="form-control"
                  placeholder="Unique ID"
                  errors={errors.uniqueId?.message}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col lg="6" md="6" className="m-b-15 ">
            <Button
              color="warning"
              className="pull-left"
              style={{ marginLeft: "10px", backgroundColor: "red" }}
              onClick={() => {
                setAddNewClient(false);
              }}
            >
              <FaArrowLeft className="mr-2" /> Back
            </Button>{" "}
          </Col>
          <Col lg="6" md="6" className="m-b-15 d-flex justify-content-end">
            <Btn color="success" onClick={handleSubmit(onSubmit)}>
              Next
            </Btn>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ClientForm;
