import { createReducer } from "@reduxjs/toolkit";

let initialState = {
  refreshToken: {},
};

export const refreshTokenReducer = createReducer(initialState, {
  setRefreshTokenData: (state, action) => {
    state.refreshToken = action.payload;
  },
});
