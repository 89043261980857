import axiosInstance from "../../api/axios";

const ADD_PERMISSION = `${process.env.REACT_APP_BACK_URL}/api/v1/permission/createPermission`;
const UPDATE_PERMISSION = `${process.env.REACT_APP_BACK_URL}/api/v1/permission/updatePermission`;

export const addPermissions = async (data) => {
  try {
    const response = await axiosInstance.post(ADD_PERMISSION, data);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updatepermissions = async (data) => {
  try {
    const response = await axiosInstance.put(UPDATE_PERMISSION, data);
    return response;
  } catch (error) {
    console.error(error);
  }
};
