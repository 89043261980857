import axiosInstance from "../../api/axios";

const GET_ALL_APPOINTMENT = `${process.env.REACT_APP_BACK_URL}/api/v1/appointmentPrestation/getAppointmentByEstablishmentId`;
const EXECUTE_APPOINTMENT = `${process.env.REACT_APP_BACK_URL}/api/v1/appointmentPrestation/updateAppointment`;


export const getAllAppointments = async (establishement, page, perPage) => {
  try {
    const response = await axiosInstance.get(
      `${GET_ALL_APPOINTMENT}/${establishement}?page=${page}&perPage=${perPage}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const executeAppointment = async (data, appointmentId) => {
    try {
      const response = await axiosInstance.post(
        `${EXECUTE_APPOINTMENT}/${appointmentId}`,
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  

