import axiosInstance from "../../api/axios";

// const GET_ALL_SERVICES = `${process.env.REACT_APP_BACK_URL}/api/v1/service?page=1&perPage=10`;
const GET_ALL_SERVICES = `${process.env.REACT_APP_BACK_URL}/api/v1/service`;
const ADD_SERVICES = `${process.env.REACT_APP_BACK_URL}/api/v1/service/addService`;
const DELETE_SERVICES = `${process.env.REACT_APP_BACK_URL}/api/v1/service/delete`;
const UPDATE_SERVICES = `${process.env.REACT_APP_BACK_URL}/api/v1/service/update`;
const GET_SERVICE_By_Id = `${process.env.REACT_APP_BACK_URL}/api/v1/service`;

export const getAllServices = async (establishmentId, page, perPage) => {
  try {
    // const response = await axiosInstance.post(GET_ALL_SERVICES, {
    //   establishment: establishmentId,
    // });
    const response = await axiosInstance.post(`${GET_ALL_SERVICES}?page=${page}&perPage=${perPage}`, {
      establishment: establishmentId,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const addNewService = async (serviceData) => {
  try {
    const response = await axiosInstance.post(ADD_SERVICES, serviceData);
    return response;
  } catch (error) {
    throw error;
  }
    
};

export const deleteService = async (serviceId) => {
  try {
    const response = await axiosInstance.delete(
      `${DELETE_SERVICES}/${serviceId}`
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateService = async (serviceId, serviceData) => {
  try {
    const response = await axiosInstance.post(
      `${UPDATE_SERVICES}/${serviceId}`,
      serviceData
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const getServiceById = async (serviceId) => {
  try {
    const response = await axiosInstance.get(
      `${GET_SERVICE_By_Id}/${serviceId}`
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};


