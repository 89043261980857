import { useLocation, useParams } from "react-router-dom";
import { usePermission } from "./usePermission";
import { useSelector } from "react-redux";

const useProtectedRoute = (path = location?.pathname || "") => {
  const location = useLocation();
  const routeParams = useParams();
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );

  if (affectationSelected) {
    if (!affectationSelected?.role) {
      usePermission("static", "service manager", "read"); // this is just to equilibrate the number of hook calls
      return false;
    }
  }
  switch (true) {
    case path.includes("services-manager"):
      return usePermission("static", "service manager", "read");
    case path.includes("dashboard"):
      return usePermission("static", "dashboard", "read");
    case path.includes("employees"):
      return usePermission("static", "employees", "read");
    case path.includes("users"):
      return usePermission("static", "clients", "read");
    case path.includes("rooms"):
      return usePermission("static", "room", "read");
    case path.includes("orders"):
      return usePermission("static", "orders", "read");
    case path.includes("settings-page"):
      return usePermission("static", "settings", "read");
    case path.includes("user-profile"):
      return usePermission("static", "profile", "read");
    case path.includes("appointments"):
      return usePermission("static", "appointment", "read");
    case path.includes("complaints"):
      return usePermission("static", "complaints", "read");
    case path.includes("booking"):
      return usePermission("static", "booking", "read");
    default:
      // Assuming routeParams is available in your context. Adjust as necessary.
      return usePermission("dynamic", routeParams?.id, "read");
  }
};

export default useProtectedRoute;
