import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import DataTableRooms from "../../Components/Rooms/DataTableRooms";
import { fetchRoomsAsync } from "../../redux-toolkit/slices/roomsSlice";
import { Edit } from "react-feather";
import { Image } from "../../AbstractElements";
import { dynamicImage } from "../../Service";
import { useTranslation } from "react-i18next";


const RoomsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(1); // State for current page
  const [perPage, setPerPage] = useState(10); // State for items per page
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  const establishmentId = affectationSelectedData?.establishments?._id;
  const supportColumns = [
    {
      name: t("Image"),
      selector: (row) => (
        <Image
          src={
            row?.images[0]
              ? process.env.REACT_APP_MINIO_URL + row?.images[0]
              : dynamicImage("ecommerce/options.png")
          }
          style={{ width: "70px", padding: "10px" }}
        />
      ),
      sortable: true,
      center: true,
    },
    {
      name: t("Room Number"),
      selector: (row) => row["roomNumber"],
      sortable: true,
      center: true,
    },
    {
      name: t("Description"),
      selector: (row) => row["description"],
      sortable: true,
      center: true,
    },
    {
      name: t("Status"),
      selector: (row) => row["status"],
      sortable: true,
      center: true,
    },
    {
      name: t("Action"),
      selector: (row) => (
        <div>
          <a className="btn-xs" onClick={() => handleOpenToEdit(row)}>
            <Edit width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  const roomsData = useSelector((state) => state.rooms.roomsData);

  // Function to handle edit navigation
  const handleOpenToEdit = (row) => {
    navigate(`/rooms/edit/${row?._id}`, { state: row });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (establishmentId) {

        const res = await dispatch(
          fetchRoomsAsync({ establishmentId, page, perPage })
        );
      }
    };
    fetchData();
  }, [dispatch, establishmentId, page, perPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerRowsChange = (newPerPage, newPage) => {
    setPerPage(newPerPage);
    setPage(newPage);
  };

  return (
    <div className="page-body">
      <Breadcrumbs title={t("Rooms")} />
      <div>
        <DataTableRooms
          columns={supportColumns}
          data={roomsData.data}
          expandOnRowClicked={true}
          highlightOnHover={true}
          fixedHeader={true}
          pagination // Enable pagination
          paginationServer // Indicate server-side pagination
          paginationTotalRows={roomsData.totalData} // Total rows for pagination
          onChangePage={handlePageChange} // Page change handler
          onChangeRowsPerPage={handlePerRowsChange} // Rows per page handler
          page={page}
          setPage={setPage}
          perPage={perPage}
          selectableRowsSingle={true}
        />
      </div>
    </div>
  );
};

export default RoomsPage;
