import React, { Fragment, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  Col,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getAllMeals } from "../../../redux-toolkit/slices/mealsSlice";
import {
  availabilityRoomBookings,
  cleanRoomsForBooking,
  fetchRoomsAsync,
} from "../../../redux-toolkit/slices/roomsSlice"; // Add this line
import "./Calendar.css"; // Import your custom styles
import AvailabileRoom from "../AvailabileRoom";
import BookingSummary from "../BookingSummary";
import { getAllBookings } from "../../../redux-toolkit/slices/bookingSlice";
import { createBookingsAsync } from "../../../redux-toolkit/slices/bookingSlice";
import { toast } from "react-toastify";

const DragCalendar = () => {
  const dispatch = useDispatch();
  const paymentOptions = ["Paid", "Unpaid", "In-progress"];
  const [step, setStep] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [selectedBookingPreview, setSelectedBookingPreview] = useState(null);
  const [modalSize, setModalSize] = useState("xl");
  const [reviewDataToSubmit, setReviewDataToSubmit] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  const [selectedDateRange, setSelectedDateRange] = useState({
    start: null,
    end: null,
  });
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  const establishmentId = affectationSelectedData?.establishments?._id;

  const roomAvailableForBooking = useSelector(
    (state) => state.rooms.roomAvailableForBooking
  );

  const { bookings: bookingsData, error } = useSelector(
    (state) => state.bookings
  );

  const [formData, setFormData] = useState({
    dateArrive: "",
    dateDepart: "",
    bookings: [
      { roomId: "Room 1", nbAdulte: 1, nbEnfant: 0, roomType: "standard" },
    ],
  });

  useEffect(() => {
    if (establishmentId) {
      dispatch(getAllMeals(establishmentId));
    }
  }, [dispatch, establishmentId]);
  useEffect(() => {
    if (establishmentId) {
      dispatch(getAllBookings(establishmentId));
    }
  }, [dispatch, establishmentId]);

  useEffect(() => {
    let draggableEl = document.getElementById("external-events");

    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("title");
          let id = eventEl.getAttribute("data-id");
          return {
            title: title,
            id: id,
          };
        },
      });
    }
  }, []);

  const handleDateSelect = async (selectInfo) => {
    const { startStr, endStr } = selectInfo;
    setSelectedDateRange({
      start: startStr,
      end: endStr,
    });
    setFormData({
      ...formData,
      dateArrive: startStr,
      dateDepart: endStr,
    });
    setModalOpen(true);
    selectInfo.view.calendar.unselect(); // Clear date selection
  };

  const handleFormChange = (e, index) => {
    const { name, value } = e.target;
    const newValue =
      name.includes("nbAdulte") || name.includes("nbEnfant")
        ? Number(value)
        : value;

    setFormData((prevState) => {
      const updatedBookings = prevState.bookings.map((booking, i) =>
        i === index ? { ...booking, [name]: newValue } : booking
      );
      return { ...prevState, bookings: updatedBookings };
    });
  };

  const handleAddBooking = () => {
    const newRoomNumber = formData.bookings.length + 1;
    setFormData((prevState) => ({
      ...prevState,
      bookings: [
        ...prevState.bookings,
        { roomId: `Room ${newRoomNumber}`, nbAdulte: 1, nbEnfant: 0 }, // Add new room
      ],
    }));
  };
  const handleDeleteBooking = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      bookings: prevState.bookings.filter((_, i) => i !== index),
    }));
  };
  const handleCloseModal = () => {
    setModalOpen(false); // Close modal
    dispatch(cleanRoomsForBooking()); // Clear available rooms
    setSelectedBooking(null);
    setStep(0);
    setModalSize("xl");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const transformedData = formData.bookings.map((booking) => ({
        dateArrive: formData.dateArrive,
        dateDepart: formData.dateDepart,
        nbAdulte: booking.nbAdulte,
        roomType: booking.roomType,
        nbEnfant: booking.nbEnfant,
      }));

      // Create the final data object to send
      const dataToSend = {
        bookings: transformedData,
        establishmentId: establishmentId,
      };
      const response = await dispatch(availabilityRoomBookings(dataToSend));
    } catch (error) {
      console.error(
        "Error creating booking:",
        error.response?.data || error.message
      );
    }
  };

  const handleReviewBook = (data) => {
    setSelectedBooking(data);
    setModalSize("lg");
    setStep(1);
  };

  const handleBack = () => {
    setStep(0);
    setModalSize("xl");
  };

  const SubmitBooking = async () => {
    const finalData = {
      ...reviewDataToSubmit,
      userEmail: userEmail,
      paiment: paymentStatus,
    };
    const response = await dispatch(createBookingsAsync(finalData));
    if (createBookingsAsync.rejected.match(response)) {
      toast.error("Error creating booking !");
    } else {
      toast.success("booking created Successfully...!");
      await dispatch(getAllBookings(establishmentId));
      handleCloseModal();
    }
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.event.title}</b>
      </>
    );
  };

  const handleEventClick = (clickInfo) => {
    const booking = bookingsData.find(
      (booking) => booking._id === clickInfo.event.id
    );
    setSelectedBookingPreview(booking);
    setPreviewModalOpen(true);
  };
  return (
    <Fragment>
      <Row>
        <Col md={12} className="box-col-70">
          <Card>
            <CardBody>
              <div className="demo-app-calendar" id="mycalendartest">
                <FullCalendar
                  initialView="dayGridMonth"
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  rerenderDelay={10}
                  editable={true}
                  droppable={true}
                  selectable={true}
                  select={handleDateSelect}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  events={bookingsData?.map((booking) => ({
                    id: booking._id,
                    title: `${booking?.userId?.email} - Room ${booking?.roomId?.roomNumber}`,
                    start: booking?.dateArrive,
                    end: booking?.dateDepart,
                  }))}
                  eventContent={renderEventContent}
                  eventClick={handleEventClick}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={previewModalOpen}
        toggle={() => setPreviewModalOpen(!previewModalOpen)}
      >
        <ModalHeader toggle={() => setPreviewModalOpen(false)}>
          Booking Details
        </ModalHeader>
        <ModalBody>
          {selectedBookingPreview && (
            <>
              <p>
                <strong>User Email:</strong>{" "}
                {selectedBookingPreview.userId?.email}
              </p>
              <p>
                <strong>Room Number:</strong>{" "}
                {selectedBookingPreview.roomId?.roomNumber}
              </p>
              <p>
                <strong>Meals:</strong> {selectedBookingPreview.mealsId?.name}
              </p>
              <p>
                <strong>Number of Adults:</strong>{" "}
                {selectedBookingPreview.nbAdulte}
              </p>
              <p>
                <strong>Number of Children:</strong>{" "}
                {selectedBookingPreview.nbEnfant}
              </p>
              <p>
                <strong>Total Price:</strong>{" "}
                {selectedBookingPreview.totalPrice}
              </p>
              <p>
                <strong>Payment Status:</strong>{" "}
                {selectedBookingPreview.paiment}
              </p>
            </>
          )}
        </ModalBody>
        <Button
          color="secondary"
          onClick={() => setPreviewModalOpen(false)}
          style={{ margin: "30px" }}
        >
          Close
        </Button>
      </Modal>
      {/* Booking Form Modal */}
      <Modal
        isOpen={modalOpen}
        toggle={handleCloseModal}
        // fullscreen
        onClosed={handleCloseModal}
        size={modalSize}
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          {step === 0 ? "Create Booking" : "Review Booking"}
        </ModalHeader>
        <ModalBody>
          {step === 0 ? (
            <Form onSubmit={handleSubmit}>
              <Row className="g-2">
                <Col md="6">
                  <FormGroup>
                    <Label for="dateArrive">Arrival Date</Label>
                    <Input
                      type="date"
                      name="dateArrive"
                      id="dateArrive"
                      value={formData.dateArrive}
                      onChange={(e) =>
                        setFormData({ ...formData, dateArrive: e.target.value })
                      }
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label for="dateDepart">Departure Date</Label>
                    <Input
                      type="date"
                      name="dateDepart"
                      id="dateDepart"
                      value={formData.dateDepart}
                      onChange={(e) =>
                        setFormData({ ...formData, dateDepart: e.target.value })
                      }
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>

              {formData.bookings.map((booking, index) => (
                <Row
                  key={index}
                  className="g-2"
                  style={{ marginBottom: "10px" }}
                >
                  <Col md="2">
                    <FormGroup>
                      <Label>Room</Label>
                      <Input
                        type="text"
                        value={booking.roomId}
                        disabled // Room input is disabled, e.g. "Room 1", "Room 2", etc.
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label for={`roomType-${index}`}>Room Type</Label>
                      <Input
                        type="select"
                        name="roomType"
                        id={`roomType-${index}`}
                        value={booking.roomType}
                        onChange={(e) => handleFormChange(e, index)}
                      >
                        <option value="standard">Standard</option>
                        <option value="double">Double</option>
                        <option value="triple">Triple</option>
                        <option value="quad">Quad</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label for={`nbAdulte-${index}`}>Adults</Label>
                      <Input
                        type="number"
                        name="nbAdulte"
                        id={`nbAdulte-${index}`}
                        value={booking.nbAdulte}
                        onChange={(e) => handleFormChange(e, index)}
                        min="1"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Label for={`nbEnfant-${index}`}>Children</Label>
                      <Input
                        type="number"
                        name="nbEnfant"
                        id={`nbEnfant-${index}`}
                        value={booking.nbEnfant}
                        onChange={(e) => handleFormChange(e, index)}
                        min="0"
                        required
                      />
                    </FormGroup>
                  </Col>
                  {!index == 0 && (
                    <Col
                      md="3"
                      className="d-flex align-items-center"
                      style={{ marginBottom: "2%" }}
                    >
                      <Button
                        color="danger"
                        onClick={() => handleDeleteBooking(index)}
                        style={{ marginTop: "30px" }}
                      >
                        Delete
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  color="secondary"
                  onClick={handleAddBooking}
                  style={{ marginTop: "10px" }}
                >
                  + Add Another Room
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                }}
              >
                <Button
                  type="submit"
                  color="success"
                  style={{ marginTop: "10px", marginLeft: "10px" }}
                >
                  Check availability
                </Button>
              </div>

              {roomAvailableForBooking.length > 0 && (
                <AvailabileRoom
                  data={roomAvailableForBooking}
                  formData={formData}
                  onReview={handleReviewBook}
                  setReviewDataToSubmit={setReviewDataToSubmit}
                />
              )}
            </Form>
          ) : (
            <div>
              {/* Display selectedBooking data */}
              <Row className="g-2">
                <Col md="6">
                  <FormGroup>
                    <Label for="userEmail">User Email</Label>
                    <Input
                      type="email"
                      name="userEmail"
                      id="userEmail"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup>
                    <Label for="paymentStatus">Payment Status</Label>
                    <Input
                      type="select"
                      name="paymentStatus"
                      id="paymentStatus"
                      value={paymentStatus}
                      onChange={(e) => setPaymentStatus(e.target.value)}
                      required
                    >
                      {paymentOptions.map((option) => (
                        <option key={option} value={option.toLowerCase()}>
                          {option}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              {selectedBooking && (
                <BookingSummary bookingData={selectedBooking} />
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button onClick={handleBack}>Previous</Button>
                <Button color="success" onClick={SubmitBooking}>
                  Confirm
                </Button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default DragCalendar;
