import React from "react";
import SweetAlert from "sweetalert2";

const SweetAlertDelete = ({
  title,
  text,
  icon,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  setShowAlert,
}) => {
  const showAlert = () => {
    SweetAlert.fire({
      title,
      text,
      icon,
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        onConfirm();
      } else {
        setShowAlert(false);
      }
    });
  };

  return <>{showAlert()}</>;
};

export default SweetAlertDelete;
