import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import { useTranslation } from "react-i18next";
import TableComp from "../../Components/dataTable/TableComp";
import { Btn } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchComplaints,
  updateComplaintsStatus,
} from "../../redux-toolkit/slices/complaintsSlice";
import { Edit, Eye } from "react-feather";
import { Image } from "react-bootstrap";
import { dynamicImage } from "../../Service";
import ComplaintDetails from "../../Components/Complaints/ComplaintDetails";
import { toast } from "react-toastify";
import { usePermission } from "../../Service/usePermission";
import { extractServicesWithExecutePermission } from "../../Service/extractServiceWithExecutePermission";

const IndexComplaints = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /**  Start useSelector**/
  const complaintData = useSelector((state) => state.complaints.complaintsData);
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  /** End useSelector**/

  /** Start useState **/
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [sectedComplaint, setSectedComplaint] = useState(null);
  /** End useState **/
  const services = extractServicesWithExecutePermission(
    affectationSelectedData?.role
  );
  /** Start useEffect **/
  useEffect(() => {
    const fetchData = async () => {
      if (affectationSelectedData?.establishments._id) {
        const res = await dispatch(
          fetchComplaints({
            establishmentId: affectationSelectedData?.establishments._id,
            page,
            perPage,
            services,
          })
        );
      }
    };
    fetchData();
  }, [dispatch, affectationSelectedData, page, perPage]);
  /** End useEffect **/

  const canExecute = usePermission("static", "complaints", "execute");

  /** Start Handlers**/
  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleResolveComplaint = async () => {
    const resolvedComplaint = await dispatch(
      updateComplaintsStatus({ reclamationId: selectedRows[0]?._id })
    );
    if (updateComplaintsStatus.rejected.match(resolvedComplaint)) {
      toast.error(
        resolvedComplaint?.payload ||
          "error occured while resolving this complaint!"
      );
    } else {
      if (Math.ceil(complaintData?.data?.totalData / perPage) < page)
        // this is useful when resolving the last complaint in a page != 1
        setPage(page - 1);
      toast.success(
        resolvedComplaint?.payload?.message ||
          "Complaint resolved successfully!"
      );
    }
  };

  const handelOpenToView = (row) => {
    setSectedComplaint(row);
    setOpen(true);
  };
  /** End Handlers**/

  const complaintsTableColumns = [
    {
      name: t("Image"),
      selector: (row) => (
        <Image
          src={
            row?.images[0]
              ? process.env.REACT_APP_MINIO_URL + row?.images[0]
              : dynamicImage("ecommerce/options.png")
          }
          style={{ width: "70px", padding: "10px" }}
        />
      ),
      sortable: true,
      center: true,
    },
    {
      name: t("service"),
      selector: (row) =>
        row["service"] && row["service"]["name"] ? row["service"]["name"] : "",
      sortable: true,
      center: true,
    },
    {
      name: t("client"),
      selector: (row) => row["affectationUser"]["user"]["username"],
      sortable: true,
      center: true,
    },
    {
      name: t("status"),
      selector: (row) => row["status"],
      sortable: true,
      center: true,
    },
    {
      name: t("Action"),
      selector: (row) => (
        <div>
          <a
            className="btn-xs"
            onClick={() => handelOpenToView(row)}
            style={{ cursor: "pointer" }}
          >
            <Eye width={20} />
          </a>
        </div>
      ),
      sortable: false,
      center: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        color: "#202124",
        fontSize: "14px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        cursor: "pointer",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
    <div className="page-body">
      <Breadcrumbs />
      <div>
        <div className="d-flex align-items-center justify-content-end bg-light-info p-2">
          <Btn
            color=" btn btn-success mb-2 m-r-15"
            onClick={() => handleResolveComplaint()}
            disabled={
              !selectedRows.some((e) =>
                complaintData.data.some((item) => item._id === e._id)
              ) || !canExecute
            }
          >
            {t("Resolve")}
          </Btn>
        </div>
        <TableComp
          data={complaintData?.data}
          columns={complaintsTableColumns}
          handleRowSelected={handleRowSelected}
          selectableRowsSingle={true}
          toggleDelete={false}
          expandableRows={false}
          highlightOnHover={true}
          fixedHeader={true}
          customStyles={customStyles}
          onChangePage={setPage}
          onChangeRowsPerPage={(newPerPage, newPage) => {
            setPerPage(newPerPage);
            setPage(newPage);
          }}
          paginationTotalRows={complaintData.totalData}
          pagination
          selectableRows={true}
        />
      </div>
      <div>
        <ComplaintDetails
          open={open}
          setOpen={setOpen}
          sectedComplaint={sectedComplaint}
        />
      </div>
    </div>
  );
};

export default IndexComplaints;
