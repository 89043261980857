import React, { useState, Fragment } from 'react';
import Dropzone from 'react-dropzone-uploader';

const UploadDropzone = (props) => {
  const { getUploadParams, handleChangeStatus, key } = props;

  const [imageURL, setImageURL] = useState(`${process.env.REACT_APP_MINIO_URL}${props.image}`);

  const handleChangeStatuss = ({ meta, file }, status) => {
    if (handleChangeStatus) {
      handleChangeStatus({ meta, file }, status);
    }
    
    if (status === "done") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageURL(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Fragment>
      <div className="upload-container" style={{ position: "relative", width: "100%" }}>
        <div className="dz-message needsclick" style={{ position: "relative", zIndex: 1, height: "100%", width: "100%" }}>
          <Dropzone
            key={key}  //this status is used to reset the Dropzone when submitting a form 
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatuss}
            maxFiles={1}
            multiple={false}
            canCancel={true}
            inputContent="Drop A File"
            styles={{
              dropzone: { height: "100%", borderColor: "transparent", color: "#ffffff" },
              dropzoneActive: { borderColor: "green" },
            }}
            accept="image/png, image/jpeg"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default UploadDropzone;
