import React, { useEffect, useState } from "react";
import CardOfRoom from "./cardRoom";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { reviewBookingsAsync } from "../../redux-toolkit/slices/bookingSlice";

const AvailabileRoom = ({
  data,
  formData,
  onReview,
  setReviewDataToSubmit,
}) => {
  console.log("🚀 ~ AvailabileRoom ~ data:", data);
  const meals = useSelector((state) => state.meals.meals);
  console.log("🚀 ~ AvailabileRoom ~ meals:", meals);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);

  const dispatch = useDispatch();

  const handleRoomSelect = (roomIndex, room) => {
    setSelectedRooms((prev) => {
      const newSelection = [...prev];
      newSelection[roomIndex] = room;
      return newSelection;
    });
  };

  const handleMealSelect = (roomIndex, selectedOption) => {
    setSelectedMeals((prev) => {
      const newSelection = [...prev];
      newSelection[roomIndex] = selectedOption;
      return newSelection;
    });
  };
  const handleReview = async () => {
    const bookings = selectedRooms.map((room, index) => ({
      roomId: room._id,
      nbAdulte: formData.bookings[index].nbAdulte,
      nbEnfant: formData.bookings[index].nbEnfant,
      mealsId: selectedMeals[index].value || null,
    }));

    const reviewData = {
      dateArrive: formData.dateArrive,
      dateDepart: formData.dateDepart,
      bookings: bookings,
    };
    setReviewDataToSubmit(reviewData);
    const data = await dispatch(reviewBookingsAsync(reviewData));
    onReview(data.payload);
    console.log("Data to send to backend:", reviewData);
    // Implement your API call here
  };

  const mealOptions = meals?.map((meal) => ({
    label: `${meal.name} - $${meal.price}`,
    value: meal._id,
  }));

  return (
    <div style={{ paddingTop: "30px" }}>
      {data.map((roomSet, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Room {index + 1}</h2>
            <Select
              options={mealOptions}
              value={selectedMeals[index]}
              onChange={(selectedOption) =>
                handleMealSelect(index, selectedOption)
              }
              placeholder="Select a meal plan"
            />
          </div>
          {roomSet.suggestion && (
            <p style={{ color: "orange" }}>{roomSet.suggestion}</p>
          )}
          <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
            {roomSet.availableRooms.map((room) => (
              <div key={room._id}>
                <CardOfRoom
                  data={room}
                  isSelected={selectedRooms[index]?._id === room._id}
                  onSelect={() => handleRoomSelect(index, room)}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
      <div
        style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
      >
        <Button
          color="primary"
          onClick={handleReview}
          disabled={selectedRooms.length !== data.length}
          style={{ marginTop: "20px" }}
        >
          Review Booking
        </Button>
      </div>
    </div>
  );
};

export default AvailabileRoom;
