import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Row,
  Label,
  InputGroup,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { formatISO } from "date-fns";
import { FaSave } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { fetchRoomsAsync } from "../../redux-toolkit/slices/roomsSlice";
import { Btn } from "../../AbstractElements";
import { updateAffectationUserAsync } from "../../redux-toolkit/slices/affectationSlice";
import { fetchClients } from "../../redux-toolkit/slices/clientSlice";
import { usePermission } from "../../Service/usePermission";

const schema = yup.object().shape({
  rooms: yup.array().required("You must enter at least one room"),
  // status: yup.string().required("You must select a status"),
  selectedDate: yup.date().required("You must select a date"),
});

const UpdateAffectation = ({ isOpen2, setIsOpen2, selectedAffectation, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
  const roomsData = useSelector((state) => state.rooms.roomsData.data);
  const [inactiveRooms, setInactiveRooms] = useState([]);
  const [affectationId, setAffectationId] = useState(null);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isValid, dirtyFields, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (selectedAffectation) {
      reset({
        selectedDate: new Date(selectedAffectation.expiredDate),
        rooms: selectedAffectation.rooms.map((room) => ({
          label: room.roomNumber,
          value: room._id,
        })),
      });
      setAffectationId(selectedAffectation._id);
    }
  }, [selectedAffectation, reset]);

  useEffect(() => {
    const fetchData = async () => {
      if (affectationSelectedData.establishments?._id) {
        const rooms = await dispatch(
          fetchRoomsAsync({
            establishmentId: affectationSelectedData.establishments?._id,
            page: 1,
            perPage: "all",
          })
        );
        if (rooms) {
          setInactiveRooms(
            rooms.payload?.data?.filter((room) => room.status === "available")
          );
        }
      }
    };
    fetchData();
  }, [dispatch, affectationSelectedData]);

  async function onSubmit(data) {
    const { selectedDate, rooms } = data;
    // Extract an array of IDs from the selected rooms
    const roomIds = rooms.map((room) => room.value);
    // Formulate the date to match the format in the backend
    const formattedDate = formatISO(selectedDate);
    try {
      const updatedAffectation = await dispatch(
        updateAffectationUserAsync({
          id: affectationId,
          affectationData: {
            date: formattedDate,
            rooms: roomIds,
          },
        })
      );
      if (updateAffectationUserAsync.fulfilled.match(updatedAffectation)) {
        await dispatch(
          fetchClients({
            establishmentId: affectationSelectedData?.establishments._id,
            page: 1,
            perPage: 10,
          })
        );
        toast.success("Affectation updated successfully!");
        setIsOpen2(false);
        if(setIsOpen){
          setIsOpen(false)
        }
      } else {
        toast.error(updatedAffectation.payload);
      }
    } catch (error) {
      console.error("Error updating affectation:", error);
      toast.error("Affectation update error!");
    }
  }

  return (
    <Modal isOpen={isOpen2} size="lg" centered={true}>
      <ModalHeader>{t("Update Affectation")}</ModalHeader>
      <ModalBody>
        <Container fluid={true}>
          <Row className="justify-content-center align-items-center">
            {inactiveRooms && (
              <Col lg="6" sm="12" className="m-b-15">
                <Label>{t("Rooms")}</Label>
                <Controller
                  name="rooms"
                  control={control}
                  render={({ field }) => (
                    <InputGroup>
                      <div className="w-100">
                        <Select
                          options={roomsData
                            ?.filter((room) => room.status === "available")
                            .map((e) => ({
                              label: e.roomNumber,
                              value: e._id,
                            }))}
                          isMulti
                          onChange={(newValue) => {
                            const selectedValues = newValue.map((option) => ({
                              label: option.label,
                              value: option.value,
                            }));
                            setValue("rooms", selectedValues);
                          }}
                          defaultValue={selectedAffectation.rooms.map(
                            (room) => ({
                              label: room.roomNumber,
                              value: room._id,
                            })
                          )}
                        />
                      </div>
                      {errors.rooms && (
                        <div className="w-100">
                          <span className="error-msg-input">
                            {errors.rooms.message}
                          </span>
                        </div>
                      )}
                    </InputGroup>
                  )}
                />
              </Col>
            )}

            <Col
              lg="6"
              sm="12"
              className="m-b-15 d-flex gap-2 align-items-center"
            >
              <Label>{t("Date")}</Label>
              <Controller
                name="selectedDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    className="form-control digits"
                    showPopperArrow={false}
                    selected={field.value}
                    showTimeSelect
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) => {
                      field.onChange(date);
                      setValue("selectedDate", date);
                    }}
                    defaultValue={new Date(selectedAffectation.expiredDate)}
                  />
                )}
              />
              {errors.selectedDate && (
                <div className="w-100">
                  <span className="error-msg-input">
                    {errors.selectedDate.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row className="d-flex justify-content-between align-items-center">
            <Col>
              <Button
                variant="success"
                className="pull-right"
                style={{ marginLeft: "10px" }}
                onClick={handleSubmit(onSubmit)}
                disabled={!usePermission('static', "clients", 'update')} 
              >
                <FaSave className="mr-2" /> {t("Save")}{" "}
              </Button>{" "}
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Btn
          color="primary"
          onClick={() => {
            setIsOpen2(false);
          }}
        >
          {t("Close")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateAffectation;
