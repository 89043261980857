import React from "react";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import Orders from "../../Components/orders";

const OrdersPage = () => {
  return (
    <div className="page-body">
      <Breadcrumbs title="Orders" />
      <div>
        <Orders />
      </div>
    </div>
  );
};

export default OrdersPage;
