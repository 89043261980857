import React, { useEffect, useRef, useState } from "react";
import { Col, CardBody, Row, Label, CardFooter, Input } from "reactstrap";
import { Button } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../../../../Components/Inputs/simpleInput";
import UploadDropzone from "../../../../Components/Dropzone/UploadDropzone";
import Select from "react-select";
import { toast } from "react-toastify";
import { fetchDepartment } from "../../../../redux-toolkit/slices/departementSlice";
import { getAllCategoryTreeAsync } from "../../../../redux-toolkit/slices/categorySlice";
import SweetAlert from "sweetalert2";
import {
  addService,
  fetchServices,
  getServiceByIdAsync,
  updateTheService,
} from "../../../../redux-toolkit/slices/serviceSlice";
import Products from "./products";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ProductList from "./productList";
import { FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { usePermission } from "../../../../Service/usePermission";

const ServiceForm = () => {
/***Start hooks ***/
  //Start useState
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editProductInNewService, setEditProductInNewService] = useState(false)
  const [hasAnImage, setHasAnImage] = useState(false);
  const [options,setOptions]=useState([])
  const [newServiceId, setNewServiceId] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [serviceById, setServiceById] = useState() ;  //we use this state and not the location.state, because when we update the service and then directly refresh the serviceForm we need to get the updated service
  //End useState

  //Start useSelector
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  const categoryData = useSelector((state) => state.category.categoriesData?.data);
  const departementData = useSelector((state) => state.departement.departmentsData.data);
  const affectationSelected = useSelector(
    (state) => state.auth.affectationSelected
  );
  const servicesData = useSelector((state) => state.services.servicesData);
  //End useSelector

  // useLocation
  let location = useLocation();

  // useDispatch
  const dispatch = useDispatch();

  //useTranslation
  const { t } = useTranslation();

  //useRef
  const productRef = useRef(null);

  //useParams
  const routeParams = useParams();

   //useForm hook : we declared it in constants and variables block  to be able to use "schema" const

  
  //Start useEffect
              
             // this for fetching all categories and departments to be displayed in drop down list
              useEffect(() => {
                const fetchDepartmentsData = async () => {
                  try {
                    if (affectationSelected?.establishments._id) {
                      await dispatch(fetchDepartment({
                        establishmentId: affectationSelected?.establishments._id,
                        perPage: "all",
                        page: 1
                      })); // perPage = all to get all departments
                    }
                  } catch (error) {
                    console.error("Error fetching departments:", error);
                  }
                };
       
                const fetchCategoriesData = async () => {
                 try {
                   if (affectationSelected?.establishments._id) {
                     await dispatch(
                       getAllCategoryTreeAsync({
                         establishment: affectationSelected?.establishments._id,
                         page: 1,
                         perPage: "all",
                         tree: "false"
                       })
                     );
                   }
                 } catch (error) {
                   console.error("Error fetching category tree:", error);
                 }
               };
                fetchCategoriesData();
                fetchDepartmentsData();
              }, [dispatch, affectationSelected]);

              useEffect(()=>{
                categoryData && setOptions( getOptionsFromTree(categoryData))
               },[categoryData])

          // Cleanup effect pour réinitialiser newServiceId à null lors du démontage du composant
              useEffect(() => {
                return () => {
                  setNewServiceId(null);
                };
              }, []);
  
              useEffect(() => {
                if (serviceById && pathname.includes("edit")) {
                  reset({
                    name: serviceById?.name,
                    description: serviceById?.description,
                    status: serviceById?.statut,
                    category: {
                      label: serviceById?.category?.name,
                      value: serviceById?.category?._id,
                    },
                    departement: {
                      label: serviceById?.departement?.name,
                      value: serviceById?.departement?._id,
                    },

                  });
  
                  if (Array.isArray(serviceById?.images) && serviceById?.images.length > 0) {
                    setHasAnImage(true);
                  }
                }
             }, [location, serviceById]);
              
                //this useEffect usefull when selecting a product to  scroll to the edit product block 
              useEffect(() => {
                  if (selectedProduct && productRef.current) {
                      productRef.current.scrollIntoView({ behavior: 'smooth' }); 
                  }
               }, [selectedProduct]);


               useEffect(() => {
                const fetchService = async () => {
                  if (routeParams?.id) {
                    const res = await dispatch(getServiceByIdAsync(routeParams.id));
                    setServiceById(res?.payload?.data[0])
                  }
                };
                
                fetchService();
              }, [routeParams, dispatch]);
  
  //End useEffect

       // Call usePermission to get necessary permissions we called outSide to avoid condionally call of hooks
       const canExecute = usePermission('static', "service manager", 'execute');
       const canUpdate = usePermission('static', "service manager", 'update');
/***End hooks ***/

/*******  Start Constant and variables ******/
      
      const pathname = location.pathname.split("/");                    
      const schema = yup.object().shape({
        name: yup.string().required("You must enter the name"),
        description: yup.string().required("You must enter the description"),
      });

       //useForm hook : we declared here to be able to use "schema" const
        const {
          control,
          handleSubmit,
          reset,
          resetField,
          formState: { isValid, dirtyFields,isSubmitting , errors },
          setValue,
          watch,
        } = useForm({
          mode: "onChange",
          resolver: yupResolver(schema),
        });
/*******  End Constant and variables ******/


/****** Start functions and handlers ********/
    const getOptionsFromTree = (tree, options = []) => {
      tree.forEach(category => {
        options.push({ label: category.name, value: category._id });
        if (category.children && category.children.length > 0) {
          getOptionsFromTree(category.children, options);
        }
      });
      return options;
    };
    
    const getUploadParams = (body) => {
      return { url: "https://httpbin.org/post" };
    };
    
    const handleSubmitFile = ({ meta, file, remove }, status) => {
      setValue("images", [file]);
      if (status === "headers_received") {
        toast.success(`${meta.name} uploaded!`);
      } else if (status === "aborted") {
        toast(`${meta.name}, upload failed...`);
      } else if (status === "removed") {
        resetField("images");
      }
    };
    
    async function onSubmit(data) {
      const formData = new FormData();
    
      try {
        if(serviceById?.name !== data?.name){
          formData.append("name", data?.name);
        }        
        formData.append("description", data?.description);
        formData.append("category", data?.category?.value);
        formData.append("departement", data?.departement?.value);
        formData.append("establishment", affectationSelected?.establishments._id);
        formData.append("statut", data?.status)
        
        for (let i = 0; i < data?.images?.length; i++) {
          formData.append("images", data.images[i]);
        }
      
        if (!pathname.includes("edit")) {
          const serviceAdded = await dispatch(addService(formData));
          if (addService.rejected.match(serviceAdded)) {
            SweetAlert.fire(
              "Conflict!",
              serviceAdded.payload,
              "error"
            );
          } else {
            setNewServiceId(serviceAdded.payload.data._id);
            SweetAlert.fire(
              "Added!",
              serviceAdded.payload.message || "Service was added successfully!",
              "success"
            );
            // await dispatch(fetchServices({
            //   establishmentId: affectationSelected?.establishments._id,
            //   page: 1,
            //   perPage: "all",
            // }));
          }
        } else {
         const serviceUpdated = await dispatch(
            updateTheService({
              serviceId: serviceById?._id,
              serviceData: formData,
            })
          )
          if (updateTheService.rejected.match(serviceUpdated)) {
            SweetAlert.fire(
              "Conflict!",
              serviceUpdated?.payload || "Error while updating this service",
              "error"
            );
          } else {
            SweetAlert.fire(
              "Updated!",
              serviceUpdated?.payload?.message || "Service was updated successfully!",
              "success"
            );           
          }        
        }
      } catch (error) {
        SweetAlert.fire(
          "Error!",
          "An error occurred while submitting the form. Please try again.",
          "error"
        );
      }
    }
    
    const handleDeleteImage = () => {
      setHasAnImage(false);
    };  
    
/****** End functions and handlers *******/


  return (
    <div className="page-body">
      <Breadcrumbs />
      <Col xl="12">
        <form className="card">
          <CardBody>
            <Row>
            <Col xl="3" className="position-relative">
                {hasAnImage ? (
                  <div className="position-relative">
                    <img
                      src={process.env.REACT_APP_MINIO_URL + serviceById?.images[0]}
                      alt="Preview"
                      className="img-fluid"
                    />
                    <FaTrash className="position-absolute top-0 start-0 text-danger" style={{ cursor: 'pointer' }} onClick={handleDeleteImage} />
                  </div>
                ) : (
                  <Controller
                    name="images"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <UploadDropzone
                        getUploadParams={getUploadParams}
                        handleChangeStatus={handleSubmitFile}
                        {...field}
                      />
                    )}
                  />
                )}
              </Col>
              <Col xl="9">
                <Row>
                  <Col md="6">
                    <Label>{t("Service name")}</Label>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input" // change to lowercase
                          className="form-control"
                          placeholder={t("Name")}
                          errors={errors.name?.message}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>{t("Description")}</Label>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <SimpleInput
                          {...field}
                          errclassname="error-msg-input" // change to lowercase
                          className="form-control"
                          placeholder={t("Description")}
                          errors={errors.description?.message}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col md="6">
                    <Label>{t("Category")}</Label>
                    <Controller
                      name="category"
                      control={control}
                      // defaultValue={
                      //   pathname.includes("edit") ? selectedCategory : null
                      // }
                      render={({ field }) => (
                        <Select
                          // defaultValue={
                          //   pathname.includes("edit") ? selectedCategory : null
                          // }
                          options={options}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                  <Col md="6">
                    <Label>{t("Department")}</Label>
                    <Controller
                      name="departement"
                      control={control}
                      render={({ field }) => (
                        <Select
                          options={departementData?.map((e) => ({
                            label: e?.name,
                            value: e?._id,
                          }))}
                          {...field}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                  <Col md="6">
                  <Label>{t("Active")}</Label>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "start",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="icon-state switch-outline">
                      <Controller
                        name={`status`}
                        control={control}
                        defaultValue={serviceById?.statut || false}
                        render={({ field }) => (
                          <Label
                            className="switch"
                            style={{ marginBottom: "0px", marginTop: "0px" }}
                          >
                            <Input type="checkbox" {...field}  checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
                            <span className="switch-state bg-primary"></span>
                          </Label>
                        )}
                      />
                    </div>
                  </div>
                </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-end">
            <Button onClick={handleSubmit(onSubmit)} color="secondary"
            disabled={
                  (pathname.includes("edit") && !canUpdate ) ||
                  (!pathname.includes("edit") && !canExecute)|| (pathname?.includes("new") && newServiceId )
             }
              >
              {t("Save")}
            </Button>
          </CardFooter>
        </form>
      </Col>
      <>
      {(newServiceId || (serviceById?._id)) && (
        <div>
          <div ref={productRef}>
            <Products selectedProduct={selectedProduct} setSelectedProduct = {setSelectedProduct} setEditProductInNewService = {setEditProductInNewService} page = {page}  perPage = {perPage} />
          </div>
          <h4 style={{ paddingBottom: "10px" }}>List products</h4>
          <ProductList
            setSelectedProduct={setSelectedProduct}
            editProductInNewService = {editProductInNewService}
            perPage = {perPage}
            page = {page}
            setPage = {setPage}
            setPerPage = {setPerPage}
          />
        </div>
      )} 
    </>
      
    </div>
  );
};

export default ServiceForm;
