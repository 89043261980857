import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createMeals,
  getAllEstablishmentMeals,
} from "../services/mealsService";
import {
  createBookings,
  getAllBookingByEstablishmentId,
  reviewBookings,
} from "../services/bookingService";

const initialState = {
  bookings: [],
  loading: false,
  error: null,
};

export const createBookingsAsync = createAsyncThunk(
  "booking/create",
  async (bookingData, { rejectWithValue }) => {
    try {
      const response = await createBookings(bookingData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllMeals = createAsyncThunk(
  "meals/fetch",
  async (establishmentId) => {
    try {
      const response = await getAllEstablishmentMeals(establishmentId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAllBookings = createAsyncThunk(
  "bookings/fetch",
  async (establishmentId) => {
    try {
      const response = await getAllBookingByEstablishmentId(establishmentId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const reviewBookingsAsync = createAsyncThunk(
  "booking/review",
  async (bookingData, { rejectWithValue }) => {
    try {
      const response = await reviewBookings(bookingData);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const bookingSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllMeals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllMeals.fulfilled, (state, action) => {
      console.log("Fetched Meals:", action.payload);
      state.meals = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllMeals.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(createBookingsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    //*************************************__BOOKING__CASES__****************************************** */
    builder.addCase(getAllBookings.fulfilled, (state, action) => {
      state.bookings = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllBookings.pending, (state, action) => {
      state.bookings = null;
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllBookings.rejected, (state, action) => {
      state.bookings = null;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const bookingsReducer = bookingSlice.reducer;
