import axios from "axios";
import axiosInstance from "../../api/axios";

const GET_ALL_EMPLOYEE = `${process.env.REACT_APP_BACK_URL}/api/v1/admin/`;
const DELETE_EMPLOYEE = `${process.env.REACT_APP_BACK_URL}/api/v1/admin/deleteAdmin`;
const CREATE_EMPLOYEE = `${process.env.REACT_APP_BACK_URL}/api/v1/admin/createAdmin`;
const GET_EMPLOYEE_BY_ID = `${process.env.REACT_APP_BACK_URL}/api/v1/admin/getAdminById`;
const UPDATE_EMPLOYEE = `${process.env.REACT_APP_BACK_URL}/api/v1/admin/updateAdmin`;
const UPDATE_EMPLOYEE_PASSWORD = `${process.env.REACT_APP_BACK_URL}/api/v1/admin/updateAdminPassword`;
const CREATE_AFFECTATION_ADMIN = `${process.env.REACT_APP_BACK_URL}/api/v1/affectationAdmin/createAffectationAdmin`;
const GET_ALL_EMPLOYEES_WITH_ROLES = `${process.env.REACT_APP_BACK_URL}/api/v1/admin/getAdminAffectationByEstablishement`;
const UPDATE_AFFECTATION_ADMIN = `${process.env.REACT_APP_BACK_URL}/api/v1/affectationAdmin/updateAffectationAdmin`;
const DELETE_AFFECTATION_ADMIN = `${process.env.REACT_APP_BACK_URL}/api/v1/affectationAdmin/deleteAffectationAdmin`;

export const getEmployee = async () => {
  try {
    const response = await axiosInstance.get(GET_ALL_EMPLOYEE);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getEmployeeWithRoles = async (establishmentId, page, perPage) => {
  try {
    const response = await axiosInstance.get( `${GET_ALL_EMPLOYEES_WITH_ROLES}/${establishmentId}?page=${page}&perPage=${perPage}`);
    console.log("repppppppppppppppppppppppppppppppppppppp",response)
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createEmployee = async (employeeData) => {
  try {
    const response = await axiosInstance.post(CREATE_EMPLOYEE, employeeData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const createAffectationAdmin = async (
  adminId,
  establishmentId,
  roleIds
) => {
  try {
    const response = await axiosInstance.post(CREATE_AFFECTATION_ADMIN, {
      adminId,
      establishmentId,
      roleIds,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const deleteEmployee = async (employeeId) => {
  try {
    const response = await axiosInstance.post(DELETE_EMPLOYEE, employeeId);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEmployeeById = async (adminId) => {
  try {
    const response = await axiosInstance.get(
      `${GET_EMPLOYEE_BY_ID}/${adminId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateEmployeeById = async ({employeeId, updatedAdminData}) => {
  try {
    console.log("emmmmppp",employeeId)
    const response = await axiosInstance.put(
      `${UPDATE_EMPLOYEE}/${employeeId}`,
      updatedAdminData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

//update admin password
export const updateEmployeePassword = async ({employeeId, updatedAdminPasswordData}) => {
  try {
    console.log("emmmmppp",employeeId)
    const response = await axiosInstance.put(
      `${UPDATE_EMPLOYEE_PASSWORD}/${employeeId}`,
      updatedAdminPasswordData
    );
    console.log("aaaaaaaaaaaaaa",response)
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateAffectationAdmin = async ({affectationId, updatedAffectationAdminData}) => {
  try {
    const response = await axiosInstance.post(
      `${UPDATE_AFFECTATION_ADMIN}/${affectationId}`,
      updatedAffectationAdminData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAffectationAdmin = async ( affectationId ) => {
  try {
    const response = await axiosInstance.delete(
      `${DELETE_AFFECTATION_ADMIN}/${affectationId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};