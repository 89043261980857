import { createReducer } from "@reduxjs/toolkit";

let initialState = {
  service: {},
};

export const serviceReducer = createReducer(initialState, {
  setServiceData: (state, action) => {
    state.service = action.payload;
  },
});
