// import axios from "axios";
import axiosInstance from "../../api/axios";

// const GET_ALL_USERS = `${process.env.REACT_APP_BACK_URL}/api/v1/auth/getAllUsers`;
// const UPDATE_USER = (userId) =>
//   `${process.env.REACT_APP_BACK_URL}/api/v1/auth/update/${userId}`;
// const GET_USER_BY_UNIQUEID = (uniqueId) =>
//   `${process.env.REACT_APP_BACK_URL}/api/v1/auth/getUserByUniqueId/${uniqueId}`;
// const GET_USERS_FROM_AFFECTATION = (establishmentId) =>
//   `${process.env.REACT_APP_BACK_URL}/api/v1/affectationUser/getUsersFromAffectationByEstablishmentId/${establishmentId}`;
const GET_CLIENTS_FROM_AFFECTATION = (establishmentId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/affectationUser/getClientsFromAffectationByEstablishmentId/${establishmentId}`;
const GET_ALL_CLIENTS = (establishmentId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/affectationUser/getUsersFromAffectationByEstablishmentId/${establishmentId}`;
// const CREATE__USER_ACCOUNT = `${process.env.REACT_APP_BACK_URL}/api/v1/auth/signup`;
const PASSPORT_OCR_TUN = `${process.env.REACT_APP_BACK_URL}/api/v1/passportOCR/passportOcrTun`;
// const UPDATE_ROLE_USER = (userId) =>
//   `${process.env.REACT_APP_BACK_URL}/api/v1/auth/updateRoleByUserId/${userId}`;

// export const getAllUsers = async () => {
//   try {
//     const response = await axiosInstance.get(GET_ALL_USERS);
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };
export const passportOcrTun = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    console.log("formData========>", formData);
    const response = await axiosInstance.post(PASSPORT_OCR_TUN, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllClients = async (establishmentId, page, perPage) => {
  try {
    const response = await axiosInstance.get(GET_ALL_CLIENTS(establishmentId)+`?page=${page}&perPage=${perPage}`);
    console.log(
      "🚀 ~ file: clientService.js:10 ~ getAllClients ~ response:",
      response
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

// export const getUserByUniqueId = async (uniqueId) => {
//   try {
//     const response = await axiosInstance.get(GET_USER_BY_UNIQUEID(uniqueId));
//     console.log("uniqueId", response);
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };
// export const updateUser = async (
//   userId,
//   email,
//   status,
//   firstName,
//   lastName,
//   role
// ) => {
//   try {
//     const response = await axiosInstance.post(UPDATE_USER(userId), {
//       email,
//       status,
//       firstName,
//       lastName,
//       role,
//     });

//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };
// export const CreateUser = async (
//   email,
//   password,
//   firstName,
//   lastName,
//   phone,
//   username,
//   role,
//   uniqueId
// ) => {
//   try {
//     const response = await axiosInstance.post(CREATE__USER_ACCOUNT, {
//       email,
//       password,
//       firstName,
//       lastName,
//       phone,
//       username,
//       role,
//       uniqueId,
//     });

//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

// export const updateRoleUser = async (role) => {
//   try {
//     const response = await axiosInstance.post(UPDATE_ROLE_USER(userId), {
//       role,
//     });

//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

// export const getUsersFromAffectationByEstablishmentId = async (
//   establishmentId
// ) => {
//   try {
//     const response = await axiosInstance.get(
//       GET_USERS_FROM_AFFECTATION(establishmentId)
//     );

//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const getClientsFromAffectationByEstablishmentId = async (
  establishmentId
) => {
  try {
    const response = await axiosInstance.get(
      GET_CLIENTS_FROM_AFFECTATION(establishmentId)
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};
