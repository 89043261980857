import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getNotification } from "../services/notificationService";

const initialState = {
  notifications: [],
  loading: false,
  error: null,
  totalCount: null,
};

export const getNotifications = createAsyncThunk(
  "notification/get",
  async ({ type, services, page, limit }) => {
    console.log("🚀 ~ type:", type);

    const response = await getNotification(type, services, page, limit);
    try {
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload.data;
      state.totalCount = action.payload.totalCount;
      state.loading = false;
      state.error = null;
    });
  },
});

export const notificationReducer = notificationSlice.reducer;
export const { actions } = notificationSlice;
