import React from "react";
import DataTable from "react-data-table-component";

const TableComp = ({
  columns,
  data,
  handleRowSelected,
  toggleDelete,
  expandableRowsComponent,
  expandOnRowClicked,
  fixedHeader,
  expandableRows,
  highlightOnHover,
  customStyles,
  selectableRowsSingle,
  onRowClicked,
  selectableRowDisabled,
  onChangePage,
  onChangeRowsPerPage,
  paginationTotalRows, 
  selectableRows,
}) => {
  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        striped={true}
        selectableRowsSingle={selectableRowsSingle}
        pagination
        paginationServer // Enable server-side pagination
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        paginationTotalRows={paginationTotalRows} // Use the total rows prop
        selectableRows={selectableRows}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleDelete}
        expandableRows={expandableRows}
        expandableRowsComponent={expandableRowsComponent}
        expandOnRowClicked={expandOnRowClicked}
        highlightOnHover={highlightOnHover}
        fixedHeader={fixedHeader}
        customStyles={customStyles}
        onRowClicked={onRowClicked}
        selectableRowDisabled={selectableRowDisabled}
      />
    </div>
  );
};

export default TableComp;
