import React, { useEffect } from "react";
import { Col, CardBody, Row, Label, CardFooter, Input } from "reactstrap";
import { Button } from "reactstrap";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import SimpleInput from "../../../../Components/Inputs/simpleInput";
import {
  addDepartement,
  getDepartmentByIdAsync,
  updateDepartmentAsync,
} from "../../../../redux-toolkit/slices/departementSlice";
import { useTranslation } from "react-i18next";
import SweetAlert from "sweetalert2";
import { usePermission } from "../../../../Service/usePermission";


const Index = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userAdmin = useSelector((state) => state.auth.UserAdmin);
  const schema = yup.object().shape({
    name: yup.string().required("You must enter a first name"),
  });
  const routeParams = useParams();
  const navigate = useNavigate();
  let location = useLocation();
  const departmentData = useSelector(
    (state) => state.departement.departementId
  );
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );
   // Call usePermission to get necessary permissions we called outSide to avoid condionally call of hooks
   const canExecute = usePermission('static', "service manager", 'execute');
   const canUpdate = usePermission('static', "service manager", 'update');

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { isValid, dirtyFields, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      workingHours: daysOfWeek.map((day) => ({
        day: day,
        openTime: "08:00:00",
        closeTime: "22:30:00",
        isOpen24Hours: false,
      })),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "workingHours",
  });

  useEffect(() => {
    if (routeParams?.departmentId) {
      dispatch(getDepartmentByIdAsync(routeParams?.departmentId));
    }
  }, [dispatch, routeParams]);

  useEffect(() => {
    if (routeParams?.departmentId && departmentData) {
      reset({
        name: departmentData.name,
        workingHours: departmentData.workingHours,
      });
    }
  }, [departmentData, routeParams]);

  const pathname = location.pathname.split("/");

  async function onSubmit(data) {
    const formData = {
      ...data,
      establishment: affectationSelectedData?.establishments._id,
    };
    if (pathname.includes("edit")) {
      try {
        const updatedDepartment = await dispatch(
          updateDepartmentAsync({
            idDepartement: routeParams?.departmentId,
            departementData: formData,
          })
        );
        if (updateDepartmentAsync.rejected.match(updatedDepartment)) {
          SweetAlert.fire(
            "Update Department",
            updatedDepartment.payload,
            "error"
          );
        } else {
          SweetAlert.fire(
            "Update Department",
            updatedDepartment.payload.message,
            "success"
          );
        }
      } catch (error) {
        console.log("An error occurred while updating the department", error);
      }
    } else {
      try {
        const addedDepartment = await dispatch(addDepartement(formData));
        if (addDepartement.rejected.match(addedDepartment)) {
          toast.error(
            addedDepartment?.payload ||
              "An error occurred while adding the department."
          );
        } else {
          toast.success(addedDepartment.payload.message);
        }
        navigate("/services-manager/department");
      } catch (error) {
        console.log("An error occurred while adding the department", error);
      }
    }
  }

  return (
    <div className="page-body">
      <Breadcrumbs />
      <Col xl="12">
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <CardBody>
            <Row>
              <Col md="12">
                <Label>{t("Department Name")}</Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <SimpleInput
                      {...field}
                      errclassname="error-msg-input" // change to lowercase
                      className="form-control"
                      placeholder={t("Name")}
                      errors={errors.name?.message}
                    />
                  )}
                />
              </Col>
            </Row>
          </CardBody>
          <CardBody>
            <Row>
              <Col md="3">
                <Label>{t("Day")}</Label>
              </Col>
              <Col md="3">
                <Label>{t("Open time")}</Label>
              </Col>
              <Col md="3">
                <Label>{t("Close time")}</Label>
              </Col>
              <Col md="3">
                <Label>{t("24H open")}</Label>
              </Col>
            </Row>

            {fields.map((item, index) => (
              <Row key={item.id}>
                <Col md="3">
                  <SimpleInput
                    value={t(item.day)}
                    className="form-control"
                    disabled
                  />
                </Col>
                <Col md="3">
                  <Controller
                    name={`workingHours[${index}].openTime`}
                    control={control}
                    render={({ field }) => (
                      <Input {...field} className="digits" type="time" />
                    )}
                  />
                </Col>
                <Col md="3">
                  <Controller
                    name={`workingHours[${index}].closeTime`}
                    control={control}
                    render={({ field }) => (
                      <Input {...field} className="digits" type="time" />
                    )}
                  />
                </Col>
                <Col md="3">
                  {/* <div>24H open</div> */}
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "start",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="icon-state switch-outline">
                      <Controller
                        name={`workingHours[${index}].isOpen24Hours`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Label
                            className="switch"
                            style={{ marginBottom: "0px", marginTop: "10px" }}
                          >
                            <Input
                              type="checkbox"
                              {...field}
                              checked={getValues(
                                `workingHours[${index}].isOpen24Hours`
                              )}
                            />
                            <span className="switch-state bg-primary"></span>
                          </Label>
                        )}
                      />
                    </div>
                  </div>
                  {/* <div style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "flex-end" }}>
                    <input
                      type="checkbox"
                      {...control[`workingHours[${index}].isOpen24Hours`]}
                    />
                  </div> */}
                </Col>
              </Row>
            ))}
          </CardBody>

          <CardFooter className="text-end">
            <Button type="submit" color="secondary" 
              disabled={
                    (pathname.includes("edit") && !canUpdate ) ||
                    (!pathname.includes("edit") && !canExecute)
               }
            >
              {t("Save")}
            </Button>
          </CardFooter>
        </form>
      </Col>
    </div>
  );
};

export default Index;
