import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getProducts,
  addNewProduct,
  updateProduct,
  deleteProduct,
} from "../services/productService";

const initialState = {
  productsData: {},   // productsData contains two fields : data(an array of products) and totalProducts
  loading: false,
  error: null,
};


export const fetchProducts = createAsyncThunk(
  "products/fetchData",
  async ( {service, page, perPage}, { rejectWithValue }) => {
    try {
      const response = await getProducts(service, page, perPage);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addProduct = createAsyncThunk(
  "products/addProduct",
  async (productData, { rejectWithValue }) => {
    console.log("🚀 ~ file: serviceSlice.jsx:45 ~ serviceData:", productData);
    const response = await addNewProduct(productData);
    try {
      return response.data;
    } catch (error) {
      
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const updateProductAsync = createAsyncThunk(
  "products/updateproduct",
  async ({ productId, productData }, { rejectWithValue }) => {
   
    try {
      const response = await updateProduct(productId, productData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {  
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const deleteProductAsync = createAsyncThunk(
  "services/deleteProduct",
  async (productId, { rejectWithValue }) => {
    try {
      const response = await deleteProduct(productId);
      console.log("🚀 ~ file: productSlice.jsx:38 ~ response:", response);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {  
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.productsData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(addProduct.fulfilled, (state, action) => {
      state.productsData.data = [...state.productsData.data, action.payload.data];
      state.productsData.totalProducts ++;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(updateProductAsync.fulfilled, (state, action) => {
      state.productsData.data = state.productsData.data.map(item => 
        item._id === action.payload.data._id ? action.payload.data : item
      );
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateProductAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteProductAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteProductAsync.fulfilled, (state, action) => {
      state.productsData.data = state.productsData.data.filter(
        (product) => product._id !== action.payload?.data?._id
      );
      state.loading = false;
      state.error = null;
    });
  },
});

export const productsReducer = productSlice.reducer;
export const { actions } = productsReducer;
