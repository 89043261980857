import axiosInstance from "../../api/axios";

const GET_NOTIFICATION = `${process.env.REACT_APP_BACK_URL}/api/v1/notification/getNotifications`;

// const ADD_ROOM = `${process.env.REACT_APP_BACK_URL}/api/v1/service/addService`;

export const getNotification = async (type, services, page, limit) => {
  try {
    const response = await axiosInstance.get(
      `${GET_NOTIFICATION}?type=${type}&service=${services}&page=${page}&limit=${limit}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
