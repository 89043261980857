import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { Col, Row, Label } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import SimpleInput from "../Inputs/simpleInput";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Btn } from "../../AbstractElements";
import { toast } from "react-toastify";
import {
  fetchUserByUniqueIdAsync,
  passportOcrTunAsync,
} from "../../redux-toolkit/slices/userSlice";
import ListViewCard from "../ListView/cardView";
import { getAffectationByUserIdAsync } from "../../redux-toolkit/slices/affectationSlice";
import { FaEdit, FaPlus, FaBarcode } from "react-icons/fa";
import ClientForm from "./clientForm";
import CreateAffectation from "./createAffectation";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import { Stepper, Step } from "react-form-stepper";
import { usePermission } from "../../Service/usePermission";
import UploadDropzone from "../Dropzone/UploadDropzone";
import Scan from "./Scan";


const schema = yup.object().shape({
  uniqueId: yup.string().required("You must enter a uniqueId"),
});

const AddUserForm = (props) => {
  const dispatch = useDispatch();
  const [affectationsUser, setAffectationsUser] = useState();
  const [userData, setUserData] = useState(null);
  const [scanData, setScanData] = useState(null)
  const [addNewClient, setAddNewClient] = useState(false);
  const [addAffectation, setAddAffectation] = useState(false);
  const [scan, setScan] = useState(false)
  const [clientCreated, setClientCreated] = useState(null);
  const [goSteps, setGoSteps] = useState(0);
  const [isOpen2, setIsOpen2] = useState(false);
  const { t } = useTranslation();
  const affectationSelectedData = useSelector(
    (state) => state.auth.affectationSelected
  );

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    setValue,
    watch,
    getValues,
    formState: { isValid, dirtyFields, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setUserData(null);
    try {
      const response = await dispatch(fetchUserByUniqueIdAsync(data.uniqueId));
      console.log("🚀 ~ onSubmit ~ response:", response);
      setUserData(response.payload);
      if (fetchUserByUniqueIdAsync.fulfilled.match(response)) {
        const affectationOfTheUser = await dispatch(
          getAffectationByUserIdAsync(response.payload?._id)
        );
        setAffectationsUser(affectationOfTheUser.payload.affectations);
      }
    } catch (error) {
      console.error("User not found:", error);
      toast.error("User not found");
    }
  };


  useEffect(() => {
    if (getValues("uniqueId") === "") setUserData(null);
  }, [watch("uniqueId")]);

  useEffect(() => {
    if (getValues("uniqueId") === "") {
      setAddAffectation(false);
    }
  }, [getValues("uniqueId")]);

  useEffect(() => {
    if (!props.isOpen) reset();
  }, [props.isOpen]);

  let location = useLocation();
  const canExecute = usePermission('static', "clients", 'execute');
  const pathname = location.pathname.split("/");
  const titlePopup = pathname.includes("edit")
    ? "Update User"
    : t("Add New Client");

    const modalStyle = isOpen2 ? { display: 'none' } : {};
  return (
    <Modal isOpen={props.isOpen} size="lg" centered={true} style={modalStyle}>
      <ModalHeader>{t(titlePopup)}</ModalHeader>
      <ModalBody>
        {!addNewClient && (
          <Col xl="12">
            <Row>
              <Col lg="9" className="m-b-15">
                <Label>{t("Unique Id")}</Label>
                <Controller
                  name="uniqueId"
                  control={control}
                  render={({ field }) => (
                    <SimpleInput
                      {...field}
                      errclassname="error-msg-input"
                      className="form-control m-b-5"
                      placeholder={t("Please enter the unique Identifier")}
                      errors={errors.uniqueId?.message}
                    />
                  )}
                />
              </Col>
              <Col lg="3" className="m-t-30">
                <Button
                  onClick={handleSubmit(onSubmit)}
                  color="secondary"
                  style={{ width: "100%" }}
                >
                  {t("Search")}
                </Button>
              </Col>
            </Row>
          </Col>
        )}
        {userData  ? (
          <ListViewCard
            item={userData}
            affectationData={affectationsUser}
            setAddAffectation={setAddAffectation}
            setIsOpen={props.setIsOpen}
            isOpen2={isOpen2}
            setIsOpen2={setIsOpen2}
          />
        ) : (
          !addNewClient && (
            <>
              <Btn
                color="primary"
                className=" pull-right "
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setAddNewClient(true);
                  setAddAffectation(false);
                }}
                disabled={!canExecute}
              >
                <FaPlus className="mr-2" /> {t("Add New Client")}{" "}
              </Btn>
              <Btn 
                color="success"
                className=" pull-right "
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setAddNewClient(true);
                  setScan(true);
                  setAddAffectation(false);
                }}
                disabled={!canExecute} 
              >
              <FaBarcode className="mr-2" />
              Add with scan
            </Btn>
            </>
          )
        )}
        {addNewClient && !addAffectation && !scan &&(
          <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody className="pb-0">
                    <Stepper activeStep={goSteps}>
                      <Step onClick={() => setGoSteps(0)} label="StepOne" />
                      <Step onClick={() => setGoSteps(1)} label="StepTwo" />
                    </Stepper>
                    {goSteps === 0 && (
                      <ClientForm
                        setAddNewClient={setAddNewClient}
                        setGoSteps={setGoSteps}
                        setClientCreated={setClientCreated}
                        scanData={scanData}
                      />
                    )}
                    {goSteps === 1 && (
                      <CreateAffectation
                        clientCreated={clientCreated}
                        setClientCreated={setClientCreated}
                        goSteps={goSteps}
                        setGoSteps={setGoSteps}
                        setIsOpen={props.setIsOpen}
                        setAddNewClient={setAddNewClient}
                        setAddAffectation={setAddAffectation}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
        {!addNewClient && addAffectation && (
          <CreateAffectation
            clientCreated={userData}
            setClientCreated={setUserData}
            setIsOpen={props.setIsOpen}
            goSteps={goSteps}
            setGoSteps={setGoSteps}
            setAddNewClient={setAddNewClient}
            setAddAffectation={setAddAffectation}
          />
        )}
       {scan && (
          <Scan setAddAffectation={setAddAffectation} setAddNewClient={setAddNewClient} setScan={setScan} setScanData={setScanData}/>
        )}
      </ModalBody>

      <ModalFooter>
        <Btn
          color="primary"
          onClick={() => {
            props.cancel,
              setUserData(null),
              setAddAffectation(false),
              setAddNewClient(false);
            props.setIsOpen(false);
          }}
        >
          {t("Close")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default AddUserForm;
