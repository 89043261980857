import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react'
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardBody, CardFooter, Col, Label, Row } from 'reactstrap';
import { updateAdminPasswordAsync } from '../../redux-toolkit/slices/employeeSlice';
import SweetAlert from "sweetalert2";
import { usePermission } from '../../Service/usePermission';

const UpdatePasswordFom = () => {
    const dispatch = useDispatch();
    const affectationSelected = useSelector(
      (state) => state.auth.affectationSelected
    );

    const passwordSchema = yup.object().shape({
        oldPassword: yup.string().required("You must enter the old password"),
        newPassword: yup.string().required("You must enter the new password")
          .matches(/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{3,}$/, 'New password must contain at least one uppercase letter, one number, one special character and be at least 3 characters long'),
        confirmNewPassword: yup.string().required("You must confirm the new password")
          .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      });

    const {
        control: passwordControl,
        handleSubmit: handlePasswordSubmit,
        formState: { errors: passwordErrors },
        reset: resetPasswordForm,
      } = useForm({
        mode: "onChange",
        resolver: yupResolver(passwordSchema),
      });

      const onPasswordSubmit = async (data) => {
        const formDataa = new FormData();
        formDataa.append("oldPassword", data?.oldPassword);
        formDataa.append("newPassword", data?.newPassword);
        formDataa.append("confirmPassword", data?.confirmNewPassword);
        console.log(data);
        try {
          const employeeUpdated = await dispatch(updateAdminPasswordAsync({
            employeeId: affectationSelected?.admin?._id,
            updatedAdminPasswordData: formDataa
          }));
          if(updateAdminPasswordAsync.rejected.match(employeeUpdated)){
            console.log("employeeUpdated.action.payload",employeeUpdated)
            SweetAlert.fire(
              "Conflict",
              employeeUpdated.payload,
              "error"
            );
          }else{
            SweetAlert.fire(
              "employeeUpdated.action.payloaaaaad",
              employeeUpdated.payload,
              "success"
            );
          }
        } catch (error) {
          console.log("An error occurred while updating the profile", error);
        }
        resetPasswordForm();
        // toast.success("Password updated successfully");
      };
  return (
    <div>
        <form className="card" style={{ marginTop: "10px" }}>
            <CardBody>
              <Row>
                <Col md="6">
                  <Label>Old Password</Label>
                  <Controller
                    name="oldPassword"
                    control={passwordControl}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control"
                        type="password"
                        placeholder="Old Password"
                      />
                    )}
                  />
                  <span className="error-msg-input">{passwordErrors.oldPassword?.message}</span>
                </Col>
                <Col md="6">
                  <Label>New Password</Label>
                  <Controller
                    name="newPassword"
                    control={passwordControl}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control"
                        type="password"
                        placeholder="New Password"
                      />
                    )}
                  />
                  <span className="error-msg-input">{passwordErrors.newPassword?.message}</span>
                </Col>
              </Row>
              <Row style={{ paddingTop: "10px" }}>
                <Col md="6">
                  <Label>Confirm New Password</Label>
                  <Controller
                    name="confirmNewPassword"
                    control={passwordControl}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control"
                        type="password"
                        placeholder="Confirm New Password"
                      />
                    )}
                  />
                  <span className="error-msg-input">{passwordErrors.confirmNewPassword?.message}</span>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-end">
              <Button onClick={handlePasswordSubmit(onPasswordSubmit)} color="secondary" disabled={!usePermission('static', "profile", 'update') }>
                Save Password
              </Button>
            </CardFooter>
          </form>
    </div>
  )
}

export default UpdatePasswordFom