import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  availabilityRoomBooking,
  createRoom,
  deleteRoom,
  getAllRooms,
  getRoomsByEstablishment,
  updateRoom,
} from "../services/roomsService";
import {
  getRoomsBooking,
  getRoomsByEstablishmentId,
} from "../services/affectationService";

const initialState = {
  // rooms: [],
  roomsData: {},
  roomAvailableForBooking: [],
  loading: false,
  error: null,
};

export const availabilityRoomBookings = createAsyncThunk(
  "rooms/getRoomAvailablewithFilter",
  async (payload, { rejectWithValue }) => {
    try {
      console.log("🚀 ~ payload:", payload);
      const response = await availabilityRoomBooking(payload);
      console.log("🚀 ~ availabilityRoomBookings ~ response:", response);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addRoom = createAsyncThunk(
  "rooms/addRooms",
  async (roomData, { rejectWithValue }) => {
    try {
      const response = await createRoom(roomData);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// Update Room
export const updateRooms = createAsyncThunk(
  "rooms/updateRooms",
  async ({ id, roomData }, { rejectWithValue }) => {
    // Log the content of roomData before making the API call
    console.log("FormData Content:");
    for (const [key, value] of roomData.entries()) {
      console.log(`${key}: ${value}`);
    }
    try {
      const response = await updateRoom(id, roomData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

//delete room
export const deleteRooms = createAsyncThunk(
  "rooms/deleteRooms",
  async (idRoom, { rejectWithValue }) => {
    try {
      const response = await deleteRoom(idRoom);
      console.log("room slice", response);
      return { id: idRoom, data: response }; // Ensure the payload contains the id and data
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const fetchRoomsAsync = createAsyncThunk(
  "rooms/fetchData",
  async ({ establishmentId, page, perPage }, { rejectWithValue }) => {
    try {
      console.log(
        "affectationSelectedData?.establishments?._id",
        establishmentId
      );
      const response = await getRoomsByEstablishmentId(
        establishmentId,
        page,
        perPage
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllRoomsForBooking = createAsyncThunk(
  "rooms/fetchDataRooms",
  async ({ establishmentId }, { rejectWithValue }) => {
    try {
      console.log(
        "affectationSelectedData?.establishments?._id",
        establishmentId
      );
      const response = await getRoomsBooking(establishmentId);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const roomSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    cleanRoomsForBooking: (state) => {
      state.roomAvailableForBooking = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addRoom.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addRoom.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(availabilityRoomBookings.fulfilled, (state, action) => {
      state.roomAvailableForBooking = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(availabilityRoomBookings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(fetchRoomsAsync.fulfilled, (state, action) => {
      state.roomsData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllRoomsForBooking.fulfilled, (state, action) => {
      state.roomsData = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateRooms.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateRooms.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(deleteRooms.fulfilled, (state, action) => {
      state.roomsData.data = state.roomsData.data.filter(
        (room) => room._id !== action.payload?.data?.data?.data?._id
      );
      state.roomsData.totalData--;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteRooms.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});
export const { cleanRoomsForBooking } = roomSlice.actions; // Export the action
export const roomsReducer = roomSlice.reducer;
export const { actions } = roomSlice;
