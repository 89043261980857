
export const ProfilesMessage = [
  {
    name: "Inbox",
  },
  {
    name: "Taskboard",
  },
  {
    name: "Settings",
  },

];
