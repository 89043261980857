import axiosInstance from "../../api/axios";

const CREATE_PACK= `${process.env.REACT_APP_BACK_URL}/api/v1/establishmentMeals/addEstablishementMeals`;
const GET_ALL_ESTABLISHMENT_MEALS = (establishmentId) =>
  `${process.env.REACT_APP_BACK_URL}/api/v1/establishmentMeals/getEstablishementMealsbyEstablishementId/${establishmentId}`;


export const createMeals = async (mealsData) => {
  try {
    const response = await axiosInstance.post(CREATE_PACK, mealsData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllEstablishmentMeals = async (
  establishmentId
) => {
  try {
    const response = await axiosInstance.get(
      GET_ALL_ESTABLISHMENT_MEALS(establishmentId)
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

